const TwoTenHomebuyersWarranty = `<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
  <style type="text/css">
    .st0{fill:#FFFFFF;}
  </style>
  <g>
    <path class="st0" d="M254.7,8.1c41,29.7,81.9,59.4,122.9,89c31.8,23.1,63.7,46.1,95.5,69.2c6.9,5,10.3,11.8,10.3,20.6
      c-0.1,48.4-0.1,96.8,0,145.2c0,3.7-0.8,5-4.7,4.9c-17.3-0.2-34.7-0.1-52-0.1c0-0.4,0-0.8,0.1-0.9c17.3-9.5,26.1-24.9,29-43.7
      c3.6-23.6,4-47.5-0.7-71c-5.2-26.3-21.8-41.8-48-46.9c-18.1-3.5-36.3-3.5-54.4,0.6c-25.2,5.7-40.7,21.4-45.4,46.7
      c-4.6,24.6-4.5,49.5,0,74.1c3.2,17.6,12.2,31.8,29.6,40.9c-17.6,0-34,0-50.9,0c-0.1-1.8-0.3-3.6-0.3-5.3c0-50.4,0-100.8,0-151.1
      c0-5.7,0-5.7-5.8-5.7c-12.4,0-24.7,0-37.1,0.1c-1.9,0-3.9,0.5-5.6,1.4c-12.6,6.9-25,14-37.6,20.9c-2.3,1.3-3.1,2.7-3,5.3
      c0.1,6.6,0.1,13.3,0.1,20.9c9.1-5,17.4-9.5,26.1-14.2c0.2,1.6,0.4,2.5,0.4,3.5c0,40.1,0,80.2,0.1,120.4c0,3.1-0.6,4.4-4.1,4.4
      c-18.4-0.2-36.8-0.2-55.2,0c-3.6,0-4.4-1.2-4.3-4.5c0.2-9.9-0.1-19.7,0.1-29.6c0.1-3.6-1.2-4.4-4.5-4.3c-21,0.1-42.1,0.1-63.1,0.1
      c-1,0-2.1-0.1-4.2-0.2c2.1-1.6,3.5-2.7,4.9-3.7c20.4-14.4,41-28.6,61.1-43.4c10-7.4,18.1-16.9,23.7-28.2c5.9-11.8,0.7-29.1-11-38
      c-9.3-7.1-20.3-9.9-31.5-11.4c-29-3.7-57.7-1.6-86.3,3.7c-3.6,0.7-4.6,2.1-4.5,5.7c0.2,10.5,0.2,21,0,31.6c0,2.8,0.5,4,3.7,4
      c9.6-0.2,19.2-0.2,28.8,0c2.6,0,3.4-1.1,3.9-3.3c1.7-7.7,1.7-7.8,9.7-7.7c5.5,0.1,11,0.3,16.5,0.8c3.9,0.4,7.7,1.7,8.8,6.1
      c1.2,4.6,1.1,9.5-2.7,12.9c-4.7,4.1-10,7.7-15.2,11.2c-10.4,6.9-21.2,13.2-31.4,20.4c-15.9,11.4-22.7,27.8-23.7,46.9
      c-0.5,9.1-0.5,18.1-0.5,27.2c0,2.9-0.9,3.9-3.8,3.8c-7.2-0.1-14.4,0.1-21.7,0.2c0-52.4,0-104.7,0-157.1c1.9-5.8,5.3-10.2,10.3-13.8
      C101.5,113.7,176.1,60.9,250.8,8.1C252.1,8.1,253.4,8.1,254.7,8.1z M188.4,295.7c4.2,0,8.4-0.1,12.6,0c2.1,0,3.1-0.7,3.1-3
      c-0.1-6.7-0.1-13.4,0-20.1c0-2-0.7-2.8-2.8-2.8c-8.4,0-16.8,0-25.2,0c-2,0-2.8,0.7-2.8,2.8c0.1,6.7,0.1,13.4,0,20.1
      c-0.1,2.5,1.1,3.1,3.3,3.1C180.5,295.7,184.5,295.7,188.4,295.7z"/>
    <path class="st0" d="M16.4,427.2c4.2,0,8.4,0.3,12.6,0.1c3.6-0.2,5,1,5.5,4.7c1.5,10.6,3.5,21.2,5.3,32.3c1.2-6.3,2.3-12.2,3.5-18
      c1-5,2.2-10,3.2-15c0.5-2.4,1.6-3.5,4.2-3.5c11.1-0.3,11.1-0.4,13.5,10.6c1.9,8.8,3.7,17.6,5.5,26.3c1.7-10.8,3.6-21.8,5-32.9
      c0.5-3.6,1.9-4.7,5.3-4.4c3.9,0.3,7.8,0.1,12.7,0.1c-5,22-9.9,43.3-14.8,64.6c-5.3,0-10.5,0-15.8,0c-2.6-12.6-5.3-25.2-7.8-37
      c-1.1,5.4-2.3,11.7-3.6,17.9c-1.4,6.4-2.9,12.7-4.4,19.1c-5.3,0-10.5,0-15.8,0c-3.1-13.9-6.2-27.8-9.4-41.6
      c-1.5-6.4-3.2-12.8-4.8-19.2C16.4,429.8,16.4,428.5,16.4,427.2z"/>
    <path class="st0" d="M322.6,491.9c-0.2-1.6-0.5-3.1-0.5-4.7c0-18.4,0.1-36.8-0.1-55.2c0-3.6,1.1-4.4,4.6-4.8
      c8.1-0.9,13.1,1.5,16.5,9.3c3.3,7.6,8.1,14.6,12.7,22.7c-0.2-2.8-0.4-4.8-0.5-6.9c-0.1-7.2-0.1-14.5-0.2-21.7
      c0-2.4,1.1-3.3,3.4-3.2c3.3,0.1,6.6-0.2,9.9,0.1c1,0.1,2.8,1.7,2.8,2.6c0.1,20,0.1,40,0.1,60c0,0.6-0.4,1.3-0.5,1.9
      c-4.5,0-8.9,0-13.4,0c-6.5-11.5-12.9-23-19.2-34c0,8.3,0,17.1,0,25.9c0,2.7-0.4,5.5-0.5,8.2C332.6,491.9,327.6,491.9,322.6,491.9z"
      />
    <path class="st0" d="M152.9,491.9c-0.2-1.4-0.6-2.8-0.6-4.2c0-18.7,0-37.4-0.1-56c0-3.2,0.8-4.5,4.2-4.4c7.9,0.3,15.8,0.1,23.7,0.5
      c7.3,0.4,12.8,3.7,15.4,10.9c2.8,7.8,1.4,15-3.8,21.4c-1.2,1.4-2.6,2.6-4.2,4.1c4.5,7.8,8.9,15.5,13.4,23.2
      c1.1,1.8,1.6,3.3-0.6,4.6c-5.5,0-11,0-16.6,0c-4.1-8.3-8.2-16.6-12.4-24.9c-0.4,0.1-0.8,0.2-1.2,0.3c0,4.7,0.1,9.5,0,14.2
      c-0.1,3.5-0.4,7-0.7,10.4C164,491.9,158.5,491.9,152.9,491.9z M170.2,440.7c0,2.8,0,5.4,0,8c0,5.3,2,6.7,6.8,4.4
      c1.3-0.6,2.5-2,3-3.3C182.2,443.2,178.4,439.5,170.2,440.7z"/>
    <path class="st0" d="M207.4,491.9c-0.1-1.3-0.4-2.6-0.4-3.9c0-18.7,0-37.3-0.1-56c0-3.4,0.9-4.9,4.7-4.7c7.6,0.4,15.2,0.2,22.8,0.5
      c17.9,0.6,20.7,21.2,12.6,31.2c-1.5,1.8-3.2,3.4-4.9,5.3c4.1,7.1,8.1,14.1,12.2,21.1c3.1,5.3,3.1,5.3-2.7,6.5c-3.9,0-7.9,0-11.8,0
      c-1.1-1.2-2.6-2.2-3.3-3.6c-3.2-6.3-6.2-12.7-9.4-19.1c-0.4-0.8-1-1.5-1.6-2.2c-0.9,4.9-0.8,9.4-0.9,14c-0.1,3.6-0.4,7.3-0.7,10.9
      C218.4,491.9,212.9,491.9,207.4,491.9z M225,440.6c-0.2,0.5-0.3,0.8-0.3,1c0,2.5-0.1,5-0.1,7.4c0,5,1.4,6.1,6,4.4
      c1.6-0.6,3.3-2.3,3.9-3.9C236.9,443.1,232.9,439.4,225,440.6z"/>
    <path class="st0" d="M90.6,491.9c0-1.1-0.3-2.3,0-3.3c6.2-19.4,12.5-38.7,18.7-58.1c0.8-2.4,2-3.4,4.5-3.2c3.3,0.2,6.6,0.2,9.9,0
      c3-0.2,4.1,1.1,5,3.8c6,18.8,12.2,37.5,18.2,56.2c0.5,1.5,0.4,3.1,0.6,4.6c-6.3,0-12.6,0-18.9,0c-1.1-4.4-2.2-8.9-3.4-13.3
      c-0.2-0.7-1.1-1.7-1.7-1.8c-3.4-0.2-6.8-0.2-10.2,0c-0.6,0-1.4,1.1-1.6,1.8c-1.3,4.4-2.4,8.8-3.5,13.2
      C102.1,491.9,96.4,491.9,90.6,491.9z M121.9,463.9c-1.1-6.5-2.1-12.5-3.2-18.5c-1.4,3.1-2,6.1-2.6,9.1c-0.6,3-1.3,6-2,9.4
      C116.9,463.9,119,463.9,121.9,463.9z"/>
    <path class="st0" d="M259.5,491.9c0.2-1.5,0.3-3.1,0.8-4.6c6-18.6,12.1-37.2,18-55.9c1-3.2,2.5-4.4,5.8-4.2c4,0.3,9.1-1.1,11.9,0.8
      c2.7,1.9,3.2,7.1,4.5,11c5.3,16,10.5,31.9,15.6,48c0.5,1.5,0.2,3.2,0.3,4.9c-6.1,0-12.1,0-18.2,0c-1.4-5-2.8-10-4.4-15.2
      c-3.5,0-6.9-0.2-10.3,0.1c-1,0.1-2.4,1.4-2.8,2.4c-1.5,4.2-2.6,8.5-3.9,12.7C271.1,491.9,265.3,491.9,259.5,491.9z M287.8,445
      c-0.2,0-0.4,0.1-0.6,0.1c-0.6,3.1-1.1,6.2-1.7,9.2c-0.6,3-1.2,6.1-1.9,9.4c2.8,0,5,0,7.6,0c-0.9-5.5-1.7-10.7-2.6-15.8
      C288.4,447,288.1,446,287.8,445z"/>
    <path class="st0" d="M446.5,491.9c-0.2-7.5-0.1-14.9-0.5-22.4c-0.2-2.8-0.8-5.7-2.1-8.2c-5.1-9.9-10.4-19.7-15.7-29.5
      c-0.6-1.1-1-2.3-1.9-4.4c7,0,13.2-0.1,19.4,0.1c0.8,0,1.8,1.7,2.2,2.9c2.4,5.8,4.6,11.7,7.1,18.3c1.8-4.3,3.3-8,4.9-11.8
      c4-9.7,4-9.7,14.4-9.7c2.8,0,5.6,0,9.3,0c-0.8,2.1-1.2,3.3-1.8,4.4c-5.4,10.2-10.9,20.4-16.1,30.7c-1,2-1.6,4.4-1.7,6.7
      c-0.2,7.6-0.1,15.2-0.1,22.8C458.1,491.9,452.3,491.9,446.5,491.9z"/>
    <path class="st0" d="M16.4,364.1c3.8-0.1,7.6,0,11.4-0.2c2.9-0.2,4,1,3.9,3.9c-0.1,5.2,0,10.5,0,16c3.5,0,6.6,0,10.5,0
      c0-5,0.3-10.1-0.1-15.1c-0.3-4,1.1-4.9,4.8-4.8c10.3,0.2,10.3,0,10.3,10.3c0,12.6,0,25.2,0,37.8c0,1.7,0,3.4,0,5.7
      c-4.7,0-8.9,0.2-13.2-0.2c-0.7-0.1-1.8-2.2-1.9-3.4c-0.2-5.7-0.1-11.5-0.1-17.5c-3.5,0-6.7,0-10.4,0c0,5.5-0.2,10.9,0.1,16.3
      c0.2,3.6-0.7,5.4-4.8,5c-3.5-0.3-7.1,0-10.6,0C16.4,399.8,16.4,381.9,16.4,364.1z"/>
    <path class="st0" d="M391.3,491.9c-0.2-1.5-0.6-3.1-0.6-4.6c0-13.5-0.1-27,0-40.6c0-3.1-0.8-4.1-4-4.1c-10.4,0-10.2-0.1-10.4-10.5
      c-0.1-3.7,0.9-5,4.8-4.9c12.9,0.2,25.7,0.2,38.6,0c3.4,0,5.5,0.5,3.9,4.5c0,0.1,0,0.3-0.1,0.4c-1.7,10.1-1.7,10.2-11.9,10.5
      c-2.9,0.1-3.1,1.4-3.1,3.6c0.1,13.7,0,27.3,0,41c0,1.5-0.4,3.1-0.6,4.6C402.3,491.9,396.8,491.9,391.3,491.9z"/>
    <path class="st0" d="M367.2,258.5c0.4-10.9,0.8-21.8,1.2-32.7c0.1-1.8,0.5-3.6,0.9-5.4c1.7-7.3,5.1-10.1,11.9-10.1
      c6.5,0.1,10.2,3,11.3,10.2c1.1,7.5,1.7,15.1,1.8,22.7c0.1,15.4-0.4,30.7-0.7,46.1c-0.1,2.3-0.5,4.7-0.9,7
      c-1.6,7.7-5.1,10.4-12.9,10.1c-5.2-0.2-8.3-2.1-9.5-7.2c-1.3-5.4-2.1-11.1-2.4-16.7c-0.4-8-0.1-16-0.1-24
      C367.6,258.5,367.4,258.5,367.2,258.5z"/>
    <path class="st0" d="M144.8,387.3c-1.5,5.2-3.4,10.4-4.4,15.7c-1,5-4.6,3-7.3,3.5c-2.9,0.5-3.9-1-4.5-3.6
      c-1.2-5.2-2.5-10.3-3.8-15.5c-0.3,0-0.7,0.1-1,0.1c0,9.9,0,19.7,0,30c-4.5,0-8.4,0.2-12.3-0.2c-0.7-0.1-1.9-2.2-1.9-3.3
      c0.5-10.8,1.2-21.5,1.9-32.3c0.3-5,0.8-10,1-14.9c0.1-2.4,1.1-3,3.4-3.1c11.4-0.5,11.4-0.6,14.8,10c1.3,4.1,2.5,8.2,4.5,12.4
      c1.7-5.3,3.4-10.5,5.1-15.8c2.7-8.1,1.2-6.5,9.1-6.6c8.5,0,7.3-1.3,7.9,7.5c0.9,14,1.6,28.1,2.5,42.1c0.2,3.4-0.8,4.3-4.1,4.3
      c-10.5,0-10.5,0.1-10.5-10.3c0-6.6,0-13.3,0-19.9C145.1,387.5,145,387.4,144.8,387.3z"/>
    <path class="st0" d="M254.5,389.2c6.4,3.8,9.1,8.8,7.6,15.8c-1.6,7.2-6.5,11.9-13.9,12.4c-7.3,0.4-14.7,0.3-22.1,0.2
      c-0.9,0-2.6-1.8-2.7-2.7c-0.2-16-0.1-32-0.1-48.1c0-2.3,1-3,3.1-2.9c6.6,0.1,13.1,0.1,19.7,0.2c6.5,0.1,11.2,2.9,13.4,9
      C261.7,379.2,260.8,384.8,254.5,389.2z M241.4,395.3c-2.6-0.5-4.1,0.9-3.3,3.7c0.8,2.6-2.9,7.2,3,7.5c4.9,0.3,7.3-1.7,7.4-5.5
      C248.5,397.1,246.6,395.4,241.4,395.3z M239.6,374.3c-0.3,0.5-1.4,1.5-1.6,2.7c-0.3,1.6,0,3.4-0.1,5.1c-0.2,2.3,0.9,3,3,3
      c3.9,0,6-2,5.9-5.7C246.8,375.9,244.8,374.3,239.6,374.3z"/>
    <path class="st0" d="M442.4,417.5c-4.8,0-9.6,0.1-14.4-0.1c-0.8,0-1.8-1.7-2.4-2.8c-2.9-5.7-5.6-11.5-8.4-17.2
      c-0.4,0.1-0.9,0.1-1.3,0.1c0,3,0,6,0,9c0.1,11.5,0.1,11.4-11.4,11.2c-2.9,0-3.5-1.1-3.5-3.7c0.1-15.6,0.1-31.3,0-46.9
      c0-2.4,0.6-3.7,3.3-3.6c6.4,0.2,12.9,0.2,19.3,0.4c6.3,0.2,11.3,2.5,13.6,8.8c2.5,6.7,1.8,13.2-3.2,18.3c-3,3-2.8,5-0.8,8.2
      c3.5,5.5,6.6,11.3,9.9,16.9C442.8,416.7,442.6,417.1,442.4,417.5z M416,386.1c1.7,0,2.6,0.1,3.5,0c4.6-0.6,7.2-5.2,4.5-8.9
      c-1.1-1.6-4.2-1.8-6.3-2.7c-0.5,1.4-1.4,2.8-1.6,4.2C415.8,381,416,383.3,416,386.1z"/>
    <path class="st0" d="M106.7,392c-1,4.4-1.7,10.2-3.8,15.5c-3.2,8.3-10.5,12.1-20,11.6c-9.2-0.5-15.5-5.1-18.1-13.7
      c-2.7-9-2.9-18.2-0.6-27.2c2.8-10.8,10-15.8,21.5-15.6c10.1,0.2,16.8,5.8,19.2,16.4C105.8,382.7,106,386.7,106.7,392z M91.4,390.6
      c-0.7-4.3-1.1-8.7-2.3-12.8c-0.5-1.7-2.9-4-4.5-4c-1.6,0-4.1,2.1-4.6,3.9c-2.9,8.5-3,17.3-0.2,25.9c0.6,1.7,3,3.8,4.6,3.8
      c1.6,0,4.1-2.1,4.6-3.7C90.2,399.6,90.6,395.1,91.4,390.6z"/>
    <path class="st0" d="M265.9,384.6c0-5.8,0.1-11.6-0.1-17.4c-0.1-2.7,1-3.3,3.4-3.4c11.7-0.4,11.6-0.4,11.6,11
      c0,8.2-0.1,16.3,0.1,24.4c0.1,3.2-0.1,6.8,4.5,7.6c4.8,0.9,8-1,8.2-5.9c0.4-9.8,0.4-19.7,0.4-29.6c0.1-7.6,0-7.6,7.4-7.6
      c8,0,6.9-0.7,6.9,6.9c0,9.2,0.1,18.4,0,27.6c-0.2,12.9-6.5,19.6-19.4,20.5c-3.5,0.2-7.1,0-10.6-0.7c-7.9-1.5-12.4-7.6-12.6-16.7
      C265.8,395.9,265.9,390.2,265.9,384.6z"/>
    <path class="st0" d="M200.4,363.9c-0.5,3.7-0.7,6.7-1.5,9.6c-0.3,1-2.1,2.1-3.3,2.2c-4.4,0.3-8.9,0.1-13.6,0.1c0,2.9,0,5.3,0,8.3
      c3.6,0,7.2,0.1,10.7,0c2.6-0.1,3.4,0.9,3.4,3.5c-0.2,10.4,1.1,8.5-8.4,8.7c-1.8,0-3.6,0-5.7,0c0,3.2,0,6,0,9.5
      c4.6,0,9.2,0.2,13.7-0.1c3-0.1,4.2,0.8,4.1,4c-0.3,9.6,1,7.9-7.8,8c-7.2,0.1-14.5-0.1-21.7,0.1c-2.7,0.1-3.8-0.8-3.7-3.6
      c0.1-15.5,0-31,0.2-46.5c0-1.2,1.5-3.5,2.3-3.6C179.2,363.8,189.4,363.9,200.4,363.9z"/>
    <path class="st0" d="M395.5,363.9c-0.5,3.8-0.7,7-1.4,10c-0.2,0.9-2.1,1.8-3.2,1.8c-3.7,0.2-7.4,0.2-11,0.1
      c-3.5-0.2-2.9,2.2-2.9,4.2c-0.1,2.2-0.3,4.4,3,4.1c2.2-0.2,4.5,0.2,6.7-0.1c3.9-0.5,4.9,1.2,4.7,4.9c-0.4,8.2,1.1,7.2-7.4,7.3
      c-2.1,0-4.2,0-7,0c0,2.7-0.2,5.2,0.1,7.6c0.1,0.8,1.7,1.8,2.7,1.9c4.1,0.2,8.1,0.2,12.2,0.1c2.1,0,3.2,0.6,3.1,2.9
      c-0.1,2.8,0,5.7,0,9.1c-6.2,0-11.9,0-17.7,0c-3.7,0-7.4-0.2-11,0.1c-3.2,0.2-4.4-0.9-4.3-4.2c0.2-10.8,0.1-21.6,0.1-32.4
      c0-4.7-0.1-9.5,0.1-14.2c0.1-1.1,1.5-3,2.3-3C374.6,363.8,384.7,363.9,395.5,363.9z"/>
    <path class="st0" d="M448.4,402.4c5,1.4,9.3,2.9,13.7,3.9c2.7,0.6,5.5,0.3,6.5-2.9c0.9-3.1-1.1-4.9-3.6-6.2c-4-2-8-3.9-11.9-6
      c-5.1-2.8-7.5-7.2-7.2-13c0.2-5.9,2.8-10.4,8.1-13c8.7-4.2,17.4-2.8,25.9,0.9c2,0.9,2.6,2.3,1.7,4.5c-1,2.4-1.8,4.9-2.9,7.8
      c-2.1-0.7-4-1.6-6-2c-2.8-0.7-5.6-1.3-8.4-1.4c-1.2,0-3.1,1.4-3.4,2.4c-0.3,1.1,0.8,3.1,1.9,3.8c3,1.9,6.3,3.2,9.5,4.8
      c5.3,2.7,9.9,6.1,10.8,12.6c1.5,10.3-4.8,18.6-15.1,19.8c-6.7,0.8-13.1-0.4-19.3-2.7c-2.5-0.9-3.7-2.3-2.7-5.1
      C446.8,407.8,447.6,405,448.4,402.4z"/>
    <path class="st0" d="M310.9,363.7c6.1,0,11.2-0.1,16.3,0.1c0.8,0,1.8,1.7,2.3,2.7c2,4.8,3.9,9.7,6,15.1c2.2-5.2,4.3-10,6.1-14.9
      c0.9-2.4,2.2-3.3,4.7-3.2c4.1,0.1,8.3,0,13,0c-0.6,1.8-0.9,2.8-1.3,3.7c-2.3,4.6-4.3,9.4-7.2,13.6c-6.7,9.8-9.1,20.4-7.7,32
      c0.4,3.7-0.7,5.1-4.7,5c-10.3-0.3-10.6-0.1-10.2-10.3c0.3-7.9-1.1-14.9-5.3-21.6C318.6,379.1,315.3,371.8,310.9,363.7z"/>
  </g>
  <g>
    <path class="st0" d="M481.3,475.5c1.4,0,2.7,0.4,4,1.1c1.3,0.7,2.3,1.7,3.1,3c0.7,1.3,1.1,2.7,1.1,4.1c0,1.4-0.4,2.8-1.1,4.1
      c-0.7,1.3-1.7,2.3-3,3c-1.3,0.7-2.7,1.1-4.1,1.1c-1.4,0-2.8-0.4-4.1-1.1c-1.3-0.7-2.3-1.7-3-3c-0.7-1.3-1.1-2.7-1.1-4.1
      c0-1.4,0.4-2.8,1.1-4.1c0.7-1.3,1.8-2.3,3.1-3C478.6,475.9,479.9,475.5,481.3,475.5z M481.3,476.9c-1.2,0-2.3,0.3-3.4,0.9
      c-1.1,0.6-1.9,1.4-2.6,2.5c-0.6,1.1-0.9,2.2-0.9,3.4c0,1.2,0.3,2.3,0.9,3.4c0.6,1.1,1.4,1.9,2.5,2.5c1.1,0.6,2.2,0.9,3.4,0.9
      c1.2,0,2.3-0.3,3.4-0.9c1.1-0.6,1.9-1.4,2.5-2.5c0.6-1.1,0.9-2.2,0.9-3.4c0-1.2-0.3-2.3-0.9-3.4c-0.6-1.1-1.5-1.9-2.6-2.5
      C483.6,477.2,482.4,476.9,481.3,476.9z M477.7,488.3v-8.8h3c1,0,1.8,0.1,2.2,0.2c0.5,0.2,0.8,0.4,1.1,0.9s0.4,0.8,0.4,1.3
      c0,0.6-0.2,1.2-0.7,1.7c-0.5,0.5-1.1,0.7-1.8,0.8c0.3,0.1,0.6,0.3,0.7,0.5c0.4,0.3,0.8,0.9,1.3,1.7l1.1,1.7h-1.7l-0.8-1.4
      c-0.6-1.1-1.1-1.8-1.5-2.1c-0.3-0.2-0.6-0.3-1.1-0.3h-0.8v3.7H477.7z M479.1,483.3h1.7c0.8,0,1.4-0.1,1.7-0.4
      c0.3-0.2,0.5-0.6,0.5-1c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.4-0.6-0.5c-0.3-0.1-0.7-0.2-1.4-0.2h-1.6V483.3z"/>
  </g>
  </svg>`

const AshevilleHomeBuildersAssociation = `<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
  <style type="text/css">
    .st0{fill:#FFFFFF;}
  </style>
  <g>
    <path class="st0" d="M12.5,250.2C12.9,119,116.4,15,244.9,12.6c136.6-2.5,240.5,105,242.5,232.6c2.2,137.7-106.2,238.9-230.2,242.2
      C119,491.1,12.7,380.9,12.5,250.2z M45.1,137.1c0.2,0,0.4,0.1,0.6,0.1c-1,2.6-1.9,5.2-3.1,7.6c-10.6,21.2-18.1,43.4-22,66.8
      c-2.7,16.5-4,33.2-3,50c1.2,20.2,4.7,40,10.8,59.3c7.4,23.1,18.4,44.5,32.5,64.4c13.5,19.2,29.7,35.7,48.3,49.8
      c20.9,15.8,44,27.8,69,36c23.8,7.8,48.1,11.4,72.9,11.6c13.1,0.1,26.3-1.7,39.3-3.9c37.8-6.5,71.8-21.7,102.1-45.2
      c28.5-22,50.7-49.4,66.4-81.6c16.9-34.7,24.8-71.4,23.4-110.1c-0.9-26-5.9-51.2-15.4-75.4c-19.8-50.3-52.9-89.6-99.6-117.2
      C348,37.8,327.2,29.3,305.2,24c-22.7-5.4-45.8-7.5-69.2-6.1c-22.7,1.3-44.7,5.8-65.9,13.6c-28.2,10.4-53.6,25.7-76,45.9
      C76.9,93,62.4,110.9,50.4,130.8C49,133.1,46.9,135,45.1,137.1z"/>
    <path class="st0" d="M251,103.4c45,77.6,89.4,154.1,134.2,231.5c-9.2,0.2-17.9,0.5-26.5,0.7c-11.1,0.3-22.2,0.7-33.3,0.9
      c-4.4,0.1-8.9-0.2-13.3-0.8c-1.1-0.1-2.3-1.7-3-2.9c-3.2-5.8-6.4-11.7-9.2-17.7c-1.2-2.6-2.7-3.4-5.4-3.4
      c-27.7,0.1-55.5,0-83.2,0.2c-1.6,0-3.8,1.6-4.6,3c-3.6,6.6-6.7,13.5-10.3,20.2c-0.8,1.5-3,3.1-4.5,3.2c-23.4,0.4-46.8,0.5-70.3,0.6
      c-1.4,0-2.9-0.2-5.1-0.3C163.8,261.6,206.3,182.9,251,103.4z M249.4,227.6c-0.5,0-1.1,0-1.6,0c-6.3,12.5-12.5,25.1-19.1,38.1
      c13.8,0,26.7,0,40.5,0C262.3,252.6,255.9,240.1,249.4,227.6z"/>
    <path class="st0" d="M388.6,332.9c-2.9-2.5-3.8-4.8-1.7-8.7c14.8-28.2,20-58.3,16.8-89.9c-0.5-4.4-2-7.5-6-10.1
      c-7.5-4.9-15.3-9.1-24.2-8.9c-6.1,0.2-12.2,2.8-18.2,4.6c-2,0.6-3.6,2.2-5.6,2.8c-8.4,2.2-11.1,7.7-9.7,15.9c0.3,2,0,4-0.8,6.2
      c-7.2-12.3-14.5-24.6-21.7-36.9c-6.7-11.4-13.3-22.8-20.1-34c-2.7-4.4-4.6-8.8-4.1-14.1c0.4-4.3,0.2-8.6,0-12.9
      c-0.1-2.6,0.7-3.9,3.5-3.9c13.2,0.1,26.4,0.1,39.6,0c3.1,0,3.6,1.6,3.6,4.2c0,11.1,0.1,22.2,0.1,33.4c0,1.7,0,3.5,0,6.1
      c2.3-0.7,3.8-1.1,5.2-1.7c4.9-2.1,10.1-3.8,14.6-6.5c4.9-3,8.8-1.7,13,1.1c5.7,3.8,11.6,7.4,17.9,10.8
      c-5.4-15.8-14.4-29.3-25.4-41.4c-26-28.5-58.3-45.8-96.7-50.4c-85.1-10.1-147.7,48-164.7,102.4c3.5-1.5,6.6-2.3,9.2-3.9
      c11.8-7.1,23.5-14.3,35.2-21.6c2.8-1.8,4.5-2.1,7.6,0.3c5.9,4.6,12.5,8.4,19,12.2c7.3,4.3,14.9,4.1,22.5,0.3c0.9-0.4,1.9-0.5,3-0.9
      c-3.8,11.6-15.5,15.2-25.9,8c-5.1-3.5-10.2-7.1-15.1-10.9c-2.8-2.2-5.8-2.6-8.7-1.3c-8.2,3.7-16.3,7.6-24.3,11.6
      c-7.6,3.7-15,7.6-22.5,11.5c-1,0.5-2.4,1.2-2.7,2.1c-1.8,6.8-3.3,13.7-5,20.5c0.4,0.4,0.8,0.8,1.2,1.3c3.8-1.2,7.9-1.8,11.4-3.6
      c10-5.1,19.9-10.4,29.6-16.2c3.3-1.9,5.3-1.6,8.2,0.5c8.8,6.2,18,12,27,18c0.8,0.6,1.6,1.2,2.8,2.1c-1,1.5-2,2.9-3.2,4.8
      c-3.3-2.3-6.3-4.5-9.3-6.5c-2.8-1.9-5.6-3.8-8.4-5.7c-7.4-5-15-5.6-23.2-1.9c-11.2,5-22.6,9.7-33.7,14.9c-1.6,0.7-3.2,3.1-3.4,4.8
      c-1.9,17,0.4,33.6,4.6,50.1c3.4,13.3,8.3,26,15.5,37.7c2.4,3.9,0.2,5.9-2.2,8.2c-37.3-55.9-36.7-147.1,29.8-206
      c66.5-59,167.1-53.2,226.4,12.2C426.2,206.3,417.5,290.4,388.6,332.9z M399.3,217.8c0.5-0.1,1-0.2,1.5-0.3
      c-1.9-5.5-3.7-11-5.6-16.4c-0.2-0.6-1-1.1-1.6-1.5c-5.4-3.4-10.8-6.8-16.1-10.2c-9.1-5.8-17.2-6.2-27,0.3c-7.6,5-12.1,9.6-10.5,19
      c0.6,3.6,0.1,7.4,0.1,11.2c0.5,0.2,0.9,0.5,1.4,0.7c8.6-4.9,17.2-9.5,25.6-14.6c3.5-2.1,6.2-2.5,9.8-0.4
      C384.2,209.8,391.8,213.7,399.3,217.8z"/>
    <path class="st0" d="M116,342.1c5-1.4,7.8,0.6,10.7,4.4c14.1,19,32.1,33.5,53.1,44.2c23.7,12,49,17.7,75.5,16.6
      c26.7-1.1,51.5-8.5,74.5-22.3c19.1-11.5,34.7-26.5,47.6-44.6c1.4-2,5-2.6,7.5-3.8c0.4,0.7,0.8,1.4,1.2,2.2
      c-25.6,39.3-61.5,64.1-107.8,72.4C206.2,424.1,144.7,387.5,116,342.1z"/>
    <path class="st0" d="M70.4,303.2c12.2,0.1,22.2,10.7,22.1,23.3c-0.1,12-10.7,22-22.9,21.8c-13.1-0.2-22.4-10.1-22.2-23.7
      C47.4,312.7,57.8,303.1,70.4,303.2z M68.8,342.7c9.9,0,18.3-7.9,18.3-17.3c0-9.2-7.4-16.3-16.9-16.3c-9.7,0-17.8,7.6-17.9,16.6
      C52.2,334.9,59.8,342.7,68.8,342.7z"/>
    <path class="st0" d="M98.9,389.4c11.5-3.8,21.7-7.2,33.2-11c-8.7,12.2-16.6,23.4-24.6,34.7c-3.5-1.6-4.4-3.3-2-6.3
      c3.6-4.7,7-9.5,10.5-14.4c0.8-1.1,1.5-2.3,2.2-3.5c-0.2-0.3-0.5-0.6-0.7-0.9c-8.2,2.7-16.4,5.4-26.1,8.6
      c3.5-9.4,6.6-17.7,10.1-27.4c-2.7,1.4-4.4,2.1-5.9,3c-5,3.1-10,6.4-15.1,9.4c-0.9,0.5-2.6-0.3-4-0.5c0.4-1.2,0.4-3,1.2-3.5
      c11-6.9,22.1-13.7,34-21C107.5,367.8,103.4,378,98.9,389.4z"/>
    <path class="st0" d="M231.4,442c1.6,4.7,3.9,8.4,4,12.2c0.3,6.5-4.9,10.9-12.1,10.6c-5.1-0.2-10.1-1.2-15.3-1.8
      c1.8-13.1,3.6-25.7,5.3-38.2c0.4-3.1,1.4-4.6,4.8-3.7c3.7,1,7.6,1.1,11.2,2.3c7.5,2.7,9.2,10.8,3.7,16.7
      C232.4,441,231.5,441.9,231.4,442z M222.3,460c4.3-0.1,7.3-2.6,7.7-6.5c0.4-4.7-3.6-8.3-10.1-9.1c-5.2-0.7-3.3,3.7-4.4,5.9
      c-0.2,0.4-0.2,0.9-0.3,1.3C213.8,458.7,215.1,460.2,222.3,460z M223.3,440.3c4.8-0.1,7.4-2.5,7.4-6.8c0-4.3-3-7.1-8.2-7.3
      c-5.3-0.3-3.9,3.9-4.5,6.8c-0.1,0.4-0.1,0.9-0.2,1.3C217,439.3,218,440.4,223.3,440.3z"/>
    <path class="st0" d="M388.3,417.8c-9.9-10.6-19.4-20.7-29.6-31.5c4.9-3.7,9.3-8,14.5-10.9c8.4-4.7,16.8-2.5,23.9,5.2
      c6.5,7,7.8,16.4,3.3,24.2c-1,1.7-2,3.3-3.3,4.7C394.5,412.3,391.6,414.8,388.3,417.8z M388.6,410.1c5.9-3.8,9.2-8.7,9.1-15.2
      c-0.1-6.4-5.8-13.2-11.9-15.2c-6.2-2.1-13-0.2-17.2,5c-0.7,0.8-0.9,3-0.3,3.6C374.9,395.6,381.7,402.7,388.6,410.1z"/>
    <path class="st0" d="M183.3,44.3c5.3,13,10.6,26.2,16.1,39.7c-1.8,0.8-3.2,1.4-5,2.1c-2.1-5.2-4.1-9.9-5.9-14.7
      c-0.8-2.2-1.7-3.4-4.2-2.4c-4.9,2-9.9,4-14.8,5.9c-2.3,0.9-2.7,2.3-1.7,4.5c1.7,3.6,3.1,7.4,4.6,11.1c1.6,3.9,1.6,3.9-3.3,5.8
      c-2.4-5.6-4.9-11.2-7.2-16.9c-2.6-6.3-4.9-12.7-7.5-18.9c-1.4-3.2-0.7-5.1,3.5-5.9c1.5,3.7,2.9,7.4,4.5,11.2c2.3,5.7,2.3,5.7,8,3.6
      c3.9-1.4,7.6-3.2,11.5-4.7c2.5-1,3-2.5,2-4.9c-1.4-3.3-2.7-6.6-4.1-9.8C178.1,46.7,179,44.7,183.3,44.3z"/>
    <path class="st0" d="M76.8,251.2c-4.9,0.4-9.4,0.8-14,1c-3.6,0.2-5.3,1.5-4.5,5.6c0.8,4.2,0.9,8.5,1.1,12.8
      c0.1,3.2,1.6,4.2,4.5,3.8c3.1-0.4,6.2-0.7,9.2-1.1c3.4-0.4,6,0.2,5.3,5.3c-2.7,0.2-5.7,0.5-8.7,0.7c-9.7,0.8-19.4,1.6-29.2,2.2
      c-2.1,0.1-5.3,1.3-5.3-2.7c0-3.4,2.9-2.7,4.9-2.9c3.7-0.4,7.4-0.7,11.1-0.7c2.9,0,3.6-1.4,3.4-3.8c-0.4-5.2-0.6-10.3-1.2-15.5
      c-0.1-1-1.9-2.6-3-2.6c-4.1-0.1-8.3,0.5-12.4,0.7c-2,0.1-4.8,0.6-4.4-2.5c0.2-1.2,2.7-2.8,4.3-2.9c5.7-0.7,11.5-0.8,17.3-1.2
      c5.6-0.3,11.2-0.8,16.8-1C76,246.1,76.1,246.2,76.8,251.2z"/>
    <path class="st0" d="M459.5,302.6c1.3,4.3,0.2,6.1-3.3,7.1c-6.5,1.7-12.9,3.9-19.4,5.9c0,0.4-0.1,0.8-0.1,1.2
      c5.9,2.2,11.8,4.4,18,6.8c-0.5,1.5-0.9,2.9-1.6,4.9c-13.7-4.3-27.1-8.6-40.8-13c2.1-7.1,2.8-14,9.6-17.5c6.4-3.2,14.6-0.1,16.7,6.7
      c1.2,3.9,2.8,4.5,6.4,3C449.7,305.6,454.6,304.3,459.5,302.6z M435.5,310.1c0.1-4.5-5.3-8.7-9.9-7.7c-3.9,0.8-6,3.7-6.1,7.2
      c0,1.1,3.2,2.5,5.1,3.5c1.4,0.7,3.1,1.1,4.6,1.6C433.7,316.2,435.4,315,435.5,310.1z"/>
    <path class="st0" d="M262.4,423.2c1,8.3,1.9,16,2.8,23.8c0.2,1.9,0.5,3.8,0.9,5.7c1.5,6.3,4.7,8.8,10.2,8c6.4-1,8.8-4,8.1-10.8
      c-0.7-7.2-1.7-14.4-2.5-21.6c-0.2-1.8-0.6-3.6-0.2-5.3c0.2-0.9,1.6-2,2.7-2.3c0.5-0.2,2.1,1.2,2.2,1.9c1.3,10.1,2.9,20.2,3.4,30.4
      c0.3,7.3-5.2,11.9-12.9,12.6c-8.7,0.8-14.4-2.3-15.8-10c-1.8-9.4-2.6-19-3.8-28.6C257,423.7,259.4,423.5,262.4,423.2z"/>
    <path class="st0" d="M153.6,439c-2,3.7-4.2,3.8-7.1,1.8c-4.3-2.9-8.8-5.5-13.3-8.1c-2.4-1.4-3-2.8-1.4-5.3
      c6.3-10.2,12.5-20.5,18.5-30.8c1.4-2.5,2.6-2.5,4.9-1.1c4.2,2.7,8.6,5.2,13,7.7c3.1,1.7,3.4,3.7,0.9,6.6c-3.7-2.2-7.5-4.2-11-6.6
      c-2.6-1.7-4.3-1.5-5.7,1.1c-0.3,0.5-0.6,1-0.9,1.5c-3.7,5.9-3.7,6,2.4,9.7c1.9,1.1,3.7,2.4,6,3.8c-0.6,1.4-1.2,2.6-2,4.5
      c-3-1.8-5.8-3.4-8.5-5.2c-2.7-1.7-4.5-1.8-6.4,1.5c-5,8.9-5.2,8.9,3.7,14c1.7,1,3.3,2,4.9,3C152.2,437.6,152.8,438.3,153.6,439z"/>
    <path class="st0" d="M121.5,131.1c-0.4,4.1-2.4,5.1-5.6,4.4c-0.4-0.1-0.9-0.1-1.3-0.2c-8.4-3.1-14-0.5-18.5,7.3
      c-2.3,4-3.2,7-0.6,10.8c1.3,1.9,2.1,4.3,3.4,6.2c1.7,2.6,1.1,4.4-2.5,6.1c-7.1-13.7-14.2-27.5-21.8-42.3
      C90.9,126.1,106.1,128.6,121.5,131.1z M97.6,132.7c-4.9-1-8.8-1.7-12.7-2.5c-0.3,0.4-0.6,0.7-0.9,1.1c2,3.6,4,7.1,6.4,11.5
      C93.1,139.2,95,136.4,97.6,132.7z"/>
    <path class="st0" d="M383.4,138.2c11.7-8.5,22.9-16.6,34.6-25.1c4.5,6.1,8.7,11.8,13.1,17.7c-1,1.2-1.8,2.3-3.1,3.9
      c-2.7-3.7-5.3-7-7.6-10.5c-2-3-3.9-4.3-6.9-1.3c-1.2,1.1-2.6,2-3.9,2.9c-2.3,1.6-2.1,3.1-0.5,5.1c2.1,2.6,3.9,5.5,5.7,8.2
      c-2.2,3.2-3.7,2.8-5.7,0.2c-6.3-8.4-6.4-8.3-15-2.2c-0.9,0.7-1.8,1.4-3,2.4c3.6,5.1,7,10,10.6,15.1c-2.8,2.5-4.7,2.3-6.8-0.8
      C391.4,148.8,387.6,143.9,383.4,138.2z"/>
    <path class="st0" d="M132,118.7c-0.4-0.5-2.2-1.5-2.5-2.8c-0.6-2.8,1.7-3,3.7-2.8c4.6,0.4,7.4-2,9-5.9c1.8-4.6-2.8-9.6-8.1-8.7
      c-3.3,0.6-6.6,1.6-9.9,2.6c-4.7,1.4-8.5-0.2-11.5-3.8c-3-3.6-3.7-7.8-1.5-12c2.6-5.1,6.8-8.2,12.8-8.1c1.1,0,2.2,1.6,3.4,2.4
      c-1,0.8-1.8,1.9-2.9,2.5c-2.3,1.2-5.2,1.7-7,3.4c-1.3,1.2-2,4.4-1.3,5.9c1,1.9,3.6,3.8,5.6,3.9c3,0.2,6.1-0.9,9.1-1.7
      c5.3-1.6,10.3-1.7,14.1,3.2c3.3,4.1,4.5,8.6,2,13.6C144.5,115.4,139.2,118.8,132,118.7z"/>
    <path class="st0" d="M235.1,39c-4.3,0.6-8.5,1.2-12.7,1.6c-3.6,0.3-4.6,2-4.1,5.6c1.3,8.3,1.1,8.3,9.5,7c1.2-0.2,2.4-0.7,3.5-0.5
      c1,0.2,2.5,1.2,2.5,2c0.1,0.8-1,2.4-1.9,2.6c-2.7,0.8-5.5,1.1-8.3,1.5c-0.7,0.1-2.1,0.3-2.1,0.5c0,4-0.1,8,0.6,11.9
      c0.2,1,2.9,2.2,4.3,2.1c4.7-0.3,9.3-1.3,14-2c1,3.4,0.5,5.2-3,5.6c-5.4,0.6-10.9,1.4-16.2,2.4c-2.8,0.5-3.7-0.6-4.1-3.1
      c-1.6-11.9-3.2-23.7-4.9-35.6c-0.4-2.5,0.8-3.6,3-3.9c5-0.7,10-1.3,14.9-2C233.4,34.2,234.8,35.5,235.1,39z"/>
    <path class="st0" d="M392.8,350.6c3.5-5.8,6.7-10.9,9.7-16.1c1.7-2.9,3.7-2.9,6.4-0.6c-2.4,3.7-4.5,7.5-7.1,11
      c-1.9,2.6-1.4,4,1.1,5.7c7.8,5.3,8.2,5.3,13.2-3c0.5-0.8,0.6-1.9,1.2-2.3c0.8-0.6,2.5-1.1,2.9-0.7c0.7,0.6,1.3,2.3,1,3.1
      c-1.3,2.6-3,5.1-4.6,7.6c-1.3,1.9-0.9,3.3,1,4.4c0.1,0.1,0.2,0.2,0.4,0.3c11.6,6.8,8.3,7.8,15.4-3.3c1.4-2.2,2.9-4.4,4.4-6.7
      c3.2,1.8,3.7,3.8,1.9,6.7c-3.5,5.3-6.8,10.7-10.5,16.6C416.9,365.6,405,358.2,392.8,350.6z"/>
    <path class="st0" d="M431.5,251.3c-1.3,3.5-3.2,6.6-3.3,9.7c-0.1,2,2.1,5,4.1,5.8c1.7,0.7,4.9-0.5,6.4-1.9c2-2,3.1-4.9,4.5-7.5
      c2.8-5.1,6.7-8.2,12.8-7.6c5.5,0.6,9.5,4.1,10.9,9.6c1.2,4.7-1.2,12-5.1,14.8c-0.8,0.6-2.9,0.6-3.3,0.1c-0.7-0.9-1-2.7-0.6-3.7
      c0.6-1.6,2.2-2.8,3-4.3c2-3.7,0.7-8.4-2.6-10.1c-3.7-1.9-7.6-0.9-9.9,2.9c-1.7,2.8-3.1,5.7-4.6,8.6c-2.5,4.9-6.8,5.8-11.7,5.1
      c-4.6-0.6-7.7-3.6-8.8-7.8c-1.3-4.8-1-9.7,2.7-13.8C428.8,248.4,429.5,248.4,431.5,251.3z"/>
    <path class="st0" d="M271,78.6c-4.7-15.7-9-30.2-13.4-45c3.8-1.3,5.8-0.8,6.8,3.2c2.4,9.6,5.1,19.1,8,29.8c3.5-6,6.5-11.1,9.5-16.2
      c2.3-4,4.7-7.9,6.9-11.9c1.5-2.7,3.1-2,5.8-0.1C286.9,51.4,279.3,64.5,271,78.6z"/>
    <path class="st0" d="M347.7,443.9c-7-12.4-13.8-24.6-20.8-37c1.4-1,2.7-2,4.5-3.3c3.8,6.8,7.5,13.3,11.1,19.8
      c1.9,3.5,3.9,7,5.7,10.5c1.2,2.3,2.7,2.7,5,1.3c3.6-2.2,7.4-4.2,11.3-6.3c2.4,3,1.5,5.1-1.3,6.6
      C358.4,438.1,353.4,440.8,347.7,443.9z"/>
    <path class="st0" d="M387.8,82.7c-8,9.7-15.9,19.3-24.1,29.2c4.4,3.7,8.5,7.2,12.6,10.7c-2.2,3.2-4.1,3.7-6.7,1.2
      c-3.5-3.2-7.1-6.3-10.8-9.2c-2.1-1.7-2.4-3.1-0.6-5.1c7.8-9.1,15.5-18.2,23.1-27.5C383.6,79.2,385.4,80.3,387.8,82.7z"/>
    <path class="st0" d="M344,55.3c1.7,0.7,3.2,1.2,5,2c-5,9.4-9.7,18.4-14.5,27.4c-3.5,6.6-3.5,6.9,3.3,10c2,0.9,4,1.9,5.9,3.1
      c0.8,0.5,1.2,1.7,1.7,2.6c-1.2,0.6-2.8,2.1-3.6,1.7c-5.5-2.6-10.9-5.4-16.1-8.5c-0.6-0.4-0.4-3.2,0.2-4.5
      C331.8,78.1,337.8,67,344,55.3z"/>
    <path class="st0" d="M314.9,43.6c2,0.6,3.3,1,5.1,1.5c-2.1,6.6-4,13-6.1,19.4c-2,6.3-4,12.6-6.3,18.9c-0.4,1-2.3,1.5-3.5,2.2
      c-0.6-1.1-2-2.5-1.7-3.4C306.4,69.4,310.6,56.7,314.9,43.6z"/>
    <path class="st0" d="M318.5,455.7c-4.3-12.4-8.7-24.6-12.7-36.9c-0.4-1.2,1.1-3,1.7-4.5c1.1,0.9,2.9,1.6,3.3,2.8
      c4,11.1,7.7,22.3,11.7,33.4C323.8,454.1,322.5,455.4,318.5,455.7z"/>
  </g>
  </svg>`

const BetterBusinessBureau = `<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
<style type="text/css">
	.st0{fill:#FFFFFF;}
</style>
<g>
	<path class="st0" d="M412.9,471c1.4,0,2.7,0.4,4,1.1c1.3,0.7,2.3,1.7,3.1,3c0.7,1.3,1.1,2.7,1.1,4.1c0,1.4-0.4,2.8-1.1,4.1
		c-0.7,1.3-1.7,2.3-3,3c-1.3,0.7-2.7,1.1-4.1,1.1c-1.4,0-2.8-0.4-4.1-1.1c-1.3-0.7-2.3-1.7-3-3c-0.7-1.3-1.1-2.7-1.1-4.1
		c0-1.4,0.4-2.8,1.1-4.1c0.7-1.3,1.8-2.3,3.1-3C410.2,471.4,411.5,471,412.9,471z M412.9,472.4c-1.2,0-2.3,0.3-3.4,0.9
		c-1.1,0.6-1.9,1.4-2.6,2.5c-0.6,1.1-0.9,2.2-0.9,3.4c0,1.2,0.3,2.3,0.9,3.4c0.6,1.1,1.4,1.9,2.5,2.5c1.1,0.6,2.2,0.9,3.4,0.9
		c1.2,0,2.3-0.3,3.4-0.9c1.1-0.6,1.9-1.4,2.5-2.5c0.6-1.1,0.9-2.2,0.9-3.4c0-1.2-0.3-2.3-0.9-3.4c-0.6-1.1-1.5-1.9-2.6-2.5
		C415.1,472.7,414,472.4,412.9,472.4z M409.3,483.8v-8.8h3c1,0,1.8,0.1,2.2,0.2c0.5,0.2,0.8,0.4,1.1,0.9c0.3,0.4,0.4,0.8,0.4,1.3
		c0,0.6-0.2,1.2-0.7,1.7s-1.1,0.7-1.8,0.8c0.3,0.1,0.6,0.3,0.7,0.5c0.4,0.3,0.8,0.9,1.3,1.7l1.1,1.7h-1.7l-0.8-1.4
		c-0.6-1.1-1.1-1.8-1.5-2.1c-0.3-0.2-0.6-0.3-1.1-0.3h-0.8v3.7H409.3z M410.7,478.8h1.7c0.8,0,1.4-0.1,1.7-0.4
		c0.3-0.2,0.5-0.6,0.5-1c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.4-0.6-0.5c-0.3-0.1-0.7-0.2-1.4-0.2h-1.6V478.8z"/>
</g>
<g>
	<path class="st0" d="M298.1,238c-2-1.4-3.5-2.4-4.1-2.8c1.9-6.4,4.6-12.2,5.2-18.1c1.2-13-4.8-22.9-15.4-30.3
		c-18.4-12.7-36.8-25.4-55.3-38.1c-10.1-7-18.2-15.7-23.1-27.1c-8.1-18.9-7.9-37.8,3.5-54.9c12.3-18.6,26.5-36,40.1-54.2
		c1.9,1.3,3.5,2.4,5.2,3.5c-4.2,6.8-5.2,13.8-0.9,20.6c2,3.2,5,5.9,8.1,8.1C283.1,59.9,304.9,75,326.8,90c14.5,10,24.3,23,27.7,40.5
		c2.9,15,1,29.6-7.7,42C331.3,194.7,314.6,215.9,298.1,238z"/>
	<path class="st0" d="M208.5,155.4c2.4,1.6,4.3,2.9,6.2,4.3c0,0.4,0,0.8-0.1,1.1c-2.7,6.7-1.5,11,4.4,15.1
		c14.6,10.1,29.2,20.1,43.8,30.1c11.1,7.6,17.9,17.7,19.5,31.2c1,8.4-0.2,16.6-4.8,23.5c-8.6,12.7-18.1,24.8-27.4,37.3
		c-2.5-1.7-4.5-3-6.4-4.3c3.1-8.4,1.9-12-5.4-17c-14.2-9.7-28.4-19.5-42.6-29.2c-11-7.6-17.9-17.7-19.5-31.2
		c-1-8.4,0.2-16.6,4.8-23.5C189.8,180.1,199.2,167.9,208.5,155.4z"/>
	<path class="st0" d="M95.3,379.9c4.7,0,9.2,0,13.7,0c12.6,0,25.1-0.4,37.7,0.2c6.9,0.3,14,1.3,20.5,3.5
		c10.2,3.4,16.6,10.9,17.4,22.1c0.8,10.3-3.2,18.4-12.2,23.9c-0.7,0.5-1.3,1.1-2.2,1.8c2.3,1.1,4.2,2,6.2,2.9
		c9.8,4.8,14.7,12.8,14.6,23.6c-0.1,11.1-5.2,19.2-15.2,24.2c-7.3,3.7-15.3,5.1-23.3,5.2c-18.6,0.3-37.3,0.1-55.9,0.1
		c-0.4,0-0.7-0.2-1.3-0.3C95.3,451.6,95.3,416,95.3,379.9z M119.2,442.4c0,8.8,0,16.5,0,24.2c12.3,0,24.3,0.5,36.3-0.2
		c7.5-0.5,11.1-5,11.2-11.1c0.1-6.2-3.3-10.8-10.7-11.5C143.9,442.7,131.7,442.8,119.2,442.4z M119.1,423.4c8.9,0,17.5,0.2,26.1-0.1
		c3.4-0.1,7-1,10-2.5c6-3,7.3-11.9,2.2-16.1c-2.7-2.2-6.7-3.7-10.1-4c-8.6-0.6-17.3-0.3-26-0.3c-0.7,0-1.4,0.3-2.2,0.5
		C119.1,408.4,119.1,415.7,119.1,423.4z"/>
	<path class="st0" d="M202.5,487.4c0-35.9,0-71.4,0-107.3c1.4-0.1,2.6-0.2,3.9-0.2c15.8,0,31.6-0.3,47.4,0.1
		c6.9,0.2,14,1.3,20.5,3.4c10.4,3.5,16.8,11,17.6,22.3c0.7,10.3-3.4,18.4-12.3,23.8c-0.6,0.4-1.2,0.9-2.2,1.7
		c2.4,1.1,4.4,2.1,6.4,3.1c9.7,4.9,14.6,12.9,14.5,23.7c-0.1,11-5.2,19-15,23.9c-7.1,3.6-14.7,5.1-22.5,5.2
		C241.5,487.6,222.2,487.4,202.5,487.4z M226.1,466.6c12.4,0,24.3,0,36.2-0.1c1.4,0,2.8-0.6,4.1-1.1c5.4-2.1,7.7-5.6,7.4-11.4
		c-0.3-5.7-4.5-10.1-11.8-10.6c-11.1-0.7-22.2-0.5-33.4-0.6c-0.8,0-2.3,1.4-2.3,2.1C226,452.1,226.1,459.2,226.1,466.6z
		 M226.3,423.4c9.3,0,18.2,0.2,27.1-0.1c3.1-0.1,6.4-1.1,9.2-2.6c5.7-3.1,7-11.8,2.1-15.9c-2.8-2.3-6.9-3.8-10.5-4.1
		c-7.4-0.7-15-0.3-22.5-0.3c-1.8,0-3.5,0-5.5,0C226.3,408.3,226.3,415.6,226.3,423.4z"/>
	<path class="st0" d="M309,379.9c5.2,0,10.3,0,15.3,0c12,0,24.1-0.4,36.1,0.2c6.9,0.3,14,1.3,20.5,3.5c10.2,3.4,16.6,10.9,17.4,22.1
		c0.8,10.3-3.2,18.4-12.2,23.9c-0.5,0.3-1.1,0.7-1.5,1.1c-0.2,0.1-0.2,0.5-0.4,0.8c2,0.9,3.9,1.8,5.9,2.8
		c9.8,4.8,14.7,12.8,14.6,23.6c-0.1,11.1-5.2,19.2-15.2,24.2c-7.5,3.7-15.5,5.1-23.7,5.2c-18.4,0.3-36.8,0.1-55.1,0.1
		c-0.5,0-1-0.1-1.7-0.2C309,451.6,309,416,309,379.9z M332.8,443.3c0,7.9,0,15.6,0,23.3c12.3,0,24.3,0.1,36.3-0.1
		c1.8,0,3.7-1,5.5-1.8c4-2,5.8-5.3,5.8-9.7c0-4.3-1.7-7.6-5.6-9.4c-2.5-1.1-5.4-2.1-8.1-2.2c-10.3-0.4-20.7-0.4-31.1-0.5
		C334.6,442.8,333.7,443.1,332.8,443.3z M332.9,400.6c0,7.8,0,15.2,0,22.8c9.2,0,18.1,0.2,27-0.1c2.9-0.1,5.8-1,8.5-2.2
		c3.9-1.7,5.7-5.1,5.6-9.4c0-4.1-1.8-7.3-5.5-9.1c-1.9-0.9-4.1-1.9-6.2-1.9C352.6,400.5,342.9,400.6,332.9,400.6z"/>
	<path class="st0" d="M147.4,331.4c2.2-7,4.3-13.5,6.4-20c0.2-0.6,1.2-1.2,1.9-1.4c0.8-0.2,1.8-0.1,2.7-0.1c61,0,122,0,182.9-0.1
		c3.2,0,4.8,0.8,5.7,4c1.5,5.7,3.5,11.3,5.4,17.5c-1.9,0-3.3,0-4.6,0c-14.2,0-28.5,0.1-42.7-0.1c-2.6,0-3.9,0.7-4.6,3.3
		c-2.4,7.9-5.1,15.7-7.5,23.6c-0.7,2.5-1.7,3.4-4.5,3.4c-25.6-0.1-51.3-0.1-76.9,0c-2.8,0-3.8-1-4.6-3.4c-2.4-7.9-5.2-15.7-7.5-23.6
		c-0.8-2.6-2-3.4-4.6-3.3c-14.2,0.1-28.5,0.1-42.7,0.1C150.7,331.4,149.3,331.4,147.4,331.4z"/>
</g>
</svg>`

const EnergyStar = `<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
<style type="text/css">
	.st0{fill:#FBFDFE;}
</style>
<g>
	<path class="st0" d="M18.3,249.7c0-78.2,0-156.3-0.1-234.5c0-2.1,0.4-2.7,2.6-2.7c152.7,0.1,305.4,0.1,458.1,0
		c2.5,0,2.7,0.7,2.7,2.9c-0.1,156.4-0.1,312.9,0,469.3c0,2.3-0.4,2.8-2.8,2.8c-152.6-0.1-305.2-0.1-457.8,0c-2.6,0-2.9-0.7-2.9-3
		C18.3,406.2,18.3,328,18.3,249.7z M249.9,366.1c72.3,0,144.7,0,217,0.1c2.5,0,3.4-0.4,3.4-3.2c-0.1-55.5-0.1-111.1,0-166.6
		c0-1.8-0.5-3.3-1.5-4.8c-15.8-23-34.6-43.2-56.7-60.3c-39.7-30.8-84.7-48.8-134.6-54.2c-14.8-1.6-29.6-1.7-44.5-0.8
		c-19,1.2-37.7,4.2-55.9,9.6c-60.9,18-109.3,53.2-145.6,105.2c-1.1,1.6-1.7,3.3-1.7,5.3c0,55.4,0.1,110.9,0,166.3
		c0,2.9,0.8,3.4,3.5,3.4C105.4,366.1,177.6,366.1,249.9,366.1z M250,475.5c72.2,0,144.4,0,216.7,0.1c2.6,0,3.5-0.4,3.5-3.4
		c-0.1-30.6-0.1-61.2,0-91.8c0-2.8-0.8-3.4-3.5-3.4c-144.5,0.1-289.1,0.1-433.6,0c-2.6,0-3.4,0.5-3.3,3.3c0.1,30.6,0.1,61.2,0,91.8
		c0,3,0.8,3.5,3.6,3.5C105.6,475.5,177.8,475.5,250,475.5z M470.2,177.1c0-0.8,0-1.3,0-1.9c0-49.5,0-99,0.1-148.5
		c0-2.8-1.2-2.8-3.2-2.8c-144.7,0-289.5,0-434.2,0c-2.6,0-3.1,0.7-3.1,3.2c0.1,49.1,0.1,98.2,0.1,147.3c0,0.8,0,1.6,0,2.5
		c56-72,128.5-111.8,220.2-111.8C341.6,65.2,414.1,105,470.2,177.1z"/>
	<path class="st0" d="M123.9,256.8c-7.2,7.1-14.6,13.8-23.3,19.1c-6.2,3.7-12.6,6.9-20.1,6.7c-5.9-0.2-10.6-4.3-10.8-10.2
		c-0.1-2.7-1.1-2.8-3.3-2.4c-8.7,1.7-17.3,1.8-25.9-0.7c-0.8-0.2-1.7-0.5-2.4-0.9c-1.8-0.9-2.7-2.3-1.9-4.2c0.8-1.9,2.3-2.8,4.4-2.1
		c6.2,2.2,12.5,2.5,19,2c3.4-0.3,6.6-1.1,9.9-1.8c1.3-0.3,1.8-1.2,2.3-2.3c6.3-13.9,14.4-26.6,25-37.6c4.3-4.4,9-8.3,14.7-10.8
		c1.8-0.8,3.5-1.4,5.5-1.6c8.1-0.9,12.6,4.3,10.6,12.2c-1.4,5.5-4.5,10.1-8.2,14.3c-11.4,13.1-24.7,23.7-41.1,30.1
		c-2.7,1.1-1.8,3.2-1.8,5.1c0.1,2.4,1.6,3.6,3.9,3.9c2.9,0.4,5.7-0.3,8.4-1.4c7.8-3.1,14.5-8.1,20.8-13.6
		c8.9-7.6,16.8-16.2,24.3-25.2c0.5-0.6,1-1.2,1.5-1.7c1.4-1.4,3-1.8,4.6-0.6c1.6,1.2,1.9,2.8,0.9,4.6c-1.7,3.1-3.5,6.1-4.7,9.5
		c-0.5,0.7-1.2,1.3-0.8,2.4c0.8-0.4,1-1.1,1.4-1.8c1.9-2.3,3.7-4.6,5.6-6.9c1.5-1.8,3.1-3.4,5.2-4.5c1.8-1,3.7-1.1,5.4,0.1
		c1.7,1.1,2.1,2.8,1.9,4.8c-0.5,4.7-2,9.2-2,13.9c0,4.8,0.8,5.4,5.5,3.8c3.4-1.2,6.3-3.1,9.3-5c1.1-0.7,1.2-1.8,1.6-2.9
		c2.4-7.7,5.7-14.9,12-20.2c1.8-1.5,3.7-2.6,6-3.2c3.1-0.8,6-0.6,8.2,1.9c2.2,2.6,1.7,5.5,0.5,8.4c-1.1,2.4-2.8,4.3-4.7,6.1
		c-4.7,4.4-9.7,8.4-14.9,12.2c-1,0.8-2.4,1.6-1.6,2.9c0.6,1,2.1,0.4,3.2,0c5.9-2,10.8-5.7,15.5-9.7c7.3-6,13.4-13.1,19.2-20.6
		c1.1-1.5,2.2-3.1,4.4-2.7c2.3,0.4,2.8,2.2,3,4.4c1.8-1.7,3.3-3.5,5.4-4.6c3.2-1.7,5.4-0.9,6.4,2.5c0.8,2.7,1.8,5.2,3.8,7.3
		c1.3,1.4,2.1,1.7,3.4-0.2c4.5-6.5,9.9-12.2,16.8-16.3c3.6-2.1,7.4-3.7,11.6-3.6c2.9,0.1,5.5,1.1,6.8,4c1.3,2.8,0.3,5.3-1.6,7.4
		c-2,2.3-4.4,4.3-6.8,6.2c-1.1,0.9-2.2,1.6-0.5,3c0.8,0.7,0.5,1.8,0.7,3c3.9-2.4,7.7-4.9,11.6-7.2c5-3,10.3-5.5,15.6-7.9
		c2-0.9,3.7-1,5.1,0.9c1.3,1.9,0.4,3.4-0.9,4.8c-4.1,4.7-7.9,9.6-9.9,15.7c-0.4,1.4-1.5,3.4-0.2,4.3c1.2,0.9,2.7-0.7,4-1.5
		c6.6-4.6,11.5-10.9,16.3-17.3c2.9-3.9,5.6-8,8.3-12.1c1.2-1.9,2.8-2.8,4.8-1.9c2.1,1,2.3,2.8,1.6,4.9c-1.8,5.6-3.5,11.3-5.5,17.5
		c3.7-2.3,7-4.3,10.3-6.3c16.8-9.9,34.7-17,53.3-22.3c2.6-0.7,4.7,0.1,5.1,2.2c0.5,2.6-1,3.8-3.3,4.4c-18.2,5.2-35.7,12.1-52,21.7
		c-0.5,0.3-1,0.6-1.5,0.9c-5.2,3.2-10.9,6-15,10.5c-4,4.4-4.9,10.8-7.4,16.3c-5.7,12.8-11.6,25.4-19.8,36.9c-2,2.8-4.2,5.5-7.3,7.3
		c-6.1,3.5-11.5,0.4-11.6-6.6c-0.1-6.3,2.2-12,4.9-17.5c7.7-15.3,19.3-27.5,32.4-38.4c2.3-1.9,3-3.3,3.4-7.2
		c-3.6,4.1-7,8.1-11.1,11.2c-2.5,2-5.1,3.7-8.3,4.4c-4.4,1.1-8.1-1.4-8.8-5.9c-0.6-3.8,0.6-7.2,1.9-10.6c0.5-1.1,1.1-2.2,1.7-3.4
		c-1-0.4-1.5,0.3-2,0.6c-2,1.1-3.9,2.4-5.9,3.5c-8.8,4.7-15,11.2-17.8,21.4c-2.6,9.6-7.6,18.6-13.5,26.8c-2.8,3.9-5.9,7.6-10,10.2
		c-2.4,1.6-5.1,2.5-7.8,0.7c-2.6-1.8-2.9-4.6-2.5-7.4c1.4-8.6,5.9-15.7,10.6-22.7c3.7-5.4,7.9-10.3,12.9-15.4
		c-2.4,0.8-4.3,1.5-6.2,1.9c-6.7,1.5-10.7-2.6-8.9-9.3c0.2-0.7,0.4-1.5,0.7-2.2c0.5-1.2,0.5-1.9-0.8-2.8c-3-2-4.7-4.9-6.3-8.4
		c-3.3,3.3-5.8,7-8.4,10.6c-3.5,4.9-6.9,9.9-10.3,14.8c-0.3,0.4-0.5,0.8-0.8,1.2c-1.5,1.8-3.1,3.5-5.6,2c-2.2-1.4-1.9-3.5-1-5.6
		c0.8-1.9,1.6-3.8,2.6-6.4c-3.2,2.6-5.8,4.9-8.5,7c-4.6,3.5-9.3,6.7-15,8.3c-4.1,1.1-8,1.3-10.7-3c-0.7-1.2-1.4-0.5-2.1-0.1
		c-2.9,1.5-5.8,3-9,3.6c-6.6,1.2-11-2.4-11.4-9.1c-0.2-3.5,0.1-6.9,0.9-10.8c-2.6,2.1-4,4.5-5.5,6.7c-4,5.7-7.9,11.5-11.8,17.3
		c-0.6,0.9-1.2,1.7-1.9,2.5c-1.2,1.4-2.5,2.4-4.5,1.5c-1.9-0.9-2.2-2.4-1.9-4.3c0.6-3.6,1.5-7.1,2.5-10.6c0.2-0.7,0.4-1.5,0.6-2.2
		c0.3-0.3,0.5-0.7,0.2-1.2C124.4,256.4,124.1,256.6,123.9,256.8z M251.9,242.7c-2.8,0.9-5.5,2.4-8.5,2.3c-2.5-0.1-3.4,1.1-3.9,3.1
		c-0.2,0.6-0.6,1.3-0.1,1.9c0.6,0.5,1.3-0.1,1.8-0.3c4.1-1.5,7.6-3.9,10.9-6.6c0.2-0.2,0.8-0.3,0.5-0.7
		C252.5,242.1,252.1,242.3,251.9,242.7z M246.8,237c8-2.7,14-8.4,20.3-14c-1.3-0.7-2.2-0.3-3.1,0c-4.2,1.3-7.8,3.6-10.9,6.6
		C250.9,231.9,248.1,233.8,246.8,237l-0.2,0.1L246.8,237z M249.9,262.5c-8.3,8.9-15.5,18.6-18.6,30.6c9.6-8,14.8-18.7,19.2-30
		c0.4-0.3,0.6-0.7,0.3-1.2C250.5,262.1,250.2,262.3,249.9,262.5z M80.6,257.9c13.6-6.4,25-15.6,34.5-27.1c2.7-3.2,5.1-6.7,5.8-11.1
		c0.4-2.3-0.5-3.2-2.9-2.9c-3.4,0.4-6.1,2.1-8.8,4C96.2,230.4,88,243.8,80.6,257.9z M300.8,261.5c-3.4,2.8-6.4,6-9.3,9.3
		c-6.9,8.2-13,16.8-16.2,27.2c-0.7,2.4-1.4,4.9-0.7,7.5c2.3-0.9,3.7-2.4,5-4.1C288.8,289.3,294.9,275.5,300.8,261.5z M179.2,245
		c7.1-5,9.8-7.8,10.5-11.1C184.3,236.1,181.5,240.3,179.2,245z"/>
	<path class="st0" d="M330.3,292.4c6.8-4.3,13.3-8.4,19.7-12.5c6.5-4.2,13.1-8.3,19.6-12.5c3-2,5.6-1.9,8.7,0.1
		c11.9,7.8,23.9,15.5,35.9,23.3c0.7,0.5,1.4,0.9,2.8,1.7c-1.4-5.7-2.7-10.8-4-15.9c-2.5-9.7-4.9-19.5-7.5-29.2
		c-0.8-3.1-0.2-5.4,2.4-7.6c11.2-9.2,22.3-18.5,33.4-27.7c0.6-0.5,1.3-1.1,2.5-2.2c-5-0.3-9.3-0.7-13.6-0.9
		c-11.1-0.6-22.2-1.3-33.3-1.8c-3.3-0.2-4.9-1.9-6-4.8c-5.1-13.4-10.4-26.7-15.6-40.1c-0.4-1-0.8-1.9-1.5-3.4
		c-2.6,6.5-4.9,12.6-7.3,18.6c-3.3,8.4-6.6,16.7-9.7,25.1c-1.2,3.2-3.1,4.6-6.6,4.8c-19.5,1-39.1,2.1-58.6,3.2
		c-4.2,0.2-6.8-1.7-6.8-5.2c0-2.8,1.8-4.8,4.8-5c13.8-0.8,27.6-1.5,41.4-2.3c4.7-0.3,9.4-0.6,14.1-0.6c2.1,0,3-0.9,3.7-2.7
		c6.5-16.9,13.1-33.7,19.6-50.5c0.8-2,1.7-3.9,4-4.6c2.7-0.8,5.2,0.5,6.5,3.7c4.4,11,8.7,22.1,13,33.1c2.4,6.1,4.8,12.3,7.1,18.5
		c0.6,1.6,1.4,2.2,3.1,2.3c15.2,0.8,30.4,1.8,45.6,2.7c3.2,0.2,6.3,0.4,9.5,0.6c2.5,0.1,4.2,1.2,5,3.5c0.9,2.4-0.1,4.3-1.9,5.8
		c-5.8,4.9-11.6,9.7-17.5,14.5c-8.4,7-16.8,14-25.2,20.9c-1.3,1.1-1.5,2-1.1,3.7c4.6,17.7,9.1,35.4,13.6,53.1
		c0.7,2.6,0.5,4.8-1.8,6.4c-2.3,1.6-4.5,1-6.7-0.4c-15.2-9.8-30.4-19.6-45.6-29.5c-1.6-1.1-2.7-1.1-4.3-0.1c-15,9.6-30,19.2-45,28.8
		c-1,0.6-1.9,1.3-3,1.8c-1.9,0.7-3.8,0.6-5.4-0.7c-1.8-1.5-2.1-3.5-1.5-5.7c3.7-13.2,7.3-26.4,10.9-39.5c1.4-5.1,2.8-10.1,4.3-15.2
		c1-3.5,3.4-5.2,6.2-4.4c3.2,0.8,4.6,3.5,3.6,7.2c-3.7,13.6-7.5,27.1-11.2,40.6C330.5,291.3,330.4,291.6,330.3,292.4z"/>
	<path class="st0" d="M92.5,406.8c0,0.9,0,1.8,0,2.7c0,13.9-0.1,27.8,0.1,41.7c0,2.8-1.4,2.5-3.2,2.5c-1.8,0-3.2,0.3-3.2-2.5
		c0.1-16.7,0.1-33.4,0-50.1c0-1.8,0.4-2.3,2.3-2.5c5.5-0.7,8.5,1,10.8,6.6c5,12.6,11.1,24.8,16.7,37.1c0.4,0.9,0.9,1.8,1.8,2.7
		c0-0.9,0-1.8,0-2.7c0-13.7,0.1-27.4-0.1-41.1c0-2.3,0.8-2.8,2.8-2.6c1.6,0.2,3.6-0.8,3.5,2.4c-0.1,16.7-0.1,33.4,0,50.1
		c0,2-0.6,2.6-2.6,2.6c-7.1,0-7,0.1-9.9-6.3c-6.1-13.6-12.3-27.1-18.5-40.7C92.9,406.8,92.7,406.8,92.5,406.8z"/>
	<path class="st0" d="M193.1,425.3c7.4,0.9,8.4,5.6,8.9,10.9c0.4,5.1,0.5,10.3,1.6,15.4c0.3,1.5,0.2,2.4-1.7,2.2
		c-0.9-0.1-1.7-0.1-2.6,0c-2,0.3-2.9-0.5-3.3-2.5c-0.7-3.4-0.9-6.8-0.9-10.3c0-0.9,0-1.7-0.1-2.6c-0.5-8.2-2.4-10-10.6-10
		c-6.3,0-6.3,0-6.3,6.3c0,5.6-0.1,11.1,0,16.7c0,1.9-0.6,2.7-2.4,2.4c-0.3,0-0.6,0-0.9,0c-3.7,0-3.7,0-3.7-3.6
		c0-16.2,0-32.4-0.1-48.6c0-2.3,0.6-2.9,2.8-2.9c5.1,0.2,10.2,0,15.2,0.1c8,0,12.6,3.4,13.8,10.1
		C204.4,416.9,201.5,422.1,193.1,425.3z M178.1,413c0,2.9,0.1,5.3,0,7.7c0,1.4,0.5,1.8,1.8,1.8c3.1-0.1,6.1,0.1,9.2,0
		c2.8-0.1,4.8-1.7,6-4.2c3.4-7.2-0.4-13.4-8.3-13.4c-2.8,0-6.3-1.2-8.1,0.5C176.8,407,178.5,410.7,178.1,413z"/>
	<path class="st0" d="M440.2,425.2c7.3,1,8.5,5.7,8.8,11.1c0.4,5.3,0.7,10.5,1.7,15.7c0.2,1.3,0,1.8-1.3,1.8c-0.3,0-0.6,0-0.9,0
		c-5.1,0.1-5.6-0.3-6-5.5c-0.3-4.5-0.4-9-1-13.5c-0.5-4.1-2.7-5.9-6.9-6.3c-9.6-0.9-9.6-0.9-9.6,8.6c0,4.8-0.1,9.6,0,14.4
		c0,1.8-0.5,2.3-2.2,2.3c-4.8,0.1-4.8,0.1-4.8-4.7c0-15.9,0-31.8,0-47.7c0-2.1,0.5-2.8,2.7-2.7c5.3,0.2,10.5,0,15.8,0.1
		c7.7,0,12.3,3.7,13.3,10.3C451.2,417.2,448.4,422.3,440.2,425.2z M425.2,413.3c0,2.4,0.1,4.8,0,7.2c-0.1,1.5,0.5,2,2,1.9
		c3-0.1,5.9,0,8.9-0.1c4.1-0.1,6.9-3.5,7.1-8.2c0.3-5.4-2-8.9-6.3-9.3c-3.3-0.3-6.7-0.1-10-0.2c-1.3,0-1.7,0.5-1.6,1.7
		C425.2,408.7,425.2,411,425.2,413.3z"/>
	<path class="st0" d="M217.4,425.8c0,3.7-0.1,7.5,0,11.2c0.3,9.7,4.4,13.1,13.9,11.8c1.2-0.2,2.4-0.6,3.6-1c2-0.5,2.7-1.6,2.6-3.8
		c-0.2-3.8-0.2-7.7,0-11.5c0.1-2.1-0.7-2.7-2.6-2.5c-1.9,0.1-3.8-0.1-5.8,0.1c-2.8,0.2-1.8-1.9-1.8-3.2c0-1.2-0.8-3,1.8-2.9
		c4.5,0.1,9,0.1,13.5,0c1.4,0,1.8,0.5,1.8,1.9c0,7.8,0,15.5,0,23.3c0,1.1-0.4,1.8-1.4,2.2c-6.8,3.2-13.9,4.4-21.3,2.7
		c-6.6-1.5-10.3-6.2-11.1-14c-0.9-9.5-1-19-0.1-28.4c0.8-8.5,6.8-13.8,15.3-14.1c3.8-0.1,7.5,0.1,11,1.4c5.1,1.9,8.5,8,7.5,13.2
		c-0.2,0.9-0.5,1.1-1.3,1.1c-0.7,0-1.4-0.1-2,0c-2.6,0.4-4.1-0.1-4.3-3.4c-0.3-3.9-3.3-6.1-7.5-6.6c-5-0.6-9,1.4-10.8,5.3
		c-0.7,1.7-1.2,3.4-1.2,5.3C217.4,418,217.4,421.9,217.4,425.8C217.4,425.8,217.4,425.8,217.4,425.8z"/>
	<path class="st0" d="M390.5,440.7c-2.2,0-4.4,0.2-6.6-0.1c-3.3-0.5-5.1,0.7-5.7,4.1c-0.3,1.8-1,3.5-1.6,5.2c-1.2,3.8-3,4.8-6.9,3.9
		c-1.7-0.4-0.8-1.4-0.6-2.2c2.6-8.4,5.3-16.8,7.9-25.2c2.7-8.4,5.4-16.7,7.9-25.2c0.5-1.8,1.2-2.6,3.3-2.6c6.4,0.1,6.5,0,8.5,6.1
		c5.1,15.5,10.1,31.1,15.2,46.6c0.6,1.9,0.4,2.4-1.7,2.4c-5.4,0-5.3,0.1-6.9-5c-0.8-2.6-1-6.1-2.8-7.6c-1.8-1.6-5.3-0.3-8.1-0.5
		C391.9,440.7,391.2,440.7,390.5,440.7z M390.3,405.7c-0.2,0-0.4,0-0.5,0c-2.7,9.1-5.3,18.2-8,27.3c-0.5,1.6,0.4,1.5,1.4,1.5
		c4.6,0,9.2-0.1,13.8,0c2,0,1.9-0.7,1.4-2.2c-2-6.5-3.9-13-5.9-19.5C391.8,410.5,391,408.1,390.3,405.7z"/>
	<path class="st0" d="M319.5,397.7c9.6,0,14.4,4.4,15,13.6c0.1,1.8-0.3,2.8-2.3,2.4c-0.4-0.1-0.8-0.1-1.1,0
		c-2.6,0.4-3.9-0.3-4.1-3.2c-0.4-4.2-2.6-6.2-6.3-6.6c-4.6-0.6-8,0.9-9.6,4.1c-1.6,3.4-0.8,7.1,2,9.9c2,1.9,4.3,3.1,6.7,4.4
		c3.5,1.8,7,3.5,10,6.1c5.3,4.6,6.1,10.4,4.4,16.8c-1.7,6-6.3,8.6-12.1,9.5c-3.1,0.5-6.1,0.5-9.2-0.1c-7.4-1.4-12.1-8.3-11-15.8
		c0.1-0.9,0.4-1.4,1.4-1.4c0.8,0,1.6,0.1,2.3,0c2.5-0.4,3.2,0.5,3.3,3c0.1,4.6,2.9,7.6,7.2,8.4c4.8,0.8,9.3-1.1,10.9-4.5
		c2-4.2,0.9-8.4-3-11.5c-3.9-3.1-8.7-4.8-12.9-7.5c-6.4-4.1-9.1-9.6-7.6-16.2C305.2,401.7,310.8,397.6,319.5,397.7z"/>
	<path class="st0" d="M48.8,426.2c0-8.3,0.1-16.7,0-25c0-1.9,0.5-2.4,2.4-2.4c7.9,0.1,15.7,0.2,23.6,0c2.8-0.1,2.2,1.6,2.2,3.1
		c0,1.6,0.4,3.2-2.3,3.1c-5.6-0.2-11.1,0-16.7-0.1c-1.6,0-2.5,0.2-2.4,2.2c0.1,4.5,0.1,9,0,13.5c0,1.7,0.6,2.1,2.2,2.1
		c5.2-0.1,10.4,0.1,15.5-0.1c3.2-0.1,1.9,2.1,2.1,3.5c0.2,1.5,0.3,2.8-2,2.7c-5.2-0.2-10.4,0-15.5-0.1c-1.8,0-2.3,0.5-2.3,2.3
		c0.1,4.9,0.1,9.8,0,14.7c0,1.8,0.4,2.3,2.2,2.3c5.8-0.1,11.5,0.1,17.3-0.1c3-0.1,2.3,1.6,2.4,3.3c0.1,1.8,0,3-2.5,2.9
		c-8-0.2-15.9-0.1-23.9,0c-2.2,0-2.5-0.8-2.4-2.7C48.9,442.9,48.8,434.5,48.8,426.2z"/>
	<path class="st0" d="M133.9,426.3c0-8.2,0.1-16.5,0-24.7c0-2,0.3-2.8,2.6-2.8c7.7,0.2,15.4,0.2,23,0c2.9-0.1,2.6,1.4,2.7,3.3
		c0.1,2.3-0.6,2.9-2.8,2.9c-5.4-0.2-10.7,0-16.1-0.1c-2.1-0.1-2.6,0.7-2.5,2.7c0.1,4.1,0.2,8.3,0,12.4c-0.1,2.1,0.4,2.8,2.7,2.8
		c5-0.2,10,0.1,15-0.1c2.6-0.1,2.3,1.4,2.2,2.9c-0.1,1.4,0.8,3.4-2.1,3.3c-5.2-0.2-10.4,0-15.5-0.1c-1.6,0-2.2,0.4-2.2,2.1
		c0.1,5,0.1,10,0,15c-0.1,1.9,0.8,2.2,2.4,2.1c5.7-0.1,11.3,0.1,17-0.1c2.7-0.1,2.6,1.3,2.6,3.1c0,2-0.1,3.1-2.7,3.1
		c-7.9-0.2-15.7-0.1-23.6,0c-2,0-2.6-0.5-2.6-2.6C134,443,133.9,434.6,133.9,426.3z"/>
	<path class="st0" d="M248.7,399c0.2-0.1,0.4-0.1,0.6-0.2c6.7-0.3,6.7-0.3,9.1,5.9c2.7,6.7,5.4,13.4,8.4,20.7
		c2.1-4.9,4-9.3,5.8-13.6c1.4-3.3,3-6.6,4.1-10.1c0.8-2.4,2.1-3.1,4.4-2.8c1.1,0.1,2.3,0,3.8,0c-2.8,6.5-5.5,12.6-8.2,18.8
		c-1.7,3.8-3.4,7.7-5.1,11.5c-1.1,2.5-1.7,5-1.6,7.8c0.2,4.9,0,9.8,0.1,14.7c0,1.8-0.5,2.3-2.3,2.3c-4.7,0-5,0.1-4.6-4.5
		c0.9-11.2-1-21.6-6.4-31.5c-2.8-5.2-4.8-10.8-7.1-16.3C249.2,400.8,248.6,400,248.7,399z"/>
	<path class="st0" d="M352.8,429c0-7.3-0.1-14.6,0-21.8c0-1.9-0.6-2.5-2.4-2.4c-3.3,0.1-6.7-0.1-10.1,0.1c-3,0.1-1.8-2-1.9-3.4
		c-0.2-1.4-0.4-2.7,2-2.7c10.5,0.1,21.1,0.2,31.6,0c2.9,0,2,1.8,2.1,3.2c0.1,1.6,0.3,3-2.2,2.9c-3.2-0.2-6.5,0.1-9.8-0.1
		c-2.1-0.1-2.5,0.7-2.5,2.6c0.1,14.6-0.1,29.1,0.1,43.7c0,3.1-1.5,2.8-3.5,2.8c-1.9,0-3.7,0.5-3.6-2.7
		C352.9,443.7,352.8,436.4,352.8,429z"/>
</g>
</svg>`

const EqualHousingOpportunity = `<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
<style type="text/css">
	.st0{fill:#FFFFFF;}
</style>
<g>
	<path class="st0" d="M248.9,21.8c0.2,0,0.3,0,0.5,0c0.8,0.5,1.6,1.1,2.4,1.5c24.6,12.6,49.2,25.1,73.8,37.6
		c16.8,8.5,33.5,17.1,50.3,25.6c25.4,12.9,50.7,25.9,76.1,38.9c11.8,6,23.7,12.1,35.5,18.1c0,19.9,0,39.9,0,59.8
		c-6.9,0-13.8,0.1-20.9,0.1c0,58.2,0,116.1,0,174c-0.4,0.1-0.7,0.2-0.9,0.2c-142.3,0-284.7,0-427,0c-0.3,0-0.7-0.1-1-0.1
		c-0.1,0-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1,0-0.2-0.1-0.3c0-0.2-0.1-0.3-0.1-0.5c0-57,0-114,0-171c0-0.2,0-0.3,0-0.5
		c0-0.2,0-0.3,0-0.5c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.1-0.3-0.4c-7.9,0-15.9,0-24.1,0
		c0-20,0-39.9,0-59.7c1.6-0.9,3.2-1.7,4.8-2.6c13.4-6.9,26.9-13.8,40.3-20.7c17.8-9.2,35.6-18.4,53.4-27.5
		c18.4-9.5,36.9-18.9,55.3-28.4c25.9-13.3,51.7-26.6,77.6-39.9C245.7,23.8,247.3,22.7,248.9,21.8z M90.5,163.4c0,53.8,0,107.3,0,161
		c107.7,0,215.2,0,322.8,0c0-53,0-105.9,0-158.8c-0.9-0.5-1.6-0.9-2.4-1.3c-6.1-3.1-12.1-6.2-18.2-9.3
		c-25.1-12.8-50.2-25.6-75.2-38.4c-22-11.2-44-22.4-66-33.7c-1.7-0.9-3-0.9-4.8,0c-9.2,4.8-18.4,9.5-27.6,14.3
		c-14.7,7.5-29.3,15.1-44,22.6c-21.1,10.9-42.2,21.7-63.4,32.6C104.7,156.1,97.6,159.7,90.5,163.4z"/>
	<path class="st0" d="M177.7,478.2c-0.4-0.2-0.8-0.6-1.2-0.7c-5.6-1.8-9-5.7-10.5-11.2c-1.3-4.8-1.3-9.8,0.2-14.6
		c2.1-6.8,7.6-10.8,14.7-10.9c1.6,0,3.3-0.1,4.9,0.2c6.4,1.1,10.4,5,12.2,11.2c1.4,4.7,1.3,9.4,0.1,14.1c-1.5,5.6-5,9.6-10.7,11.3
		c-0.4,0.1-0.6,0.4-1,0.6C183.6,478.2,180.6,478.2,177.7,478.2z M191.8,459.4c-0.1-1.2-0.1-2.4-0.2-3.6c-0.2-1-0.5-2-0.9-3
		c-1.5-3.8-4.3-5.7-8-5.9c-4.7-0.3-7.8,2.3-9,5.6c-1.8,4.6-1.9,9.3,0.1,13.9c1.5,3.7,4.4,5.6,8.5,5.6c4.1,0,6.8-2.1,8.4-5.7
		C191.6,464,191.9,461.7,191.8,459.4z"/>
	<path class="st0" d="M55,478.2c-0.5-0.3-1-0.6-1.5-0.7c-5.5-1.6-8.7-5.5-10.3-10.7c-1.4-4.7-1.3-9.6-0.1-14.4
		c1.8-7.1,7.7-11.6,15-11.6c1.6,0,3.1-0.1,4.7,0.2c6.7,1.2,10.9,5.2,12.6,11.8c1.2,4.3,1.2,8.7,0.1,13.1c-1.5,6-5,10.1-11,11.9
		c-0.4,0.1-0.7,0.4-1.1,0.6C60.5,478.2,57.8,478.2,55,478.2z M69.2,459.6c-0.1,0-0.1,0-0.2-0.1c-0.1-0.9-0.1-1.9-0.2-2.8
		c-0.2-1.1-0.4-2.2-0.8-3.3c-1.3-4.1-4.3-6.3-8.1-6.5c-5.3-0.3-8.2,2.7-9.4,6.2c-1.5,4.2-1.4,8.5,0,12.7c1.4,4,4.6,6.1,8.7,6.2
		c4.1,0,7.3-2.1,8.7-6.1C68.6,463.9,68.8,461.7,69.2,459.6z"/>
	<path class="st0" d="M299.3,478.2c-0.7-0.3-1.3-0.7-2.1-1c-4.8-1.5-7.5-4.9-7.7-9.7c-0.3-8.5-0.1-17-0.1-25.6c2.2,0,4.5,0,7.2,0
		c0,0.8,0.1,1.6,0.1,2.4c0,6.6,0,13.2,0,19.8c0,1.3,0.2,2.6,0.5,3.8c0.5,2.2,2,3.5,4.1,3.8c1.4,0.2,3,0.2,4.4,0
		c2.1-0.4,3.6-1.7,4-3.9c0.3-1.3,0.5-2.7,0.5-4.1c0-7.2,0-14.4,0-21.8c2.4,0,4.7,0,7.1,0c0.1,0.5,0.2,1.1,0.2,1.7
		c0,7.3,0,14.6,0,21.8c0,3.3-0.6,6.4-3.1,8.8c-1.2,1.2-2.8,2-4.3,2.8c-0.8,0.5-1.7,0.7-2.6,1.1C304.9,478.2,302.1,478.2,299.3,478.2
		z"/>
	<path class="st0" d="M106.1,421.1c1.1,1,2.3,2.1,3.8,3.4c-1.3,1.5-2.4,2.8-3.6,4.2c-1.5-1.4-2.8-2.6-4.2-3.9
		c-0.6,0.3-1.2,0.5-1.9,0.8c-9.2,4-22.2,0.3-24-13c-0.6-4.5-0.4-8.8,1.2-13c2.3-6,7.8-9.7,14.3-9.9c4-0.1,7.7,0.4,11.1,2.8
		c3.8,2.6,6,6.3,6.7,10.7c0.7,4.4,0.7,8.8-0.9,13.1C107.9,417.8,107,419.3,106.1,421.1z M96.9,419.9c-1.3-1.1-2.5-2.1-3.7-3.1
		c1.6-1.6,2.8-2.8,4-4c1.5,1,2.2,2.4,3.8,3c2.1-4.7,2.1-9.4,0.6-14c-0.7-2.1-1.9-3.9-4-4.9c-4.2-2.1-11.1-1.5-13.4,5.1
		c-1.4,4.1-1.5,8.3-0.1,12.5C85.9,419.9,90.8,422.1,96.9,419.9z"/>
	<path class="st0" d="M447.7,412.9c-2.7,0-5.2,0-7.9,0c0-2,0-3.9,0-5.8c0.4,0,0.7-0.1,1-0.1c4.3,0,8.6,0,12.9,0
		c0.6,1.9,0.7,15.2,0.2,19c-1.4,0-2.8,0-4.4,0c-0.3-1.2-0.5-2.5-0.9-3.9c-0.4,0.2-0.8,0.4-1,0.7c-5.5,6.5-17.6,5.4-22.7-1.9
		c-1.8-2.6-2.9-5.5-3.3-8.6c-0.7-5.8,0-11.4,3.6-16.3c2.8-3.8,6.6-5.8,11.2-6.1c2.5-0.2,5.1,0,7.4,0.5c5.4,1.3,8.8,4.8,10.1,10.3
		c0.1,0.2,0,0.5-0.1,0.9c-2.3,0-4.5,0-6.8,0c-0.1-0.1-0.3-0.3-0.4-0.5c-1.2-3.6-4-5.1-7.5-5.3c-3.6-0.2-6.6,1.3-8.5,4.5
		c-1.7,2.9-2,6-2,9.2c0,1.6,0.4,3.3,0.8,4.8c1.1,4.5,6,6.9,9.7,6.6C443.7,420.6,446.7,417.9,447.7,412.9z"/>
	<path class="st0" d="M331.2,441.8c1.6,0,3,0.1,4.3,0c2.4-0.3,3.7,0.6,4.9,2.7c3.4,6.4,7.1,12.6,10.7,18.9c0.4,0.7,0.9,1.4,1.6,2
		c0-7.8,0-15.5,0-23.5c2.3,0,4.4,0,6.5,0c0.5,1.8,0.6,31.1,0.1,35.2c-2,0-4,0-6.1,0c-1.2,0-1.5-1.1-1.9-1.8
		c-3.9-6.7-7.7-13.4-11.5-20c-0.4-0.8-0.9-1.5-1.4-2.3c-0.2,0.1-0.3,0.1-0.5,0.2c0,7.8,0,15.7,0,23.7c-2.3,0-4.4,0-6.7,0
		C331.2,465.4,331.2,453.7,331.2,441.8z"/>
	<path class="st0" d="M265.3,409.3c0.1-4.1,0.3-7.3,1.7-10.3c2.6-5.8,7.1-9,13.5-9.3c2.1-0.1,4.2-0.1,6.2,0.3
		c6.5,1.3,10.3,5.6,11.9,11.9c1.1,4.5,1,9.1-0.3,13.6c-1.5,5.3-6,10.8-13.5,11.4c-2.6,0.2-5.2,0.1-7.7-0.5
		c-5.9-1.5-9.3-5.7-10.9-11.4C265.6,412.9,265.5,410.6,265.3,409.3z M292.2,408.7c-0.3-2.1-0.4-3.9-0.8-5.5
		c-1.1-4.5-4.2-7.3-8.5-7.5c-4.4-0.2-7.6,1.8-9.2,5.8c-1.8,4.6-1.7,9.3,0.2,13.9c1.5,3.7,5,5.7,8.9,5.5c4-0.3,6.8-2.4,8.1-6.2
		C291.6,412.7,291.9,410.5,292.2,408.7z"/>
	<path class="st0" d="M218.3,463.2c0,4.8,0,9.3,0,13.9c-2.3,0-4.5,0-6.7,0c0-11.7,0-23.3,0-35c0.3-0.1,0.6-0.2,1-0.2
		c5.7,0,11.4-0.1,17.1,0c4.6,0.1,7.8,2.4,9.3,6.6c1.6,4.5,0,8.8-4.5,12c3.6,1.8,4.2,5,4,8.6c0,0.8,0,1.6,0,2.3
		c-0.1,2,0.2,3.9,1.8,5.8c-3,0-5.5,0-8.2,0c-0.7-1.8-0.9-3.6-0.9-5.5c0-1.1,0-2.3,0-3.4c-0.1-3.4-1.3-4.7-4.7-5c-0.5,0-1,0-1.6,0
		C222.9,463.2,220.8,463.2,218.3,463.2z M218.4,457.3c3.5,0,6.8,0.1,10,0c2.4-0.1,3.7-1.6,3.9-4c0.2-3.5-1.2-5.3-4.7-5.5
		c-2.5-0.2-5-0.1-7.5,0c-0.6,0-1.1,0.2-1.7,0.3C218.4,451.1,218.4,454.1,218.4,457.3z"/>
	<path class="st0" d="M409.3,390.9c2.3,0,4.3,0,6.5,0c0,11.7,0,23.4,0,35.2c-2.3,0-4.6,0-7.1,0c-4.6-8-9.3-16.1-14-24.2
		c-0.2,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.1,0.1-0.1,0.2c0,7.9,0,15.8,0,23.8c-2.3,0-4.3,0-6.3,0c-0.5-1.8-0.7-30.5-0.2-35.1
		c2.4,0,4.9,0,7.5,0c2.2,3.9,4.5,7.8,6.8,11.8c2.3,3.9,4.5,7.9,7.3,11.8c0-1.9,0.1-3.9,0.1-5.8c0-2,0-4,0-6c0-1.9,0-3.8,0-5.7
		C409.3,395,409.3,393,409.3,390.9z"/>
	<path class="st0" d="M367,401c-2.4,0-4.4,0-6.6,0c-0.5-1.9-1.3-3.7-3.3-4.6c-2.4-1-4.9-1-7.4-0.4c-0.6,0.1-1.1,0.5-1.6,0.8
		c-2.5,1.7-2.3,5.1,0.5,6.2c1.9,0.8,4,1.2,6,1.7c2.3,0.6,4.7,1.1,7,1.8c2.6,0.8,5,2.2,5.9,5c1.6,4.6,0.7,9.3-3.1,12.2
		c-1.4,1.1-3.1,1.9-4.8,2.4c-5.1,1.3-10.1,1.1-14.8-1.3c-3.8-2-5.7-5.5-5.3-9.6c0.4,0,0.8-0.1,1.2-0.1c1.7,0,3.4,0,5.1,0
		c1.9,4.9,3.1,5.8,8.4,5.7c1.4,0,2.9-0.2,4.3-0.6c1.9-0.6,2.8-2.1,2.8-3.9c0.1-1.9-1-2.9-2.6-3.4c-2.4-0.7-4.8-1.3-7.2-2
		c-1.8-0.5-3.7-1-5.5-1.5c-8.1-2.3-7.6-13.1-2.8-16.9c0.7-0.6,1.5-1.2,2.4-1.6c5.7-2.3,11.3-2.3,16.7,0.8
		C365.4,393.9,367,397,367,401z"/>
	<path class="st0" d="M238.1,426.1c-2.6,0-4.8,0-6.9,0c-0.6-1.9-0.8-28.9-0.3-35.1c2.2-0.5,4.5-0.2,7.1-0.2c0,4.4,0,8.7,0,12.9
		c2,0.6,10.3,0.8,14,0.3c0-4.3,0-8.6,0-13c2.5-0.5,4.8-0.3,7.2-0.2c0,11.7,0,23.3,0,35.1c-2.2,0-4.5,0-7,0c0-5.1,0-10.3,0-15.4
		c-2.1-0.6-11.2-0.6-14.1-0.1C238.1,415.5,238.1,420.7,238.1,426.1z"/>
	<path class="st0" d="M305.1,390.8c2.5,0,4.7,0,7.1,0c0.1,0.8,0.1,1.6,0.1,2.3c0,6.2,0,12.3,0,18.5c0,1.5,0.2,3.1,0.5,4.6
		c0.4,2.3,1.8,3.9,4.2,4.3c1.2,0.2,2.4,0.4,3.6,0.2c3.3-0.4,5.1-2.5,5.2-6c0.1-2.8,0-5.5,0-8.3c0-5.1,0-10.2,0-15.6
		c2.6-0.2,5-0.2,7.6,0c0,0.9,0.1,1.6,0.1,2.4c0,6.2,0.1,12.3,0,18.5c0,2.1-0.2,4.2-0.6,6.2c-0.9,4.1-3.3,7-7.4,8.3
		c-4.4,1.3-8.9,1.3-13.2-0.2c-3.5-1.2-5.7-3.8-6.6-7.4c-0.4-1.7-0.6-3.4-0.6-5.1c-0.1-6.9,0-13.9,0-20.8
		C305,392.1,305.1,391.6,305.1,390.8z"/>
	<path class="st0" d="M68.3,410.5c-5.5,0-10.9,0-16.6,0c0,3.2,0,6.1,0,9.3c6.4,0,12.7,0,19.1,0c0,2.2,0,4.1,0,6.2
		c-8.7,0-17.4,0-26.2,0c0-11.8,0-23.4,0-35.2c8.4,0,16.7,0,25.3,0c0,0.9,0.1,1.8,0.1,2.7c0,0.9,0,1.9,0,3.1c-6.2,0-12.2,0-18.4,0
		c0,2.7,0,5.1,0,7.6c5.6,0,11,0,16.6,0C68.3,406.4,68.3,408.3,68.3,410.5z"/>
	<path class="st0" d="M182.3,426c-2.8,0-5.3,0-7.9,0c-0.8-2.4-1.5-4.6-2.3-7c-4.4,0-8.7,0-13.2,0c-0.8,2.2-1.5,4.5-2.4,7
		c-2.3,0.3-4.8,0.1-7.6,0.1c4.3-11.9,8.4-23.6,12.6-35.3c2.8,0,5.5,0,8.5,0C174,402.5,178.1,414.2,182.3,426z M161,412.5
		c3.1,0,6,0,9,0c-1.6-4.5-2.7-9-4.6-13.7c-1.4,4.3-2.7,8.2-4,12C161.4,411.3,161.2,411.8,161,412.5z"/>
	<path class="st0" d="M136.6,390.8c2.6,0,4.8,0,7.2,0c0.1,0.7,0.2,1.2,0.2,1.8c0,7,0,14,0,21c0,1.5-0.2,3.1-0.6,4.6
		c-0.8,3.9-3.2,6.5-6.9,7.7c-4.5,1.5-9,1.5-13.5-0.1c-4.7-1.6-6.9-5.4-7.1-10.1c-0.2-5.4-0.2-10.9-0.2-16.4c0-2.8,0-5.5,0-8.5
		c2.5,0,4.8,0,7.4,0c0,0.9,0.1,1.7,0.1,2.6c0,6.3,0,12.6,0,19c0,0.6,0,1.2,0,1.8c0.2,5.6,5.4,8.5,10.4,5.8c1.4-0.7,2.3-1.9,2.6-3.4
		c0.3-1.4,0.5-2.9,0.5-4.4c0.1-6.2,0-12.5,0-18.7C136.6,392.8,136.6,391.9,136.6,390.8z"/>
	<path class="st0" d="M95.6,464.5c0,4.3,0,8.3,0,12.5c-2.5,0-4.8,0-7.2,0c0-11.7,0-23.3,0-35.2c0.7,0,1.2-0.1,1.8-0.1
		c4.6,0,9.2,0,13.8,0c1.4,0,2.8,0.2,4.1,0.6c3.8,1.2,6.2,3.7,6.7,7.8c0.2,1.8,0.2,3.6,0.1,5.4c-0.4,5.4-3.8,8-8.5,8.5
		c-2.6,0.3-5.2,0.2-7.8,0.2C97.7,464.4,96.8,464.4,95.6,464.5z M95.7,458.4c2.5,0,4.7,0,7,0c3.8,0,5.6-2.9,5.1-6.5
		c-0.4-2.5-1.5-3.6-4-4c-2.6-0.4-5.3-0.3-8.1,0C95.7,451.4,95.7,454.7,95.7,458.4z"/>
	<path class="st0" d="M127.8,441.8c5.8,0,11.4-0.2,17,0.1c4.3,0.2,8.3,2.4,9.3,8.8c0.4,2.6,0.2,5.2-0.7,7.7
		c-1.3,3.6-4.1,5.2-7.6,5.8c-1.4,0.2-2.9,0.2-4.4,0.2c-2.1,0-4.1,0-6.5,0c-0.4,4.3,0,8.4-0.2,12.7c-2.3,0-4.5,0-6.7,0
		C127.5,475.2,127.3,445.9,127.8,441.8z M134.9,447.9c0,3.6,0,7,0,10.6c1.1,0,2,0,2.9,0c1.6,0,3.3,0.1,4.9-0.1
		c2.8-0.3,4.2-2.2,4.3-5.3c0-3.2-1.1-4.8-4.2-5.2C140.3,447.6,137.7,447.9,134.9,447.9z"/>
	<path class="st0" d="M426.7,441.9c2.9,0,5.7,0,8.5,0c1.2,2.6,2.4,5,3.6,7.5c1.2,2.5,2.4,4.9,3.8,7.8c2.5-5.4,4.7-10.3,7-15.3
		c2.7,0,5.4,0,8.2,0c-2.6,4.8-5,9.5-7.5,14.1c-1.4,2.7-3.3,5.2-4.1,8.1c-0.7,2.9-0.2,6-0.2,9.1c0,1.3,0,2.6,0,3.9
		c-2.5,0-4.7,0-7.3,0c0-1.3,0-2.5,0-3.8c0-3.1,0.5-6.4-0.2-9.3c-0.7-2.9-2.8-5.5-4.3-8.2c-2.1-3.8-4.2-7.6-6.2-11.4
		C427.5,443.6,427.2,442.8,426.7,441.9z"/>
	<path class="st0" d="M278.6,442.1c0,2,0,3.8,0,5.9c-3.5,0-6.9,0-10.6,0c0,9.9,0,19.4,0,29.1c-2.5,0-4.7,0-7.2,0
		c-0.2-3.1-0.1-6.3-0.1-9.5c0-3.2,0-6.4,0-9.6c0-3.2,0-6.4,0-9.9c-3.6-0.2-7.1,0-10.7-0.2c0-2,0-3.9,0-5.8
		C251.9,441.7,275.5,441.6,278.6,442.1z"/>
	<path class="st0" d="M400.8,447.9c-3.8,0-7.1,0-10.6,0c0-2.1,0-4,0-6c9.5,0,18.9,0,28.4,0c0,1.9,0,3.8,0,6c-3.4,0-6.9,0-10.5,0
		c0,9.8,0,19.4,0,29.1c-2.5,0-4.8,0-7.3,0C400.8,467.5,400.8,457.9,400.8,447.9z"/>
	<path class="st0" d="M194.3,419.8c5.9,0,11.6,0,17.5,0c0,2.2,0,4.1,0,6.2c-8.2,0-16.3,0-24.6,0c0-11.7,0-23.4,0-35.2
		c2.4,0,4.6,0,7.1,0C194.3,400.4,194.3,410,194.3,419.8z"/>
	<path class="st0" d="M380.2,426.1c-2.4,0-4.5,0-6.7,0c-0.6-1.8-0.8-28.4-0.3-35.3c2.2-0.2,4.5-0.1,7,0
		C380.2,402.5,380.2,414.2,380.2,426.1z"/>
	<path class="st0" d="M379.4,477.1c-2.3,0-4.4,0-6.7,0c0-11.8,0-23.4,0-35.1c0.4-0.1,0.7-0.2,1.1-0.2c1.8,0,3.6,0,5.4,0
		C379.7,443.8,379.9,472.3,379.4,477.1z"/>
	<path class="st0" d="M331.2,245.3c0,13.7,0,27.3,0,40.9c-1.9,0.5-159.9,0.7-164.2,0.2c0-13.6,0-27.3,0-41
		C168.7,244.9,327.1,244.8,331.2,245.3z"/>
	<path class="st0" d="M331.1,178.9c0,13.7,0,27.3,0,40.9c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.2,0.2c-0.5,0.1-1,0.1-1.5,0.1
		c-53.8,0-107.6,0-161.4,0c-0.2,0-0.5-0.1-0.8-0.2c0-13.7,0-27.3,0-41.1C221.7,178.9,276.3,178.9,331.1,178.9z"/>
</g>
</svg>`

const FairHousingAct = `<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
<style type="text/css">
	.st0{fill:#010101;}
</style>
<g>
	<path d="M258.2,0c6.9,3.5,13.7,7.2,20.6,10.5C353.5,46.8,428.1,83,502.7,119.3c4.3,2.1,8.6,4,12.8,6.3c1.2,0.7,2.4,2.5,2.4,3.9
		c0.2,18,0.1,36,0.2,54.1c0,2.8-1.3,3.3-3.7,3.3c-9.9-0.1-19.9,0-30.4,0c0,59.4,0,118.6,0,178.1c-151.9,0-303.2,0-455.1,0
		c0-59.1,0-118.2,0-178c-9.9,0-19.1,0-28.8,0c0-2.1,0-3.5,0-4.9c0-17.2,0.1-34.3-0.1-51.5c0-2.9,1-4.3,3.6-5.5
		C63.7,95.6,123.8,66,183.9,36.4c23.4-11.5,46.8-23.1,70.1-34.6c0.9-0.4,1.7-1.1,2.5-1.6C257.1,0,257.6,0,258.2,0z M427.3,310.7
		c0.2-0.7,0.3-1.1,0.3-1.5c0-52.8,0.1-105.5-0.1-158.3c0-1.4-1.5-3.3-2.8-4C370.2,118.5,315.5,90.3,260.9,62c-2.7-1.4-4.8-1.4-7.6,0
		c-54.8,28.2-109.7,56.2-164.7,84.2c-2.9,1.5-4.2,3-4.2,6.5c0.1,46.6,0.1,93.1,0.1,139.7c0,6,0,12,0,18.4
		C199.1,310.7,313.2,310.7,427.3,310.7z"/>
	<path d="M336,148.1c0,19.3,0,38.1,0,57.4c-53,0-106,0-159.3,0c0-19,0-38,0-57.4C229.7,148.1,282.6,148.1,336,148.1z"/>
	<path d="M335.8,288.2c-53.2,0-106.1,0-159.6,0c0-1.8,0-3.4,0-4.9c0-16.1,0.1-32.3,0-48.4c0-3.1,0.5-4.6,4.2-4.6
		c50.7,0.1,101.5,0.1,152.2,0.1c1,0,2,0.2,3.2,0.3C335.8,249.8,335.8,268.9,335.8,288.2z"/>
	<g>
		<polygon class="st0" points="57.8,434.6 39.8,434.6 39.8,426.3 55.6,426.3 55.6,419.1 39.8,419.1 39.8,412.4 57,412.4 57,405.1
			32.3,405.1 32.3,442 57.8,442 		"/>
		<path class="st0" d="M88.8,442.2c0.8-0.3,1.8-0.8,2.9-1.5l4,4.2l4.1-4.8l-3.8-3.9c1-1.3,1.8-3,2.3-5c0.7-2.2,1.1-5,1.1-8.2
			c0-6.2-1.7-11-5.1-14.5c-2.9-3-6.6-4.5-10.9-4.5s-7.9,1.4-10.8,4.2c-3.5,3.4-5.3,8.6-5.3,15.3c0,6.6,1.7,11.7,5.2,15.2
			c2.8,2.9,6.5,4.3,10.8,4.3C85.5,443,87.3,442.8,88.8,442.2z M77.3,414.3c1.5-2,3.5-3,6.1-3c2.8,0,4.8,1,6.2,3.1
			c1.4,2.1,2.2,5.2,2.2,9.1c0,1.7-0.2,3.2-0.5,4.5c-0.2,0.7-0.4,1.5-0.8,2.4l-3.2-3.3l-4.1,4.7l3.1,3.3c-0.3,0.1-0.6,0.2-0.8,0.3
			l0,0c-0.7,0.2-1.4,0.3-2.1,0.3c-2,0-3.7-0.7-5.1-2c-2.2-2-3.3-5.5-3.3-10.2C75,419.4,75.8,416.3,77.3,414.3z"/>
		<path class="st0" d="M135.4,436.9c1.1-2.2,1.7-5.3,1.7-9.2v-22.6h-7.8v22.6c0,2.4-0.3,4.1-0.8,5.2c-0.8,1.8-2.4,2.7-5,2.7
			s-4.2-0.9-5-2.8c-0.5-1.1-0.7-2.8-0.7-5.2V405H110v22.6c0,3.9,0.6,7,1.7,9.2c2.1,4,6.1,6.1,11.8,6.1
			C129.4,443,133.4,440.9,135.4,436.9z"/>
		<path class="st0" d="M170.3,442h8.3l-11.8-36.9h-8.3L146.6,442h8l2.2-7.4h11.4L170.3,442z M166.1,427.5H159l3.6-12.1L166.1,427.5z
			"/>
		<polygon class="st0" points="195.7,405.1 188.1,405.1 188.1,442 212,442 212,434.6 195.7,434.6 		"/>
		<polygon class="st0" points="259.5,442 267.1,442 267.1,405.1 259.5,405.1 259.5,418.8 247.7,418.8 247.7,405.1 240.1,405.1
			240.1,442 247.7,442 247.7,426 259.5,426 		"/>
		<path class="st0" d="M309.9,423.5c0-6.7-1.7-11.8-5-15.2c-2.5-2.9-6.2-4.3-11-4.3s-8.4,1.5-11,4.3c-3.4,3.4-5.1,8.5-5.1,15.2
			c0,6.6,1.7,11.7,5.1,15.2c2.5,2.9,6.2,4.3,11,4.3c4.7,0,8.4-1.5,11-4.3C308.2,435.2,309.9,430.1,309.9,423.5z M287.8,414.4
			c1.5-2.1,3.5-3.1,6.1-3.1s4.6,1,6.1,3.1s2.3,5.2,2.3,9.1s-0.8,7-2.3,9.1s-3.5,3.1-6.1,3.1s-4.6-1-6.1-3.1s-2.3-5.2-2.3-9.1
			S286.3,416.5,287.8,414.4z"/>
		<path class="st0" d="M347.6,427.7v-22.6h-7.8v22.6c0,2.4-0.3,4.1-0.8,5.2c-0.8,1.8-2.4,2.7-5,2.7s-4.2-0.9-5-2.8
			c-0.5-1.1-0.7-2.8-0.7-5.2V405h-7.8v22.6c0,3.9,0.6,7,1.7,9.2c2.1,4,6.1,6.1,11.8,6.1c5.8,0,9.7-2.1,11.8-6.1
			C347,434.7,347.6,431.6,347.6,427.7z"/>
		<path class="st0" d="M382.8,423.3c-1.3-1.1-3.2-2-5.8-2.7l-5.7-1.5c-2.2-0.6-3.6-1-4.2-1.4c-0.9-0.5-1.4-1.4-1.4-2.5
			c0-1.2,0.5-2.2,1.4-2.9c1-0.7,2.4-1.1,4.1-1.1c1.5,0,2.8,0.3,3.9,0.9c1.5,0.8,2.3,2.2,2.4,4.2v0.4h7.4v-0.5c-0.1-4-1.5-7-4-9.1
			s-5.6-3.1-9.1-3.1c-4.2,0-7.5,1.1-9.7,3.3c-2.2,2.2-3.4,5-3.4,8.4c0,3.7,1.2,6.4,3.5,8.2c1.4,1,3.7,2,7.3,2.8l3.5,0.9
			c2,0.5,3.5,1,4.4,1.6c0.8,0.6,1.2,1.3,1.2,2.4c0,1.8-0.9,3-2.6,3.7c-1,0.4-2.3,0.6-3.8,0.6c-2.6,0-4.4-0.7-5.5-2
			c-0.6-0.7-0.9-1.9-1.1-3.4l-0.1-0.4H358v0.5c0,3.9,1.3,7,3.8,9.2s6,3.3,10.4,3.3c4.3,0,7.7-1.1,10-3.4c2.4-2.3,3.6-5.1,3.6-8.6
			C385.8,427.7,384.8,425.1,382.8,423.3z"/>
		<rect x="395.5" y="405.1" class="st0" width="7.6" height="36.9"/>
		<polygon class="st0" points="434.9,428.9 422.7,405.1 414.8,405.1 414.8,442 422.1,442 422.1,417.8 434.6,442 442.1,442
			442.1,405.1 434.9,405.1 		"/>
		<path class="st0" d="M469.1,421.8v7h7.4c-0.4,2-1.2,3.6-2.5,4.9c-1.4,1.4-3.2,2-5.3,2c-2.2,0-4.2-0.9-5.9-2.6
			c-1.7-1.7-2.6-4.8-2.6-9s0.8-7.5,2.4-9.5c1.6-2.1,3.6-3.1,6.1-3.1c1.3,0,2.5,0.3,3.6,0.8c1.9,0.9,3,2.5,3.5,4.8l0.1,0.4h7.5
			l-0.1-0.5c-0.4-3.6-1.9-6.6-4.5-9s-6-3.6-10.2-3.6c-4.9,0-8.9,1.8-11.8,5.4c-3,3.6-4.5,8.4-4.5,14.3s1.5,10.5,4.4,13.9
			c2.8,3.4,6.5,5.1,10.9,5.1c2.7,0,5-0.6,6.8-1.9c0.9-0.6,1.9-1.6,3-2.9l0.6,3.9h5.3v-20.3L469.1,421.8L469.1,421.8z"/>
		<path class="st0" d="M46.9,461c-4.8,0-8.4,1.5-11,4.3c-3.4,3.4-5.1,8.5-5.1,15.2c0,6.6,1.7,11.7,5.1,15.2c2.5,2.9,6.2,4.3,11,4.3
			c4.7,0,8.4-1.5,11-4.3c3.4-3.5,5.1-8.6,5.1-15.2c0-6.7-1.7-11.8-5-15.2C55.4,462.4,51.7,461,46.9,461z M40.8,471.4
			c1.5-2.1,3.5-3.1,6.1-3.1s4.6,1,6.1,3.1s2.3,5.2,2.3,9.1s-0.8,7-2.3,9.1s-3.5,3.1-6.1,3.1s-4.6-1-6.1-3.1s-2.3-5.2-2.3-9.1
			S39.3,473.5,40.8,471.4z"/>
		<path class="st0" d="M102.5,465c-2-1.9-4.7-2.9-8-2.9H80.1V499h7.6v-12.9H94c3.6,0,6.5-0.9,8.4-2.7c2-1.8,3-5,3-9.4
			C105.5,469.9,104.5,466.9,102.5,465z M87.7,469.3h5.7c1.4,0,2.6,0.4,3.3,1.1c0.8,0.7,1.1,1.9,1.1,3.6s-0.4,3-1.1,3.7
			c-0.8,0.8-1.9,1.2-3.3,1.2h-5.7V469.3z"/>
		<path class="st0" d="M145.3,465c-2-1.9-4.7-2.9-8-2.9h-14.4V499h7.6v-12.9h6.3c3.6,0,6.5-0.9,8.4-2.7c2-1.8,3-5,3-9.4
			C148.3,469.9,147.3,466.9,145.3,465z M130.5,469.3h5.7c1.4,0,2.6,0.4,3.3,1.1c0.8,0.7,1.1,1.9,1.1,3.6s-0.4,3-1.1,3.7
			c-0.8,0.8-1.9,1.2-3.3,1.2h-5.7V469.3z"/>
		<path class="st0" d="M180.4,461c-4.8,0-8.4,1.5-11,4.3c-3.4,3.4-5.1,8.5-5.1,15.2c0,6.6,1.7,11.7,5.1,15.2c2.5,2.9,6.2,4.3,11,4.3
			c4.7,0,8.4-1.5,11-4.3c3.4-3.5,5.1-8.6,5.1-15.2c0-6.7-1.7-11.8-5-15.2C188.9,462.4,185.2,461,180.4,461z M174.3,471.4
			c1.5-2.1,3.5-3.1,6.1-3.1s4.6,1,6.1,3.1s2.3,5.2,2.3,9.1s-0.8,7-2.3,9.1s-3.5,3.1-6.1,3.1s-4.6-1-6.1-3.1s-2.3-5.2-2.3-9.1
			S172.8,473.5,174.3,471.4z"/>
		<path class="st0" d="M239.7,495.5c-0.1-0.5-0.2-1.6-0.2-3.2v-2.4c0-2.6-0.3-4.5-1-5.9c-0.5-1.1-1.4-1.9-2.5-2.6
			c1.4-0.7,2.5-1.9,3.2-3.3c0.8-1.7,1.2-3.5,1.2-5.3c0-1.5-0.2-2.8-0.6-4c-0.4-1.1-1-2.2-1.7-3.2c-0.9-1.2-2-2-3.2-2.6l0,0
			c-1.2-0.6-3-0.9-5.3-1h-16v37h7.5v-14.1h6.2c1.8,0,3,0.3,3.6,1s0.9,2.1,0.9,4.3v3.3c0,1.1,0.1,2.2,0.3,3.2c0.1,0.5,0.2,1.2,0.4,2
			l0.1,0.3h8.3v-1.7l-0.2-0.1C240.2,496.8,239.8,496.3,239.7,495.5z M221.1,469.3h7.1c1.3,0,2.3,0.2,2.9,0.6c1.1,0.7,1.6,2,1.6,4
			c0,1.8-0.6,3-1.7,3.6c-0.7,0.4-1.7,0.5-3.1,0.5H221L221.1,469.3L221.1,469.3z"/>
		<polygon class="st0" points="256,469.4 265.8,469.4 265.8,499 273.5,499 273.5,469.4 283.2,469.4 283.2,462.1 256,462.1 		"/>
		<path class="st0" d="M318.2,484.6c0,2.4-0.3,4.1-0.8,5.2c-0.8,1.8-2.4,2.7-5,2.7s-4.2-0.9-5-2.8c-0.5-1.1-0.7-2.8-0.7-5.2v-22.6
			H299v22.6c0,3.9,0.6,7,1.7,9.2c2.1,4,6.1,6.1,11.8,6.1c5.8,0,9.7-2.1,11.8-6.1c1.1-2.2,1.7-5.3,1.7-9.2v-22.6h-7.8V484.6z"/>
		<polygon class="st0" points="364.3,485.8 352,462.1 344.2,462.1 344.2,499 351.4,499 351.4,474.8 364,499 371.5,499 371.5,462.1
			364.3,462.1 		"/>
		<rect x="389.1" y="462.1" class="st0" width="7.6" height="36.9"/>
		<polygon class="st0" points="412.1,469.4 421.9,469.4 421.9,499 429.6,499 429.6,469.4 439.4,469.4 439.4,462.1 412.1,462.1 		"/>
		<polygon class="st0" points="467.7,477.1 461.2,462.1 452.2,462.1 463.7,485.2 463.7,499 471.4,499 471.4,485.2 482.6,462.1
			473.9,462.1 		"/>
	</g>
</g>
</svg>`

const GreenBuiltAlliance = `<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
<style type="text/css">
	.st0{fill:#FFFFFF;}
</style>
<g>
	<path class="st0" d="M70.4,352.6c0-6.7,0.1-13.3,0-20c0-2.3,0.8-3.3,3.1-3.2c6.4,0.3,12.7,0.3,19.1,0.6c5.6,0.3,12.4,5.3,13.4,10.4
		c1.4,7.4,0.8,13-5.9,17.3c-1.8,1.1-2,2.1-1.1,3.9c1.1,2.2,2.6,3.8,5,4.2c2,0.3,2.6,1.4,2.3,3.2c-0.3,2.2-0.1,5.9-1.2,6.4
		c-3.3,1.4-7.4,1.3-10.2-1.4c-2.3-2.2-3.8-5.1-5.5-7.8c-0.8-1.3-1.2-2.9-2.2-4c-0.7-0.8-2-1.1-3.1-1.2c-0.3,0-1.1,1.4-1.1,2.2
		c-0.1,3.2-0.1,6.5-0.1,9.7c0,1.7-0.6,2.4-2.3,2.4c-2.5-0.1-5-0.1-7.4,0c-2.2,0.1-3-0.8-2.8-2.8c0-0.1,0-0.2,0-0.3
		C70.4,365.7,70.4,359.2,70.4,352.6z M82.9,345.6c0,4.9,0.2,5.3,2.3,5.3c5.9,0.1,8.5-1.5,8.4-5.2c-0.1-3.8-2.1-5.2-7.8-5.2
		C82.9,340.5,82.9,340.5,82.9,345.6z"/>
	<path class="st0" d="M229.3,354.6c-0.1,1.8-0.3,2.7-0.3,3.6c0,4.7-0.1,9.3,0,14c0.1,2.4-0.6,3.5-3.2,3.3c-2.1-0.2-4.2-0.2-6.3,0
		c-2.4,0.2-3.1-0.7-3.1-3.1c0.1-12.4,0-24.7,0-37.1c0-1.8,0-3.5,0-5.3c0.3-0.2,0.5-0.3,0.8-0.5c8.5,6.8,17,13.7,26,20.9
		c0.1-1.7,0.2-2.7,0.3-3.7c0-4.7,0.1-9.3,0-14c-0.1-2.3,0.7-3.3,3.1-3.2c2.3,0.1,4.6,0.1,6.8,0c2.2-0.1,3,0.8,2.9,3
		c-0.1,11.3-0.1,22.6-0.1,34c0,2.9,0,5.8,0,9.6C247,368.6,238.4,361.8,229.3,354.6z"/>
	<path class="st0" d="M51.9,342.6c-3.3-0.7-6.1-1.4-8.9-1.8c-2.2-0.3-4.6-0.4-6.8-0.2c-4.7,0.4-8.5,2.7-9.9,7.4
		c-1.5,5.1-1.2,10,3.1,13.9c2.9,2.6,6.5,3,10.2,2.7c0.6-0.1,1.5-1.4,1.6-2.2c0.2-2,0.2-4,0.1-6c-0.1-1.8,0.6-2.6,2.5-2.5
		c2.6,0.1,5.1,0.1,7.7,0c1.6,0,2.2,0.7,2.2,2.2c0,4.9,0,9.9-0.1,14.8c0,0.8-0.5,1.9-1.2,2.2c-7.8,3.9-23.4,5-32-2.8
		c-7.6-6.9-9.8-17.4-6.2-26.8c3.2-8.5,12-15,21.8-14.6c4.6,0.2,9.1,1.2,13.7,1.9c1.8,0.3,2.3,1.7,2.3,3.4
		C51.8,336.9,51.9,339.5,51.9,342.6z"/>
	<path class="st0" d="M169.2,352.5c0-6.7,0.1-13.3,0-20c0-2.2,0.7-3.1,2.9-3c8.2,0.1,16.4,0.1,24.5,0c2.3,0,3.2,0.8,3.2,3.2
		c0,7.9,0.1,7.9-7.7,7.9c-2.2,0-4.4,0-6.6-0.2c-2.2-0.1-3.6,0.4-3.5,3.1c0.1,2.4,0.4,3.8,3.3,3.7c2.8-0.2,5.7,0.1,8.6-0.1
		c2.3-0.1,3.3,0.6,3.2,3c-0.2,8.5,0.8,6.9-6.9,7c-1.7,0-3.4,0.2-5.1,0c-3.2-0.3-2.9,1.8-3,3.8c0,2.2,0.2,4,3.2,3.8
		c3.9-0.2,7.8-0.1,11.7-0.4c2.6-0.2,3.6,0.8,3.5,3.4c-0.2,9.3,1.1,7.5-7.4,7.7c-7,0.1-14.1-0.1-21.1,0.1c-2.3,0-2.9-0.8-2.9-3
		C169.3,365.8,169.2,359.1,169.2,352.5z"/>
	<path class="st0" d="M122.3,352.4c0-6.7,0.1-13.3,0-20c0-2.2,0.7-3,2.9-3c7.6,0.1,15.2,0,22.8,0.1c4.4,0,4.6,0.3,4.5,4.5
		c-0.2,6.6,1.6,6.3-6.4,6.5c-3,0.1-6.1,0-9.1,0c-2.9-0.1-2,2.1-2.2,3.7c-0.1,1.6-0.1,3.1,2.3,3c3.3-0.1,6.7,0,10-0.1
		c2-0.1,2.9,0.7,3,2.8c0.4,7.3,0.5,7.3-6.8,7.3c-2.1,0-4.2,0-6.3,0c-2.3,0-2.4,1.6-2.2,3.1c0.1,1.6-1,4,2,4.1
		c4.5,0.1,8.9,0.2,13.4,0.1c2.4,0,3.3,0.7,3.3,3.3c0,7.7,0.1,7.7-7.6,7.7c-6.8,0-13.7-0.1-20.5,0c-2.3,0-3.2-0.6-3.1-3.1
		C122.4,365.7,122.3,359.1,122.3,352.4z"/>
	<g>
		<path class="st0" d="M323.7,79.4c6.3,9.8,12.4,19.2,18.4,28.5c10.8,16.9,21.8,33.8,32.5,50.7c9.6,15.2,12.1,31.3,5.1,48.2
			c-5.2,12.7-14.5,21.7-26.6,27.9c-7.6,3.8-15.6,6-24.1,6.4c-1.7,0.1-2.1,0.8-2.1,2.3c0.1,4.3,0.1,8.6,0,12.9
			c-0.1,1.9,0.8,2.4,2.4,2.7c15.9,3,31.8,5.6,47.6,9.3c11.2,2.6,22,6.6,33,10.1c1,0.3,2.1,1,2.8,1.8c1.3,1.7,0.2,4.3-2,4.5
			c-1.4,0.1-2.8-0.2-4.2-0.7c-25.3-9.1-51.5-14.9-78.1-18.7c-0.4-0.1-0.7,0-1.1,0c0.2,0.2,0.3,0.5,0.5,0.7c-1.5,1.2-2.9,3.3-4.4,3.4
			c-2.7,0.1-3-2.7-3.2-5.2c-22.2-2.7-44.4-4.3-67.2-4.2c0,1.9,0,3.6,0,5.3c0,2.2-0.7,4.3-3.2,4.3c-2.4,0-3.2-1.9-3.2-4.2
			c0-1.7,0-3.4,0-5.2c-7.2,0-14.2-0.3-21.2,0.1c-14.5,0.9-29,2.1-43.5,3.3c-0.9,0.1-2.4,0.9-2.4,1.5c-0.3,2.3-0.8,4.2-3.4,4.5
			c-1.7,0.2-2.7-1.3-3.5-4.9c-4.3,0.6-8.7,1.2-13.1,1.9c-23.1,3.6-45.7,9-67.8,16.6c-0.6,0.2-1.3,0.5-1.9,0.6c-2,0.5-3.7,0.1-4.4-2
			c-0.6-2,0.6-3.2,2.3-4.1c0.8-0.4,1.6-0.6,2.4-0.9c25.8-8.9,52.3-14.7,79.4-18.2c2.4-0.3,3.2-1.2,3.1-3.6c-0.2-4.4,0-8.9,0-12.9
			c-6.1-1.4-12-2.1-17.5-4c-20.5-7.1-34.1-20.8-38.2-42.6c-2-10.7-0.5-21.3,5-30.7c8.5-14.4,17.7-28.4,26.7-42.5
			c8.5-13.4,17.2-26.7,25.8-40.1c0.5-0.8,1.1-1.6,1.8-2.6c12.3,19.1,24.4,37.9,36.9,57.3c12.4-19.2,24.5-38.1,36.9-57.3
			c12.4,19.2,24.6,38.1,37,57.4C299.1,117.6,311.3,98.7,323.7,79.4z M327,234.3c0.6,0.2,0.7,0.3,0.9,0.3c0.5,0,0.9,0,1.4,0
			c9.6-0.7,18.4-3.7,26.4-9c20-13.2,27.6-38,15.4-59.5c-4.5-7.8-9.6-15.3-14.4-23c-1.2-2-2.5-3.9-4.1-6.3
			c-8.1,8.2-16,16.1-23.7,24.1c-1,1.1-1.8,2.9-1.8,4.4c-0.2,11.4-0.1,22.8-0.1,34.3c0,0.8,0.1,1.6,0.2,2.9c1-0.9,1.7-1.5,2.4-2.2
			c8.2-8.2,16.4-16.4,24.6-24.6c2.3-2.3,4.4-2.7,5.9-1.2c1.6,1.5,1.2,3.5-1.1,5.9c-0.4,0.4-0.8,0.8-1.2,1.2
			c-9.6,9.6-19.3,19.2-28.9,28.8c-0.8,0.8-1.8,2-1.9,3C326.9,220.4,327,227.3,327,234.3z M171.5,203.2c0.4-0.3,0.7-0.7,1.1-1
			c0.1-0.3,0.2-0.5,0.2-0.8c0-12.1,0-24.2-0.1-36.3c0-1.1-0.8-2.5-1.7-3.4c-7.4-7.5-14.9-15-22.4-22.4c-0.6-0.6-1.2-1.1-2.2-1.9
			c-6.6,10.7-13.7,20.8-19.2,31.6c-6.7,13-6.1,26.5,1,39.4c7.4,13.4,19.1,21.1,33.7,24.7c3.4,0.8,6.8,1.1,10.7,1.7
			c0-1.7,0-2.7,0-3.7c0-5.3-0.1-10.7,0.1-16c0.1-2.1-0.5-3.6-1.9-5c-10.7-10.6-21.3-21.2-32-31.9c-2.8-2.8-3.2-4.8-1.6-6.4
			c1.6-1.6,3.5-1.2,6.4,1.6c8.8,8.8,17.6,17.6,26.4,26.5C170.7,200.9,171,202.2,171.5,203.2z M286.7,179.2
			c-10.8,10.8-21.5,21.3-32,32c-0.8,0.8-1.2,2.2-1.3,3.3c-0.1,5.6,0.1,11.2-0.1,16.8c-0.1,2.7,1,3.6,3.3,3
			c5.8-1.5,11.8-2.4,17.2-4.7c14.5-6.3,24.2-17.1,28-32.7c0.2-0.9,0.3-2.3-0.2-2.8C296.8,189,291.8,184.2,286.7,179.2z M246.1,223.4
			C246.2,223.4,246.2,223.4,246.1,223.4c0-2.8,0.2-5.5,0-8.3c-0.1-1.5-0.6-3.3-1.6-4.3c-9.9-10.1-20-20.1-30-30.1
			c-1.2-1.2-2-1.3-3.2,0c-3.4,3.6-6.7,7.3-10.5,10.4c-3.5,2.8-3.4,5.7-2.1,9.5c4,11.9,11.8,20.7,22.6,26.9
			c6.8,3.9,14.3,6.1,22.1,6.9c2.2,0.2,2.9-0.6,2.8-2.7C246.1,228.9,246.1,226.1,246.1,223.4z M253.3,202.6c1.1-1,1.7-1.6,2.4-2.2
			c9-9,18-18,27-27c3.1-3.1,5-3.1,8.2,0c3.4,3.4,6.8,6.9,10.3,10.3c0.5,0.5,1.1,0.8,1.6,1.3c-0.2-4-0.5-8-2.1-11.3
			c-3.7-7.5-8-14.7-12.2-21.8c-2.9-5-6.2-9.7-9.6-14.9c-8.4,8.5-16.6,16.7-24.7,25c-0.7,0.7-1,2.2-1,3.4
			c-0.1,11.4-0.1,22.8-0.1,34.3C253.1,200.4,253.2,201.2,253.3,202.6z M196.4,184.6c0.3,0.1,0.6,0.3,1,0.4
			c3.6-3.6,7.2-7.2,10.8-10.9c4.1-4.2,5.5-4.2,9.6,0c8.7,8.8,17.5,17.5,26.2,26.3c0.7,0.7,1.4,1.2,2.6,2.2c0-9.6-0.6-18.5,0.2-27.3
			c0.7-8.5-1.7-14.8-8.3-20.3c-6.3-5.3-11.6-11.7-17.5-17.7c-0.8,0.9-1.2,1.3-1.5,1.8c-5,7.8-10.1,15.4-14.9,23.3
			c-2.3,3.7-4.1,7.7-5.7,11.8C197.5,177.6,197.2,181.1,196.4,184.6z M320.2,257.2c0-4.9,0.1-9.6-0.1-14.2c0-0.6-1.3-1.5-2.1-1.6
			c-10.6-0.7-20.4-3.9-29.4-9.6c-0.9-0.5-2.6-0.6-3.5,0c-7.5,4.8-15.6,7.7-24.4,9.2c-2.4,0.4-5.9,0.3-6.9,1.8
			c-1.2,1.9-0.3,5.1-0.4,7.8c-0.1,2,0.6,2.6,2.6,2.6c7.6,0.1,15.2,0.2,22.8,0.7c8.8,0.5,17.6,1.3,26.4,2.1
			C310.1,256.2,315,256.7,320.2,257.2z M234.6,252.5c0,0.2,0,0.4,0,0.5c3,0,6.1,0,9.1,0c1.7,0,2.4-0.7,2.4-2.4c0-2.3-0.1-4.6,0-6.8
			c0.1-1.9-0.8-2.4-2.6-2.5c-10.1-0.6-19.4-3.6-27.9-9c-2.2-1.4-3.7-1.2-5.9,0.1c-8.4,5.3-17.7,8.3-27.6,8.8
			c-2.1,0.1-2.6,0.9-2.5,2.8c0.1,3.1,0.2,6.3,0,9.4c-0.2,2.8,0.9,3.3,3.5,3.1C200.3,255.1,217.4,253.9,234.6,252.5z M319.6,202.5
			c0.2-0.2,0.5-0.3,0.7-0.5c0-12.4,0-24.9-0.1-37.3c0-0.9-0.8-2.1-1.6-2.8c-6.5-6.6-13.1-13-19.5-19.6c-1.5-1.5-2.8-3.3-4.2-5
			c-4.5,5-4.5,5.1-1.6,9.7c2.1,3.3,4.2,6.6,6.3,9.8c6.8,10.4,10.9,21.7,10,34.4c0,0.6,0.2,1.4,0.6,1.8
			C313.3,196.1,316.5,199.3,319.6,202.5z M205,137c-8.3,8.4-16.5,16.6-24.6,24.9c-0.6,0.6-0.8,1.9-0.8,2.9
			c-0.1,11.9-0.1,23.8-0.1,35.7c0,0.6,0.2,1.1,0.4,2.3c3-3.1,5.6-5.9,8.4-8.5c1.4-1.3,1.9-2.6,1.8-4.5c-0.4-6.3,0.6-12.5,2.7-18.5
			c2.9-8.7,8.4-16,13.2-23.6C209,142.5,209.1,142.6,205,137z M172.6,98.1c-0.6,0.4-0.8,0.5-0.9,0.6c-6.8,10.5-13.7,21-20.4,31.6
			c-0.4,0.6-0.1,2,0.4,2.6c6.4,6.6,13,13.1,19.5,19.6c0.3,0.3,0.7,0.5,1.3,0.9C172.6,134.9,172.6,116.7,172.6,98.1z M224.7,130.9
			c7.4,7.5,14.5,14.7,21.5,21.8c0-18,0-36.1,0-54.3c-0.2,0-0.3-0.1-0.5-0.1C238.6,109.3,231.5,120.3,224.7,130.9z M320.1,153.5
			c0-18.7,0-36.9,0-55c-0.3,0-0.4-0.1-0.4,0c-7,10.7-13.9,21.3-20.8,32c-0.3,0.5-0.1,1.7,0.3,2.2C306,139.5,312.9,146.3,320.1,153.5
			z M327.3,152.5c0.5-0.3,0.8-0.4,1-0.6c6.5-6.5,13.1-13,19.5-19.6c0.5-0.5,0.5-2.1,0.1-2.8c-4-6.5-8.1-12.9-12.2-19.3
			c-2.6-4-5.3-8-7.9-12c-0.2,0.1-0.3,0.1-0.5,0.2C327.3,116.4,327.3,134.3,327.3,152.5z M254,98.3c-0.2,0.1-0.3,0.1-0.5,0.2
			c0,18,0,35.9,0,53.7c7.2-7.1,14.3-14.2,21.6-21.4C268,120.1,261,109.2,254,98.3z M179.7,152.4c0.5-0.3,0.8-0.4,1-0.6
			c6.5-6.4,12.9-12.9,19.4-19.3c1.3-1.3,0.8-2.2,0-3.4c-4.6-7.1-9.1-14.2-13.7-21.3c-2-3.1-4.1-6.2-6.2-9.4
			c-0.2,0.1-0.3,0.1-0.5,0.2C179.7,116.4,179.7,134.3,179.7,152.4z M308.2,201.1c-2.8,9.6-8,17.8-15.5,24.9
			c8.7,5.1,17.5,8.2,27.5,8.3c0-7.2,0-13.9,0-20.6c0-0.6-0.3-1.3-0.7-1.8C315.8,208.3,312.1,204.8,308.2,201.1z M207,226
			c-7.6-7.1-12.8-15.3-15.7-25.2c-3.7,3.7-7.3,7.1-10.7,10.7c-0.6,0.6-0.8,1.9-0.9,2.8c-0.1,4.7-0.1,9.5-0.1,14.2c0,1.9,0,3.7,0,5.9
			C189.7,234,198.4,231.2,207,226z"/>
		<path class="st0" d="M274.2,375.4c0-15.5,0-30.6,0-46.1c8.8,0.4,17.5-1.3,26,1.8c4.5,1.6,7.6,4.6,8.3,9.6
			c0.7,4.8-1.3,8.1-5.4,10.5c5.5,2.9,8.1,7.1,7,13.2c-1,5.6-4.8,8.6-9.9,9.8c-3.7,0.8-7.5,1.1-11.3,1.2
			C284.1,375.6,279.3,375.4,274.2,375.4z M286.5,364.6c5.8,0.8,8.8,0.3,9.5-1.9c0.5-1.4,0.5-3.7-0.3-4.7c-2.4-2.9-5.8-1.8-9.2-1.8
			c0,1.3,0,2.2,0,3.1C286.5,361.1,286.5,362.8,286.5,364.6z M286.5,340c0,1.3,0,2.5,0,3.8c0,4.5,0.4,4.9,4.9,4.2
			c0.4-0.1,0.7-0.2,1.1-0.3c2.1-0.5,2.9-1.9,2.9-4c-0.1-2.1-1.2-3.5-3.2-3.7C290.4,339.8,288.6,340,286.5,340z"/>
		<path class="st0" d="M325.6,329.4c4.5,0,8.5,0,12.9,0c0.1,1.2,0.2,2.3,0.2,3.4c0,7.6-0.1,15.2,0,22.8c0.1,6,2.4,8.6,7.4,8.7
			c4.8,0,7.4-2.5,7.4-7.4c0-8.1,0-16.2,0-24.3c0-1,0-2,0-3.2c4.5,0,8.6,0,13.1,0c0.1,0.9,0.2,1.8,0.2,2.7c0,8.7,0,17.3,0,26
			c-0.1,9.9-6.5,16.8-16.3,17.9c-6.8,0.8-13.3,0.2-18.9-4.3c-3.8-3-5.8-7.2-5.9-11.9c-0.3-9.3-0.1-18.6-0.1-28
			C325.6,331.2,325.6,330.5,325.6,329.4z"/>
		<path class="st0" d="M462.7,340.6c-4.2,0-7.9,0-11.8,0c0-3.8,0-7.3,0-11c12.2,0,24.3,0,36.6,0c0,3.6,0,7.1,0,10.9
			c-3.8,0-7.5,0-11.6,0c0,11.7,0,23.1,0,34.7c-4.5,0-8.7,0-13.2,0C462.7,363.7,462.7,352.4,462.7,340.6z"/>
		<path class="st0" d="M428.7,364.3c4.8,0,9,0.1,13.3,0c2,0,2.7,0.6,2.6,2.6c-0.1,2.7,0,5.5,0,8.4c-9.7,0-19.1,0-28.7,0
			c0-15.3,0-30.4,0-45.8c4.3,0,8.4,0,12.8,0C428.7,341.1,428.7,352.5,428.7,364.3z"/>
		<path class="st0" d="M384.6,329.5c4.3,0,8.5,0,12.9,0c0,15.3,0,30.4,0,45.8c-4.3,0-8.5,0-12.9,0
			C384.6,360,384.6,344.9,384.6,329.5z"/>
		<path class="st0" d="M319.9,419.6c-3.1,1.1-5,0.2-6.6-2.2c-2.8-4.1-5.9-8-9.6-11.8c0,4.7,0,9.4,0,14.2c-1.8,0-3.3,0-4.9,0
			c0-7.9,0-15.6,0-23.6c2.6-0.7,4.5-0.5,6.2,2.1c2.8,4.3,6,8.3,9.6,12.3c0-4.7,0-9.5,0-14.1c4.6-1.5,5.2-1.1,5.2,3.2
			C319.9,406.4,319.9,413,319.9,419.6z"/>
		<path class="st0" d="M273.4,419.7c-3.4,0.9-5.9,0.5-6.5-3.1c0-0.2-0.1-0.5-0.2-0.5c-2.7-0.4-5.4-0.8-8.1-0.9
			c-0.7,0-1.9,0.8-2.3,1.5c-1.9,3.8-1.8,3.9-6.5,3c1-2.6,2-5.1,3.1-7.7c1.9-4.8,3.7-9.6,5.8-14.3c0.4-0.8,1.8-1.6,2.7-1.6
			c1-0.1,2.6,0.5,2.9,1.3C267.4,404.7,270.3,412.1,273.4,419.7z M264.7,410.9c-1-3-1.9-5.5-3.1-8.9c-1.2,3.5-2.1,6.1-3.1,8.9
			C260.6,410.9,262.4,410.9,264.7,410.9z"/>
		<path class="st0" d="M90.5,419.5c3-7.4,5.9-14.8,9-22.1c0.3-0.7,1.7-1.3,2.6-1.3c1,0,2.6,0.6,2.9,1.3c3.1,7.3,6,14.7,9,22.2
			c-4.4,1.1-4.6,0.9-6.4-2.7c-0.4-0.8-1.7-1.5-2.7-1.7c-1.1-0.3-2.3,0-3.4-0.1c-2.8-0.3-4.6,0.3-5.4,3.4c-0.8,3.2-3.6,0.8-5.2,1.7
			C90.8,420,90.7,419.8,90.5,419.5z M102.3,402.1c-1.2,3.3-2.1,6-3.1,8.8c2.3,0,4.1,0,6.3,0C104.4,408,103.5,405.4,102.3,402.1z"/>
		<path class="st0" d="M397.5,416c3.6,0,7,0,10.6,0c0,1.4,0,2.5,0,3.8c-5.1,0-10.2,0-15.8,0c0-2.7,0-5.5,0-8.2c0-4.4,0.1-8.8,0-13.1
			c0-1.8,0.4-2.6,2.4-2.6c4.3,0.1,8.5,0,12.9,0c0,1.4,0,2.5,0,4c-3.3,0-6.5,0-9.8,0c-0.2,0.7-0.4,1-0.4,1.4
			c-0.2,4.5-0.2,4.5,4.3,4.5c1.3,0,2.6,0,4.2,0c0,1.3,0,2.4,0,3.8c-2.6,0-5.3,0-8.3,0C397.5,411.8,397.5,413.7,397.5,416z"/>
		<path class="st0" d="M365.7,401.3c-4.7-1.4-9.6-2.9-13.2,2.1c-2,2.8-2,6.7,0.1,9.3c3.7,4.7,8.4,3.1,13.1,1.9
			c0.7,4.6,0.4,4.9-4.6,5.7c-6.5,1-12.4-2.3-14.4-8.1c-2-5.9,0.1-12.4,5.5-15c2.8-1.3,6.2-1.6,9.3-1.6
			C365.7,395.9,366.2,396.8,365.7,401.3z"/>
		<path class="st0" d="M193.8,415.9c0,1.6,0,2.6,0,3.9c-4.7,0-9.3,0-14.2,0c-0.1-0.7-0.2-1.5-0.2-2.3c0-6.3,0-12.6,0-18.9
			c0-1.9,0.4-2.8,2.5-2.8c2,0,2.7,0.7,2.6,2.7c-0.1,4.9,0,9.9,0,14.9c0,2,0.7,2.7,2.7,2.6C189.2,415.8,191.4,415.9,193.8,415.9z"/>
		<path class="st0" d="M144.2,415.9c2.4,0,4.3,0,6.2,0c3.6,0,3.6,0,3.1,3.9c-4.6,0-9.2,0-14,0c0-7.8,0-15.6,0-23.7
			c1.4,0,2.9,0,4.7,0C144.2,402.6,144.2,409,144.2,415.9z"/>
		<path class="st0" d="M224.4,419.8c-4.8,0.6-4.8,0.6-4.8-3.9c0-5.8,0.1-11.6,0-17.4c0-1.9,0.5-2.7,2.6-2.7c2,0,2.6,0.8,2.5,2.7
			c-0.1,6.4,0,12.7,0,19.1C224.6,418.4,224.5,419.1,224.4,419.8z"/>
	</g>
</g>
</svg>`

const NHBA = `<svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
<style type="text/css">
	.st0{fill:#FFFFFF;}
</style>
<g>
	<path class="st0" d="M25.5,178.5c11.3,0,22.6,0.1,33.9,0c1.3,0,2.1,0.3,2.9,1.6c8.4,14.1,16.9,28.2,25.4,42.2
		c3.3,5.5,6.5,11.1,10.1,17.1c0-20.6,0-40.6,0-60.8c11.1,0,22,0,32.9,0c0,41.2,0,82.3,0,123.5c-0.6,0-1.1,0.1-1.6,0.1
		c-9.2,0-18.4,0-27.7,0c-1,0-1.6-0.1-2.3-1.1c-10.9-18.2-21.8-36.3-32.7-54.4c-2.6-4.3-5.2-8.5-8.2-12.7c0,22.7,0,45.4,0,68.3
		c-11.1,0-21.9,0-32.6,0C25.5,261.1,25.5,219.8,25.5,178.5z"/>
	<path class="st0" d="M251.4,84c0.8,0.3,1.6,0.7,2.5,1c16.8,4.4,33.6,8.8,50.3,13.2c17.8,4.7,35.5,9.4,53.3,14
		c17.8,4.7,35.5,9.4,53.3,14c18.3,4.8,36.6,9.6,54.8,14.4c2.6,0.7,5.2,1.5,7.8,2.2c0,7.3,0,14.5,0,22.1c-2.7-0.7-5.3-1.3-7.8-1.9
		c-16.2-4.2-32.4-8.5-48.6-12.7c-17.8-4.7-35.5-9.4-53.3-14c-17.8-4.7-35.5-9.4-53.3-14c-17.8-4.7-35.5-9.4-53.3-14
		c-0.8-0.2-1.6-0.4-2.3-0.8c-4.4-2-8.6-0.6-12.8,0.5c-10.1,2.7-20.2,5.4-30.3,8.1c-12.5,3.3-24.9,6.5-37.4,9.8
		c-13.4,3.5-26.9,7-40.3,10.5c-18.4,4.9-36.9,9.9-55.3,14.8c-14.5,3.8-29,7.5-43.5,11.3c-3.3,0.9-6.6,1.9-9.9,2.9
		c0-7.6,0-15.1,0-22.7c4.1-1.1,8.2-2.1,12.3-3.2c15.7-4.1,31.4-8.3,47.1-12.4c15.6-4.1,31.3-8.2,47-12.3c12.3-3.2,24.7-6.4,37-9.6
		c9.6-2.5,19.2-5,28.8-7.5c13.3-3.5,26.6-7,39.9-10.6c3.4-0.9,6.8-2,10.2-2.9C248.9,84,250.2,84,251.4,84z"/>
	<path class="st0" d="M373.9,178.6c0.8,0,1.5,0,2.1,0c21.5,0,42.9-0.3,64.4,0.1c13.2,0.3,24,6,29.9,18.2c5,10.2,4.6,20.5-1.8,30.2
		c-3.3,5-8.1,8.1-13.6,10.3c-0.4,0.2-0.8,0.4-1.5,0.8c7,1.4,12.3,4.7,16.5,9.7c3.9,4.5,6,9.8,6.4,15.7c0.6,8.7-1.5,16.6-6.6,23.7
		c-5.4,7.6-12.8,12.2-21.9,14c-3.4,0.7-7,0.8-10.6,0.9c-20.3,0.1-40.6,0-60.9,0c-0.8,0-1.6,0-2.5,0C373.9,261,373.9,220,373.9,178.6
		z M408.8,274.3c0.7,0,1.3,0.1,1.8,0.1c6.5,0,13.1,0.5,19.6-0.1c7-0.7,10.9-6.7,9.5-13.5c-1.1-5.1-5.8-8.4-11.1-8.4
		c-4.2,0-8.4,0-12.6,0c-2.4,0-4.8,0-7.2,0C408.8,259.8,408.8,266.9,408.8,274.3z M408.8,225.7c6.8,0,13.4,0.1,20,0
		c4.5-0.1,8.7-4.3,9-9.7c0.2-4.7-2.8-8.7-7.5-10.2c-1.4-0.5-3-0.4-4.4-0.5c-5.6,0-11.2,0-17,0C408.8,212.3,408.8,219.1,408.8,225.7z
		"/>
	<path class="st0" d="M325.1,256.1c-9.9,0-19.4,0-29.1,0c0,15.3,0,30.6,0,46c-12.7,0-25.1,0-37.7,0c0-41.1,0-82.1,0-123.3
		c12.5,0,24.9,0,37.6,0c0,15.7,0,31.3,0,47.1c9.8,0,19.3,0,29.1,0c0-15.7,0-31.3,0-47.2c12.7,0,25.1,0,37.7,0c0,41.1,0,82.1,0,123.3
		c-12.5,0-24.9,0-37.6,0C325.1,286.9,325.1,271.6,325.1,256.1z"/>
	<path class="st0" d="M180.9,278c-2.3,8.2-4.6,16.2-7,24.3c-12.5,0-25,0-37.8,0c0.6-2,1-3.8,1.6-5.6c3.4-10.9,6.8-21.9,10.2-32.8
		c4.2-13.5,8.4-26.9,12.6-40.4c3-9.6,5.9-19.2,8.9-28.8c1.5-4.8,3-9.6,4.5-14.5c0.3-1.2,0.8-1.6,2.1-1.6c12.4,0,24.8,0,37.2,0
		c1.2,0,1.8,0.3,2.2,1.5c3.2,10.7,6.5,21.3,9.8,31.9c2.2,7,4.4,14,6.6,21c4.5,14.6,9.1,29.2,13.6,43.8c2.6,8.4,5.2,16.8,7.9,25.4
		c-9.2,0-18.2,0-27.2,0c-3,0-5.9,0-8.9,0c-1.3,0-1.9-0.3-2.3-1.6c-1.8-6.7-3.9-13.3-5.7-20c-0.5-2-1.3-2.9-3.6-2.8
		C197.3,278.1,189.2,278,180.9,278z M208.2,250.6c-1.7-5.2-3.1-9.9-4.7-14.5c-0.5-1.5,0-2.2,1-2.9c2.2-1.6,4.5-3.2,6.8-4.8
		c1.7-1.2,3.3-2.5,5-3.8c-0.1-0.1-0.1-0.3-0.2-0.4c-5.5,0-11,0-16.3,0c-1.7-5.2-3.4-10.3-5.2-15.8c-1.6,4.7-3.2,9-4.4,13.4
		c-0.6,2.3-1.7,2.6-3.7,2.5c-4.6-0.1-9.1,0-14.3,0c4.9,3.6,9.3,6.8,13.8,10.1c-1.6,5.1-3.3,10.2-5,15.8c0.9-0.5,1.4-0.7,1.7-1
		c3.5-2.5,7.1-5,10.5-7.7c1.1-0.8,1.8-0.8,2.8-0.1C199.9,244.4,203.8,247.4,208.2,250.6z"/>
	<path class="st0" d="M202.6,393.5c2.2-2.3,4.6-3.7,8-3.7c4.6,0,6.9,3.1,7.1,7c0.4,6.1,0.1,12.2,0.1,18.4c-2.5,0-4.8,0-7.3,0
		c0-4.3,0-8.6,0-12.8c0-1.4,0-2.9-0.5-4.2c-0.3-0.8-1.3-1.6-2.2-1.7c-2.5-0.4-4.5,1.5-4.5,4c0,4.9,0,9.7,0,14.7c-2.6,0-5,0-7.7,0
		c0-4.4,0-8.8,0-13.2c0-0.8,0-1.7,0-2.5c-0.1-2.3-0.9-3.2-2.8-3.2c-2.1,0-3.9,1.6-3.9,3.5c0,4.5,0,9.1,0,13.6c0,0.5,0,1.1-0.1,1.7
		c-2.6,0-5,0-7.5,0c0-8.3,0-16.4,0-24.8c2,0,4.1,0,6.3,0c0.1,0.8,0.1,1.7,0.2,2.8C192.8,389.3,197.7,387.8,202.6,393.5z"/>
	<path class="st0" d="M45.2,353.3c0-7,0-13.9,0-21c2.2,0,4.5,0,6.9,0c0,10.6,0,21.3,0,32.3c-2.4,0-5,0.1-7.5-0.1c-0.5,0-1-1-1.3-1.6
		c-3.1-6.2-6.2-12.3-9.3-18.5c-0.3-0.5-0.6-1-0.8-1.5c0.2,7.1,0.4,14.3,0.7,21.5c-2.7,0-5,0-7.4,0c0-10.8,0-21.5,0-32.4
		c2.4,0,4.7-0.1,7,0.1c0.5,0,1.1,0.8,1.4,1.3c3.3,6.5,6.6,13,9.9,19.5C44.9,353.2,45.1,353.2,45.2,353.3z"/>
	<path class="st0" d="M260.7,383c5.6,0,11-0.2,16.4,0.1c3.3,0.1,6.5,3.1,6.9,5.9c0.6,4.4-0.9,7.2-4.8,9c2.5,0.9,5.2,1.6,6,4.5
		c1.8,6.4-0.8,11.1-7.8,12.6c-0.9,0.2-1.9,0.4-2.8,0.4c-4.6,0-9.2,0-13.9,0C260.7,404.4,260.7,393.6,260.7,383z M268.4,409.4
		c2.1,0,4,0.1,5.9,0c2.2-0.2,4-2.1,4.1-4.1c0-1.8-1.5-3.6-3.6-3.8c-2.1-0.2-4.2,0-6.4,0C268.4,404.1,268.4,406.6,268.4,409.4z
		 M268.3,396.1c2.4-0.4,4.5-0.7,6.6-1.2c1.6-0.4,1.8-2,1.8-3.3c0-1.4-0.8-2.3-2.2-2.5c-2-0.3-4-0.4-6.1-0.6
		c-0.1,0.7-0.1,1.1-0.1,1.4C268.3,391.7,268.3,393.5,268.3,396.1z"/>
	<path class="st0" d="M120.7,382.6c2.5,0,4.9,0,7.5,0c0,4.1,0,8.3,0,12.6c3.7,0,7.2,0,10.9,0c0-4.1,0-8.3,0-12.5c2.7,0,5.1,0,7.7,0
		c0,10.9,0,21.7,0,32.7c-2.5,0-4.9,0-7.5,0c0-4.4,0-8.8,0-13.4c-3.7,0-7.2,0-10.9,0c0,4.4,0,8.8,0,13.4c-2.7,0-5.1,0-7.7,0
		C120.7,404.4,120.7,393.6,120.7,382.6z"/>
	<path class="st0" d="M361.3,391.5c0-3.8,0-7.5,0-11.3c2.5,0,5,0,7.6,0c0,11.6,0,23.2,0,35c-1.9,0-4,0-6.1,0
		c-0.2-0.5-0.4-1.1-0.5-1.4c-2.2,0.7-4.2,1.6-6.3,1.9c-5,0.8-8.9-2.9-10-7.3c-1.3-5.1-1-9.9,1.9-14.6c2.4-3.8,9-5.3,12.4-2.7
		C360.5,391.3,360.8,391.3,361.3,391.5z M361.3,402.7c0-1.2,0.1-2.5,0-3.7c0-0.6-0.2-1.3-0.6-1.6c-2.2-1.7-5-1.9-6.5,0.9
		c-1.7,3-1.4,6.1-0.3,9.2c0.6,1.6,2.8,2.7,4.4,2.3c1.7-0.3,3-2.1,3.1-4C361.3,404.7,361.3,403.7,361.3,402.7z"/>
	<path class="st0" d="M222.2,364.6c3.4-10.9,6.7-21.6,10.1-32.4c2.7,0,5.4-0.1,8.2,0c0.5,0,1.2,0.6,1.4,1.1c2.4,7.4,4.7,14.8,7,22.2
		c0.9,3,1.8,6,2.8,9.2c-2.4,0-4.6,0.1-6.8-0.1c-0.6,0-1.3-0.7-1.5-1.3c-0.6-1.5-0.8-3.1-1.3-4.7c-0.2-0.6-1-1.3-1.5-1.4
		c-2.4-0.1-4.8-0.2-7.2,0c-0.6,0-1.4,0.8-1.6,1.3c-0.7,1.9-0.6,4.7-1.9,5.6c-1.5,1-4.1,0.4-6.2,0.4
		C223.1,364.7,222.7,364.6,222.2,364.6z M236.7,338.1c-1.1,4.6-2.1,8.7-3.1,12.8c2.2,0,4.2,0,6.3,0
		C238.9,346.7,237.9,342.6,236.7,338.1z"/>
	<path class="st0" d="M289.9,390.5c2.7,0,5,0,7.7,0c0,4,0,8,0,11.9c0,1.6,0.2,3.1,0.3,4.7c0.2,2.3,1.8,2.5,3.5,2.3
		c1.6-0.2,3.2-1.9,3.2-3.4c0-4.7,0-9.4,0-14c0-0.4,0-0.8,0.1-1.4c2.5,0,4.9,0,7.5,0c0,8.2,0,16.4,0,24.7c-2,0-4,0-6.1,0
		c-0.2-0.8-0.3-1.7-0.5-2.9c-1,0.9-1.8,1.6-2.6,2.2c-2.4,1.8-5.2,1.5-7.9,1.2c-2.5-0.3-4.7-3.5-4.8-6.4c-0.2-4.3-0.3-8.6-0.4-12.9
		C289.8,394.5,289.9,392.6,289.9,390.5z"/>
	<path class="st0" d="M472.7,364.6c-2.7,0-5.1,0-7.7,0c0-3.4,0-6.6,0-9.9c0-1.5,0-3,0-4.5c0-1.6,0.3-3.4-1.7-4.2
		c-2.1-0.9-3.6,0.6-5.1,1.7c-0.4,0.3-0.4,1.2-0.4,1.9c0,4.9,0,9.9,0,15c-2.6,0-5,0-7.6,0c0-8.2,0-16.4,0-24.8c2,0,4,0,6.2,0
		c0.2,0.8,0.3,1.7,0.5,2.9c1.2-0.9,2.1-1.7,3.1-2.2c5.2-2.7,11.9-2,12.6,6.5C473.1,352.7,472.7,358.6,472.7,364.6z"/>
	<path class="st0" d="M321.1,352.4c0.4,8.7-5.7,13.2-12.3,13.1c-5.8-0.1-10.9-4.5-11.6-10.4c-0.6-4.8,0.1-9,3.6-12.5
		c3.6-3.6,8-4.2,12.6-2.6c4.9,1.7,7.8,6.1,7.8,11C321.1,351.4,321.1,351.9,321.1,352.4z M313.6,352.4c-0.1,0-0.1,0-0.2,0
		c0-0.7,0-1.4,0-2.1c-0.2-2.6-1.9-4.7-3.9-5c-1.7-0.2-3.8,1.5-4.3,3.8c-0.5,2.2-0.6,4.5,0.2,6.7c0.6,1.9,1.6,3.2,3.8,3.2
		c2.3,0,3.3-1.4,3.8-3.2C313.3,354.7,313.4,353.6,313.6,352.4z"/>
	<path class="st0" d="M141.1,352.4c0.5,8.3-5.7,13.4-12.5,13c-5-0.3-9.5-2.6-11.1-9c-1.3-5.2-0.7-10,3.2-13.9
		c3.1-3.1,8.6-4.3,12.6-2.6c4.8,2,7.7,6.1,7.7,11.1C141.1,351.4,141.1,351.9,141.1,352.4z M133.6,352.1c-0.3-1.3-0.7-2.7-1-4.2
		c-0.4-1.9-2-2.5-3.5-2.5c-1.6,0.1-3.2,0.9-3.7,2.7c-0.7,2.5-0.9,5.1-0.1,7.7c0.6,1.9,1.5,3.2,3.8,3.2c2.3,0,3.3-1.4,3.9-3.2
		C133.3,354.7,133.3,353.6,133.6,352.1z"/>
	<path class="st0" d="M445.5,350.7c0,7.6-2.5,11.9-7.9,13.9c-7.6,2.9-15.5-1.7-16.3-10.2c-0.3-3.6,0-7.1,2.2-10.3
		c3.9-5.6,12.8-6.6,17.8-2C444.2,344.9,445.3,348.2,445.5,350.7z M428.4,352.1c0.1,0.1,0.3,0.2,0.4,0.2c0.2,1.3,0.1,2.7,0.7,3.7
		c0.7,1.2,1.8,2.4,3,2.9c1.8,0.6,3.5-0.2,4.3-2.1c1.3-3.2,1.4-6.3-0.3-9.3c-1.4-2.5-4-3-5.8-0.7
		C429.6,348.2,429.2,350.3,428.4,352.1z"/>
	<path class="st0" d="M87.8,402.9c0.6,6-3.6,11.6-8.5,12.6c-7.4,1.5-13.6-1.2-15.4-9.2c-0.9-4.4-0.5-8.6,2.5-12
		c1.4-1.6,3.3-3.2,5.3-3.8c8.2-2.4,15.6,2.4,16,11.1C87.8,402,87.8,402.5,87.8,402.9z M80.4,402.9c-0.4-1.6-0.7-3.2-1.3-4.7
		c-1.2-2.7-3.8-3-6-1c-2.1,1.9-2.5,7.8-0.5,10.6c0.7,1,2.3,1.8,3.5,1.8c1.1,0,2.4-1.2,3-2.2C79.8,406.2,80,404.5,80.4,402.9z"/>
	<path class="st0" d="M187.3,348c-0.7-2.7-2.9-3.6-6.5-2.1c-1.2,0.5-2.3,1.1-3.6,1.7c-0.4-0.7-0.8-1.4-1.2-2.1
		c-2.1-3.5-2.1-3,1.7-4.7c4.7-2,9.5-2.8,14.1,0.4c1.9,1.3,2.6,3.3,2.8,5.4c0.4,2.8,0.4,5.7,0.5,8.6c0.1,3.1,0,6.2,0,9.4
		c-2.2,0-4.2,0-6.2,0c-0.2-0.6-0.4-1.2-0.5-1.4c-2.6,0.8-4.9,2-7.3,2.2c-3.4,0.4-6.3-1.7-7.1-4.9c-0.7-3-0.7-7.3,2.8-8.8
		C180.1,350.2,183.7,349.3,187.3,348z M187.4,353.3c-1.6,0.4-3,0.6-4.3,1.1c-1.7,0.7-2.6,2.6-2,3.7c0.8,1.4,2.7,1.6,4.5,0.8
		C188.1,357.8,187.4,355.7,187.4,353.3z"/>
	<path class="st0" d="M152.4,402.8c-0.3-5.3,1.7-9.4,6.5-11.7c7.4-3.5,15.8,0.2,17.4,8.8c0.8,4.3,0,8-2.4,11.6
		c-1.7,2.5-4.1,3.8-6.8,4.3c-8.9,1.5-15.1-4.6-14.6-12.3C152.4,403.2,152.4,403,152.4,402.8z M159.9,402.8c0.2,1.4,0.3,2.6,0.6,3.8
		c0.4,1.7,1.5,2.9,3.3,3.2c1.8,0.3,3-0.6,3.8-2.1c1.5-2.9,1.6-5.8,0.4-8.8c-0.7-1.7-1.8-2.9-3.8-2.8c-2,0-2.9,1.4-3.4,3
		C160.3,400.2,160.1,401.6,159.9,402.8z"/>
	<path class="st0" d="M71.7,348.4c-0.6-3.9-3.1-4.1-10.2-0.7c-0.4-0.8-0.8-1.7-1.3-2.5c-0.4-0.8-0.8-1.7-1.3-2.5
		c4.1-3.4,12.1-4.9,16.8-1.9c2.7,1.7,3.2,4.6,3.7,7.3c0.1,0.5,0,1,0,1.4c0,4.9,0,9.9,0,14.9c-2.1,0-4.1,0-6.2,0
		c-0.2-0.5-0.4-1.2-0.7-2c-2.8,2.2-5.7,3.6-9,2.7c-3.5-0.9-5-2.9-5.4-6c-0.7-5.5,2.4-7.8,5.3-8.8C66.1,349.5,69,349,71.7,348.4z
		 M71.5,353.1c-1.8,0.8-3.6,1.3-5,2.3c-0.7,0.5-1,1.9-0.8,2.8c0.1,0.5,1.5,1.2,2.3,1.1C70.6,359.1,72.6,358.2,71.5,353.1z"/>
	<path class="st0" d="M153.5,364.6c-2.5,0-4.7,0-7.1,0c0-8.2,0-16.4,0-24.8c1.8,0,3.7,0,5.7,0c0.3,0.8,0.5,1.7,0.8,2.7
		c1.4-0.8,2.6-1.8,4-2.4c5.3-2.3,11-0.4,11.4,6.7c0.2,2.9,0.3,5.9,0.4,8.8c0.1,2.9,0,5.9,0,9c-2.5,0-5,0-7.7,0c0-4.3,0-8.7,0-13.1
		c0-1.2-0.2-2.4-0.4-3.6c-0.3-1.7-1.6-2.3-3.2-2.1c-2.3,0.3-4,2.3-4,4.6C153.5,355,153.5,359.7,153.5,364.6z"/>
	<path class="st0" d="M383.5,364.8c-2.3-0.9-5.2,1.4-6.7-1.8c-1.6,0.7-3.2,1.5-5,2.1c-3.8,1.3-7.7-0.6-9.1-4.3
		c-1.5-3.7,0-8.1,3.4-9.6c3.1-1.3,6.4-2.1,9.7-3.1c-0.2-4.1-5.2-3.6-10.1-0.4c-0.8-1.5-1.6-2.9-2.3-4.4c-0.2-0.4,0.2-1.3,0.6-1.5
		c4.3-2.3,8.9-3.5,13.7-2.1c3.4,1,5.4,3.8,5.6,7.1C383.7,352.6,383.5,358.6,383.5,364.8z M376,353.1c-1.9,0.6-3.7,1-5.2,1.9
		c-0.7,0.4-1.4,2-1.2,2.7c0.3,0.8,1.7,1.5,2.7,1.6C375.1,359.5,376.4,357.4,376,353.1z"/>
	<path class="st0" d="M395.9,405.1c-5,0-9.7,0-14.4,0c0.2,4.9,5.1,6.4,11.2,3.6c0.1,0.1,0.3,0.1,0.3,0.2c2.8,5.1,2.8,5.5-3.2,6.7
		c-4.6,1-9.1,0.6-12.7-3.1c-3.8-3.9-4.4-12-1.6-16.7c3-5,7.4-6.3,12.7-5.7c3.8,0.4,7.5,4.6,7.7,8.8
		C396,400.8,395.9,402.8,395.9,405.1z M389.3,400.3c0.2-2.5-1.2-4.4-3.1-4.7c-2.3-0.3-4.5,1.7-5,4.6c0.4,0,0.8,0.1,1.2,0.1
		C384.6,400.3,386.9,400.3,389.3,400.3z"/>
	<path class="st0" d="M244.7,405.1c-5,0-9.7,0-14.5,0c0.3,4.9,5.1,6.4,11.2,3.5c0.1,0,0.1,0,0.2,0.1c2.7,4.7,4.4,4.9-2.9,6.7
		c-4.7,1.2-9.3,0.7-13-3.1c-3.5-3.6-4.2-11.9-1.7-16.3c3-5.2,7.5-6.8,12.9-6c4.5,0.7,7,4.1,7.7,8.8
		C245,400.8,244.7,402.8,244.7,405.1z M238.1,400.2c0.2-2.4-1.1-4.3-3.1-4.6c-2.3-0.3-4.5,1.7-5,4.6
		C232.7,400.2,235.3,400.2,238.1,400.2z"/>
	<path class="st0" d="M101.5,415.3c-2.6,0-4.8,0-7.3,0c0-6.2,0-12.4,0-18.8c-1.2-0.1-2.1-0.1-3.2-0.2c0-1.7,0-3.5,0-5.4
		c0.9-0.2,1.9-0.5,2.9-0.7c0.2-1.7,0.3-3.5,0.7-5.2c0.9-3.2,3.3-4.9,6.5-5.3c1.9-0.2,3.9,0.1,5.9,0.4c0.4,0.1,1.1,1,1,1.4
		c-0.2,1.5-0.7,3-1,4.4c-4.3-0.8-5.7,0.3-5.4,4.4c1.4,0,2.8,0,4.3,0c0,2.1,0,3.9,0,6c-1.4,0-2.8,0-4.3,0
		C101.5,402.8,101.5,408.9,101.5,415.3z"/>
	<path class="st0" d="M294,342.5c-1.1,1.5-2.2,3-3.3,4.5c-1.4-0.7-2.7-1.3-4-1.9c-0.5-0.2-1.3-0.4-1.8-0.2c-0.8,0.3-1.8,0.8-1.9,1.4
		c-0.1,0.5,0.6,1.6,1.2,2c1.8,1,3.7,1.6,5.5,2.5c3.5,1.7,4.7,3.9,4.2,8.4c-0.3,2.9-2.4,4.6-5.1,5.5c-5.3,1.9-10,0.4-14.7-2.6
		c1.2-1.7,2.3-3.3,3.2-4.7c2,0.8,3.8,1.6,5.7,2.2c0.9,0.3,2,0.2,2.9-0.2c1.2-0.5,1.7-1.6,0.4-2.5c-1.1-0.8-2.5-1.4-3.7-2
		c-2.5-1.3-5.4-2.1-6.7-5c-1.8-4.3,0.9-9,5.6-10.2C286.1,338.4,290.1,339.5,294,342.5z"/>
	<path class="st0" d="M252,361.9c1.2-1.7,2.3-3.3,3.3-4.6c1.9,0.8,3.7,1.6,5.6,2.4c0.5,0.2,1.1,0.3,1.6,0.1c0.8-0.4,1.5-1,2.3-1.6
		c-0.6-0.8-1.1-1.9-1.9-2.3c-2-1.1-4.1-1.9-6.2-3c-3-1.5-3.4-4.2-3.1-7.2c0.2-2.5,1.7-4.2,3.9-5.2c4.6-2.2,10.6-1.3,14.4,2.2
		c-0.1,0.2-0.2,0.3-0.3,0.5c-1,1.2-1.7,3.1-3,3.5c-1.1,0.4-2.8-0.9-4.2-1.4c-0.2-0.1-0.4-0.3-0.5-0.3c-1.1,0.5-2.2,1-3.4,1.5
		c0.7,0.8,1.2,1.8,2,2.2c2.1,1.1,4.5,1.8,6.6,3c4.2,2.6,3.5,9.2,0,11.6C264.3,366.7,257.3,365.9,252,361.9z"/>
	<path class="st0" d="M421.8,407.8c7.4,3.4,8.3,3.4,9.7-0.1c-1.8-0.8-3.6-1.6-5.3-2.6c-1.9-1.1-4-2.2-5.3-3.8
		c-2.7-3.4-0.8-9,3.4-10.4c4.9-1.7,9.6-1.2,14.1,2.2c-1.1,1.5-2.2,3.1-3.2,4.4c-2-0.6-3.7-1.4-5.6-1.7c-0.8-0.1-1.7,0.9-2.6,1.3
		c0.6,0.7,1.1,1.7,1.9,2.1c1.8,1,3.9,1.5,5.7,2.6c3.3,1.9,4.2,3.8,3.8,7.4c-0.3,2.9-2,5-4.9,5.9c-4.8,1.6-9.3,1.1-13.7-1.5
		c-1.3-0.8-1.2-1.5-0.5-2.4C420,410,420.9,409,421.8,407.8z"/>
	<path class="st0" d="M404,345.7c-1.9,0-3.6,0-5.6,0c0,3.8-0.1,7.6,0.1,11.3c0.1,1.7,2.2,2.9,3.9,2.3c1.4-0.4,1.7,0,1.8,1.2
		c0.1,1.3,0.5,2.5,0.8,3.7c-4.2,2.2-10.4,1.6-12.2-1.4c-1.1-1.9-1.9-4.2-2.1-6.4c-0.3-3.5-0.1-7-0.1-10.6c-1.1-0.1-2.1-0.2-3.2-0.2
		c0-1.8,0-3.6,0-5.3c0.2-0.1,0.3-0.2,0.4-0.2c3.4-0.2,3.4-0.2,3.6-3.7c0.1-1.1,0.3-2.1,0.5-3.3c2,0,4.1,0,6.3,0c0,2.1,0,4.1,0,6.4
		c2,0,3.8,0,5.7,0C404,341.6,404,343.5,404,345.7z"/>
	<path class="st0" d="M86.7,345.8c-1.3-0.1-2.4-0.1-3.7-0.2c0-0.9,0-1.8,0-2.7c0-0.9,0-1.8,0-2.5c0.3-0.2,0.4-0.3,0.5-0.3
		c3.8-0.2,3.9-0.2,4-4.7c0-1.9,0.6-2.6,2.5-2.4c1.3,0.1,2.6,0,4,0c0,2.2,0,4.3,0,6.6c2.1,0,4.1,0,6.1,0c0,2.1,0,4,0,6.1
		c-2,0-3.9,0-6,0c0,3.6-0.3,7.1,0.1,10.6c0.3,2.9,1.6,3.5,4.5,2.8c0.3-0.1,0.5,0,1,0c0.4,1.8,0.7,3.6,1.1,5.4c-3.5,1.3-7,1.6-10.3,0
		c-2.7-1.3-3.7-3.9-3.7-6.7C86.6,353.8,86.7,349.9,86.7,345.8z"/>
	<path class="st0" d="M342.4,357.3c1,1.6,2,3.2,3,4.7c-3.3,4.1-11.5,4.6-16,1.1c-6.1-4.8-6.4-15.3-1-20.5c3.9-3.8,11.1-4.5,15.2-1.7
		c1.2,0.8,1.6,1.6,0.4,2.8c-0.9,0.9-1.6,2-2.2,2.9c-1.6-0.4-3.2-1.2-4.6-1c-1.4,0.3-3,1.3-3.7,2.5c-1.7,2.7-1.7,6.9,0.9,9.5
		C336.5,359.7,339,359.6,342.4,357.3z"/>
	<path class="st0" d="M201.3,329.8c2.3,0,4.7,0,7.5,0c0,0.7,0,1.4,0,2.1c0,8.3,0,16.6,0,25c0,1.1-0.1,2.1,1.5,2.2
		c0.2,0,0.5,0.5,0.6,0.8c0.2,0.7,0.3,1.4,0.4,2.2c0.5,3.2,0.4,3.3-2.9,3.4c-5,0.2-7.1-2.9-7.2-6.8c-0.2-9.5-0.1-19,0-28.5
		C201.1,330.1,201.2,330,201.3,329.8z"/>
	<path class="st0" d="M332.7,380.2c2.2,0,4.5,0,7,0c0,0.7,0,1.4,0,2.1c0,7.8,0,15.5,0,23.3c0,1.7-0.6,3.9,2.2,4.3
		c0.3,1.8,0.6,3.6,0.9,5.5c0,0.1-0.3,0.4-0.4,0.4c-2.2-0.1-4.6,0.3-6.7-0.4c-2.3-0.7-3.3-3-3.3-5.4c-0.1-9.8,0-19.5,0-29.3
		C332.5,380.5,332.6,380.4,332.7,380.2z"/>
	<path class="st0" d="M401.2,390.4c1.5,0,2.9,0.1,4.3,0c1.5-0.1,2.2,0.4,2,2c-0.1,0.6,0,1.2,0,2.2c1.1-1.2,1.8-2.4,2.8-3.2
		c1.9-1.5,4.2-1.6,6.4-1.3c0.4,0.1,0.9,0.9,1,1.5c0.1,0.6-0.2,1.3-0.3,2c-0.4,3-0.4,3-3.3,2.9c-2.3-0.1-5.3,2.8-5.3,5.3
		c0,4.5,0,8.9,0,13.6c-2.5,0-4.9,0-7.5,0C401.2,407.1,401.2,398.8,401.2,390.4z"/>
	<path class="st0" d="M416.5,339.7c0,8.3,0,16.5,0,24.8c-2.5,0-4.9,0-7.5,0c0-8.2,0-16.4,0-24.8
		C411.4,339.7,413.8,339.7,416.5,339.7z"/>
	<path class="st0" d="M357,364.5c-2.6,0-5,0-7.5,0c0-8.3,0-16.5,0-24.8c2.5,0,5,0,7.5,0C357,348,357,356.2,357,364.5z"/>
	<path class="st0" d="M112.1,364.5c-2.6,0-5,0-7.5,0c0-8.3,0-16.5,0-24.8c2.5,0,5,0,7.5,0C112.1,348,112.1,356.2,112.1,364.5z"/>
	<path class="st0" d="M326,415.3c-2.5,0-5,0-7.5,0c0-8.3,0-16.5,0-24.8c2.5,0,5,0,7.5,0C326,398.8,326,407,326,415.3z"/>
	<path class="st0" d="M412.4,328.5c2.8,0,4.5,1.4,4.6,3.8c0,2.4-2,4.4-4.4,4.3c-2.6-0.1-4.6-2-4.6-4.2
		C408,330.2,409.9,328.5,412.4,328.5z"/>
	<path class="st0" d="M348.9,332.4c0-2.5,1.6-3.9,4.4-3.9c2.7,0,4.2,1.5,4.2,4.1c0,2.2-1.8,3.9-4.2,4
		C350.6,336.6,348.9,335,348.9,332.4z"/>
	<path class="st0" d="M112.6,332.6c0,2.4-1.8,4-4.4,3.9c-2.5-0.1-4.2-1.8-4.2-4.3c0-2.4,1.7-3.8,4.5-3.8
		C111.1,328.5,112.6,330,112.6,332.6z"/>
	<path class="st0" d="M322.4,386.9c-2.8,0-4.4-1.4-4.5-3.8c0-2.5,1.6-3.9,4.6-3.9c2.7,0,4.2,1.4,4.2,3.8
		C326.8,385.5,325.2,386.9,322.4,386.9z"/>
	<g>
		<path class="st0" d="M486.1,279.2c1.9,0,3.8,0.5,5.6,1.5c1.8,1,3.3,2.4,4.3,4.2c1,1.8,1.5,3.7,1.5,5.7c0,2-0.5,3.9-1.5,5.7
			c-1,1.8-2.4,3.2-4.2,4.2c-1.8,1-3.7,1.5-5.7,1.5c-2,0-3.9-0.5-5.7-1.5c-1.8-1-3.2-2.4-4.2-4.2c-1-1.8-1.5-3.7-1.5-5.7
			c0-2,0.5-3.9,1.5-5.7c1-1.8,2.5-3.2,4.3-4.2C482.3,279.7,484.1,279.2,486.1,279.2z M486.1,281.1c-1.6,0-3.2,0.4-4.7,1.2
			c-1.5,0.8-2.7,2-3.6,3.5c-0.9,1.5-1.3,3.1-1.3,4.8c0,1.6,0.4,3.2,1.3,4.7c0.8,1.5,2,2.7,3.5,3.5c1.5,0.8,3.1,1.3,4.7,1.3
			c1.6,0,3.2-0.4,4.7-1.3c1.5-0.8,2.7-2,3.5-3.5c0.8-1.5,1.3-3.1,1.3-4.7c0-1.7-0.4-3.3-1.3-4.8c-0.9-1.5-2-2.7-3.6-3.5
			C489.2,281.5,487.7,281.1,486.1,281.1z M481,297v-12.3h4.2c1.4,0,2.5,0.1,3.1,0.3c0.6,0.2,1.2,0.6,1.5,1.2
			c0.4,0.6,0.6,1.2,0.6,1.8c0,0.9-0.3,1.7-1,2.4c-0.6,0.7-1.5,1-2.6,1.1c0.4,0.2,0.8,0.4,1,0.7c0.5,0.5,1.1,1.3,1.8,2.4l1.5,2.4H489
			l-1.1-1.9c-0.9-1.5-1.6-2.5-2.1-2.9c-0.4-0.3-0.9-0.4-1.6-0.4H483v5.2H481z M483,290.1h2.4c1.2,0,1.9-0.2,2.4-0.5
			c0.4-0.3,0.6-0.8,0.6-1.4c0-0.4-0.1-0.7-0.3-1c-0.2-0.3-0.5-0.5-0.8-0.6c-0.4-0.1-1-0.2-2-0.2H483V290.1z"/>
	</g>
</g>
</svg>`

export const Associations = {
  TwoTenHomebuyersWarranty,
  AshevilleHomeBuildersAssociation,
  BetterBusinessBureau,
  EnergyStar,
  EqualHousingOpportunity,
  FairHousingAct,
  GreenBuiltAlliance,
  NHBA,
}

const DarkFlat = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1137.9 616.8">
<style type="text/css">

.df0{fill:none;}
.df1{fill:#962A1F;}
.df2{fill:#1E1E1E;}
.df3{fill:#323232;}

</style>
  <path class="df0" d="M346.2 205.6c-1 3.3-1.9 5.8-2.5 8.3 -3.6 14.1-6.4 28.4-8.4 42.9 -0.1 0.5-0.1 1.1-0.2 1.6H378c-3.7-9.6-8.2-19-13.7-28.1C359.2 221.8 353.7 213.6 346.2 205.6z"/>
  <path class="df0" d="M283.2 164.7c2.7-4.7 2.5-5.1-3.1-6.4 -7.3-1.6-14.6-3.3-22.1-4.3 -27-3.7-53.7-2.7-79.6 5.7 -41.3 13.4-70.8 40.7-90.6 78.1 -3.6 6.8-6.7 13.6-9.5 20.6h172.3c1-7 2.2-14 3.9-20.9C260.6 211.9 269.9 187.6 283.2 164.7z"/>
  <path class="df0" d="M1011.8 189.4c-8.5-7.3-17.5-13.8-27.4-19.2 -23.6-12.9-49.2-18-78.4-17.9 -5.9-0.6-14.1 0.4-22.3 1.2 -7.6 0.8-15.2 2.5-22.7 4 -3 0.6-4.6 1.3-2.4 5.2 8.1 14.3 15.3 28.9 20.7 44.4 5.8 16.8 10.2 33.9 13 51.3h167.1C1049.3 232.1 1034.3 208.6 1011.8 189.4z"/>
  <path class="df0" d="M769.7 166c3-2 3.8-3.6 2-7.1 -15.7-29.9-35.4-56.5-62.4-77.6 -14.1-11-29-20.8-45.6-27.9 -29.7-12.7-61-17.3-94.8-16.8 -14.8-0.8-30.9 1.2-46.9 4.1 -39.7 7.2-73.3 25.9-102.3 52.8 -20.5 19-36.2 41.4-48.4 66.2 -3.1 6.3-3.2 6.3 2.7 10.2 8.2 5.5 15.9 11.6 23.1 18.2 21.2 19.4 37.8 42.1 50.4 67.8 0.4 0.8 0.8 1.6 1.1 2.4h240.9C707.4 220.5 734.1 189.5 769.7 166z"/>
  <path class="df0" d="M781 201.6c-2.9 3-5.5 5.4-7.6 8 -12 15-21.2 31.3-28 48.8h48.2c-1.2-9.9-3.2-19.7-5.2-29.5C786.5 219.9 784.5 211 781 201.6z"/>
    <path class="df1" d="M87.8 237.8c19.9-37.4 49.4-64.6 90.6-78.1 25.8-8.4 52.6-9.4 79.6-5.7 7.5 1 14.8 2.7 22.1 4.3 5.6 1.2 5.8 1.7 3.1 6.4 -13.3 22.9-22.7 47.2-28.7 72.8 -1.6 6.9-2.9 13.9-3.9 20.9h84.4c0.1-0.5 0.1-1.1 0.2-1.6 2-14.5 4.8-28.7 8.4-42.9 0.6-2.5 1.5-5 2.5-8.3 7.5 8 13 16.2 18.1 24.7 5.4 9.1 9.9 18.5 13.7 28.1h70.5c-0.4-0.8-0.8-1.6-1.1-2.4 -12.6-25.7-29.2-48.3-50.4-67.8 -7.2-6.6-14.9-12.7-23.1-18.2 -5.9-3.9-5.8-3.9-2.7-10.2 12.2-24.8 27.9-47.3 48.4-66.2 29-26.9 62.6-45.6 102.3-52.8 16-2.9 32.2-4.9 46.9-4.1 33.8-0.5 65.1 4.2 94.8 16.8 16.6 7.1 31.5 16.9 45.6 27.9 27 21.1 46.8 47.7 62.4 77.6 1.8 3.5 1.1 5-2 7.1 -35.6 23.5-62.3 54.4-80.4 92.4h56.1c6.8-17.5 16-33.8 28-48.8 2.1-2.7 4.7-5 7.6-8 3.5 9.4 5.5 18.4 7.3 27.3 2 9.8 4.1 19.6 5.2 29.5h98.7c-2.7-17.4-7.2-34.5-13-51.3 -5.3-15.5-12.6-30.1-20.7-44.4 -2.2-3.9-0.5-4.6 2.4-5.2 7.5-1.5 15.1-3.2 22.7-4 8.2-0.9 16.4-1.9 22.3-1.2 29.2-0.1 54.8 5 78.4 17.9 9.8 5.4 18.9 11.9 27.4 19.2 22.4 19.2 37.5 42.8 47.6 69h63.4c-3.5-7.4-7.4-14.6-11.7-21.6 -14.8-24.1-33.4-44.9-56.1-62 -17.1-12.9-35.8-23.3-55.8-31.5 -25.3-10.4-51.7-16.2-79-17.4 -11.5-0.5-23.1-0.5-34.8 0.6 -13.7 1.3-27.3 3.1-40.5 6.7 -3 0.8-5.1 0.4-6.9-2.3 -14-19.5-30.5-36.6-48.7-52.4 -18.6-16.1-39.1-29.5-60.9-40.9 -27.7-14.4-56.9-25-87.7-31.2 -17.6-3.6-35.3-6-53.3-6.2 -14.8-0.2-29.6-0.6-44.4 0.7 -12.2 1.1-24.4 2.4-36.3 4.7 -24.9 4.8-49 12.4-72.3 22.6 -35 15.3-66.4 35.8-94.1 61.8 -13.4 12.6-25.6 26.5-36.2 41.6 -1.8 2.5-3.5 3.4-6.7 2.5 -15.8-4.6-32.1-7.2-48.4-8 -12.9-0.7-25.8-0.5-38.8 0.5 -22.2 1.8-43.7 6.3-64.5 14.1 -43.9 16.5-80.6 42.4-108.2 80 -8.9 12.1-16.5 24.7-22.8 37.8h63.3C81.1 251.4 84.2 244.6 87.8 237.8z"/>
    <path class="df2" d="M2.6 288.7c-0.2 0.6-0.4 1.2-0.6 1.8h0.6V288.7z"/>
    <path class="df3" d="M165.6 417.1c0 7.7-1.4 14.6-4.3 20.8 -2.8 6.2-6.7 11.5-11.5 16 -4.8 4.5-10.5 7.9-16.9 10.4 -6.4 2.5-13.2 3.7-20.4 3.7 -9.6 0-18.1-1.7-25.4-5.2 -7.3-3.5-12.7-6.3-16.2-8.5 -1.2 1.2-2.6 3-4.1 5.4 -1.5 2.4-2.5 5.1-3 8.4h-6.7v-63.9h7.4c1.5 15.8 6.8 28 16 36.6 9.2 8.5 20.8 12.8 34.9 12.8 7.9 0 14.7-2.2 20.2-6.5 5.6-4.3 8.4-10.5 8.4-18.4 0-6.4-2.5-12.3-7.6-17.6 -5.1-5.3-12.7-10.5-22.8-15.4l-25.2-13c-8.9-4.7-16.2-10.8-21.9-18.4 -5.7-7.5-8.5-16.5-8.5-26.9 0-5.9 1.2-11.4 3.5-16.5 2.4-5.1 5.6-9.5 9.7-13.4 4.1-3.8 9-6.9 14.7-9.1 5.7-2.2 11.9-3.3 18.6-3.3 7.7 0 14.4 1.2 20.2 3.5 5.8 2.4 10.7 4.9 14.7 7.6 1.2-1.2 2.4-2.7 3.5-4.5 1.1-1.7 1.9-4.1 2.4-7.1h7.1v62.7h-7.4c-0.5-5.7-1.5-11.4-3.2-17.1 -1.6-5.7-4-10.9-7.1-15.6 -3.1-4.7-7-8.5-11.7-11.5 -4.7-3-10.4-4.5-17.1-4.5 -7.2 0-13.4 1.9-18.8 5.6 -5.3 3.7-8 9-8 16 0 6.4 2.5 11.9 7.6 16.3 5.1 4.5 13.3 9.7 24.7 15.6l24.9 13c10.6 5.4 18.2 12 22.6 19.7C163.3 402.5 165.6 409.9 165.6 417.1z"/>
    <path class="df3" d="M340.8 346.1c-3.2 0.7-5.6 2.2-7.2 4.5 -1.6 2.2-2.7 5.3-3.3 9.3 -0.6 4-0.9 9.3-0.9 16v36.4c0 8.9-1.4 16.7-4.1 23.4 -2.7 6.7-6.5 12.3-11.3 16.7 -4.8 4.5-10.5 7.9-17.1 10.2 -6.6 2.3-13.7 3.5-21.3 3.5 -7.7 0-14.9-1.2-21.7-3.5 -6.8-2.4-12.8-5.8-18-10.2 -5.2-4.5-9.3-10-12.3-16.7 -3-6.7-4.5-14.5-4.5-23.4v-36.4c0-6.7-0.3-12-0.9-16 -0.6-4-1.7-7.1-3.3-9.3 -1.6-2.2-4-3.7-7.2-4.5 -3.2-0.7-7.4-1.1-12.6-1.1V338H268v7.1c-5.2 0-9.3 0.4-12.4 1.1 -3.1 0.7-5.5 2.2-7.2 4.5 -1.7 2.2-2.9 5.3-3.5 9.3 -0.6 4-0.9 9.3-0.9 16v38.2c0 12.4 3.3 21.5 9.8 27.5 6.6 5.9 15 8.9 25.4 8.9 10.4 0 19-3 25.8-8.9 6.8-5.9 10.2-15.1 10.2-27.5v-38.2c0-6.7-0.3-12-0.7-16 -0.5-4-1.6-7.1-3.3-9.3 -1.7-2.2-4.2-3.7-7.2-4.5 -3.1-0.7-7.2-1.1-12.4-1.1V338h62v7.1C348.2 345 344 345.4 340.8 346.1z"/>
    <path class="df3" d="M520.5 346.1c-3.2 0.7-5.6 2.2-7.2 4.5 -1.6 2.2-2.7 5.3-3.3 9.3 -0.6 4-0.9 9.3-0.9 16v90.2h-7.8L414.7 374v49.4c0 6.7 0.3 12.1 0.9 16.1 0.6 4.1 1.7 7.2 3.3 9.5 1.6 2.2 4 3.7 7.2 4.5 3.2 0.7 7.4 1.1 12.6 1.1v7.1h-59.8v-7.1c5.2 0 9.4-0.4 12.6-1.1 3.2-0.7 5.6-2.2 7.2-4.5 1.6-2.2 2.7-5.4 3.3-9.5 0.6-4.1 0.9-9.5 0.9-16.1v-47.5c0-6.7-0.3-12-0.9-16 -0.6-4-1.7-7.1-3.3-9.3 -1.6-2.2-4-3.7-7.2-4.5 -3.2-0.7-7.4-1.1-12.6-1.1V338h36.8l81.7 86.5v-48.6c0-6.7-0.3-12-0.9-16 -0.6-4-1.7-7.1-3.3-9.3 -1.6-2.2-4-3.7-7.2-4.5 -3.2-0.7-7.4-1.1-12.6-1.1V338h59.8v7.1C527.9 345 523.7 345.4 520.5 346.1z"/>
    <path class="df3" d="M562.5 454.6c5.2 0 9.4-0.4 12.6-1.1 3.2-0.7 5.6-2.2 7.2-4.5 1.6-2.2 2.7-5.4 3.3-9.5 0.6-4.1 0.9-9.5 0.9-16.1v-47.5c0-6.7-0.3-12-0.9-16 -0.6-4-1.7-7.1-3.3-9.3 -1.6-2.2-4-3.7-7.2-4.5 -3.2-0.7-7.4-1.1-12.6-1.1V338h66.5c13.4 0 24.8 1.6 34.3 4.8 9.5 3.2 17.4 7.6 23.6 13.2 6.2 5.6 10.8 12.1 13.7 19.7 3 7.6 4.5 15.5 4.5 23.9 0 8.4-1.5 16.4-4.5 23.9 -3 7.6-7.6 14.1-13.7 19.7 -6.2 5.6-14 10-23.6 13.4 -9.5 3.3-21 5-34.3 5h-66.5V454.6zM675.7 399.2c0-5.4-0.6-11.1-1.7-17.1 -1.1-5.9-3.3-11.3-6.7-16.2 -3.3-4.8-8-8.8-13.9-12.1 -5.9-3.2-13.7-4.8-23.4-4.8h-17.8v80.6c0 8.4 0.9 13.9 2.6 16.5 1.7 2.6 5.9 3.9 12.6 3.9h2.6c9.7 0 17.4-1.6 23.4-4.8 5.9-3.2 10.6-7.3 13.9-12.3 3.3-4.9 5.6-10.4 6.7-16.3C675.2 410.8 675.7 404.9 675.7 399.2z"/>
    <path class="df3" d="M891.1 399.6c0 9.2-2 17.8-5.9 26 -4 8.2-9.3 15.3-16 21.3 -6.7 6.1-14.5 10.8-23.6 14.3 -9 3.5-18.6 5.2-28.8 5.2 -10.2 0-19.7-1.7-28.8-5.2 -9-3.5-16.9-8.2-23.6-14.3 -6.7-6.1-12-13.2-16-21.3 -4-8.2-5.9-16.8-5.9-26 0-9.2 2-17.8 5.9-25.8 4-8 9.3-15.1 16-21.2 6.7-6.1 14.5-10.8 23.6-14.3 9-3.5 18.6-5.2 28.8-5.2 10.1 0 19.7 1.7 28.8 5.2 9 3.5 16.9 8.2 23.6 14.3 6.7 6.1 12 13.1 16 21.2C889.1 381.9 891.1 390.5 891.1 399.6zM862.8 399.6c0-5.9-0.8-12.2-2.4-18.7 -1.6-6.6-4.2-12.5-7.8-17.8 -3.6-5.3-8.3-9.7-14.1-13.2 -5.8-3.5-13.1-5.2-21.7-5.2 -8.4 0-15.6 1.7-21.5 5.2 -5.9 3.5-10.7 7.9-14.3 13.2 -3.6 5.3-6.2 11.3-7.8 17.8 -1.6 6.6-2.4 12.8-2.4 18.7 0 6.2 0.8 12.5 2.4 18.9 1.6 6.4 4.2 12.3 7.8 17.6 3.6 5.3 8.4 9.7 14.3 13.2 5.9 3.5 13.1 5.2 21.5 5.2 8.7 0 15.9-1.7 21.7-5.2 5.8-3.5 10.5-7.9 14.1-13.2 3.6-5.3 6.2-11.2 7.8-17.6C862 412.1 862.8 405.8 862.8 399.6z"/>
    <path class="df3" d="M999.5 466.4c-9.6 0-18.8-1.7-27.5-5.2 -8.7-3.5-16.2-8.2-22.5-14.3 -6.3-6.1-11.3-13.2-15-21.3 -3.7-8.2-5.6-16.8-5.6-26 0-9.2 1.9-17.8 5.6-25.8 3.7-8 8.7-15.1 15-21.2 6.3-6.1 13.8-10.8 22.5-14.3 8.7-3.5 17.8-5.2 27.5-5.2 7.2 0 14.4 1.6 21.7 4.8 7.3 3.2 13.3 7.4 18 12.6 1-1.5 1.9-3.4 2.6-5.8 0.7-2.4 1.5-4.6 2.2-6.9h7.1v50.9h-7.4c-3-13.9-8.2-24.7-15.8-32.5 -7.6-7.8-17-11.7-28.4-11.7 -8.4 0-15.3 1.7-20.8 5.2 -5.4 3.5-9.8 7.9-13 13.4 -3.2 5.4-5.5 11.4-6.7 17.8 -1.2 6.4-1.9 12.6-1.9 18.6 0 5.9 0.6 12.1 1.9 18.6 1.2 6.4 3.5 12.4 6.7 17.8 3.2 5.4 7.5 9.9 13 13.4 5.4 3.5 12.4 5.2 20.8 5.2 9.4 0 17.5-1.8 24.3-5.4 6.8-3.6 10.2-9.7 10.2-18.4 0-6.4-1.7-11-5-13.6 -3.3-2.6-9.5-3.9-18.4-3.9v-7.1h70.2v7.1c-5.2 0-9.4 0.4-12.6 1.1 -3.2 0.7-5.6 2.2-7.2 4.5 -1.6 2.2-2.7 5.3-3.3 9.3 -0.6 4-0.9 9.3-0.9 16v11.1c-4 0-8.4 0.6-13.4 1.7 -5 1.1-10 2.4-15.2 3.9 -5.2 1.5-10.3 2.8-15.2 3.9C1007.9 465.9 1003.4 466.4 999.5 466.4z"/>
  <path class="df3" d="M1134.3 262.3H3.7c-2 0-3.7-1.6-3.7-3.7s1.6-3.7 3.7-3.7h1130.6c2 0 3.7 1.6 3.7 3.7S1136.3 262.3 1134.3 262.3z"/>
    <path class="df3" d="M255.7 535.2h2.4c2.6 0 4.8 0.2 6.3 0.5 1.2 0.2 2 0.7 2.6 1.4 0.6 0.8 1.1 2 1.3 3.6 0.3 1.9 0.5 4.6 0.5 7.9v14.8h-41.5v-14.8c0-3.3 0.1-6 0.4-8 0.2-1.6 0.6-2.8 1.3-3.6 0.6-0.7 1.4-1.1 2.6-1.4 1.6-0.3 3.7-0.5 6.3-0.5h2.4v-7.8h-41.8v7.8h2.4c2.6 0 4.8 0.2 6.3 0.5 1.2 0.2 2 0.7 2.6 1.4 0.6 0.8 1.1 2 1.3 3.6 0.3 1.9 0.4 4.6 0.4 8v44.5c0 3.3-0.1 5.9-0.4 7.9 -0.2 1.7-0.7 2.9-1.3 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h41.8v-7.8h-2.4c-2.7 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.5-1.4 -0.6-0.8-1.1-2.1-1.3-3.8 -0.3-2-0.4-4.6-0.4-7.9v-19.7h41.5v19.7c0 3.2-0.2 5.9-0.5 7.8 -0.3 1.7-0.7 3-1.4 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h42.1v-7.8h-2.4c-2.7 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.6-1.5 -0.7-0.8-1.1-2.1-1.3-3.7 -0.3-2-0.4-4.6-0.4-7.9v-44.5c0-3.3 0.1-6 0.4-8 0.2-1.6 0.6-2.8 1.3-3.5 0.6-0.7 1.5-1.2 2.7-1.4 1.6-0.3 3.7-0.5 6.3-0.5h2.4v-7.8h-42.1V535.2z"/>
    <path class="df3" d="M455.8 538.5c-4.3-4.1-9.4-7.4-15.2-9.8 -5.8-2.4-12.1-3.6-18.7-3.6 -6.6 0-12.9 1.2-18.7 3.6 -5.8 2.4-11 5.7-15.3 9.8 -4.3 4.1-7.8 9-10.3 14.6 -2.6 5.6-3.8 11.6-3.8 17.8 0 6.3 1.3 12.3 3.8 17.8 2.5 5.6 6 10.5 10.3 14.6 4.3 4.1 9.4 7.4 15.3 9.8 5.8 2.4 12.1 3.6 18.7 3.6 6.6 0 12.9-1.2 18.7-3.6 5.8-2.4 10.9-5.7 15.2-9.8 4.3-4.1 7.8-9 10.3-14.6 2.6-5.6 3.8-11.6 3.8-17.8 0-6.2-1.3-12.3-3.8-17.8C463.6 547.5 460.1 542.6 455.8 538.5zM421.9 606.4c-5.5 0-10.2-1.1-13.9-3.2 -3.8-2.2-7-5-9.3-8.3 -2.4-3.4-4.2-7.2-5.3-11.5 -1.1-4.3-1.7-8.5-1.7-12.4 0-4.1 0.6-8.3 1.7-12.5 1.1-4.2 2.9-8 5.3-11.5 2.4-3.4 5.5-6.2 9.3-8.4 3.8-2.1 8.4-3.2 13.9-3.2 5.5 0 10.1 1.1 13.8 3.2 3.8 2.2 6.9 5 9.3 8.4 2.4 3.4 4.2 7.3 5.3 11.5 1.1 4.3 1.7 8.5 1.7 12.5 0 4-0.6 8.2-1.7 12.4 -1.1 4.2-2.9 8.1-5.3 11.5 -2.4 3.3-5.5 6.2-9.3 8.3C432.1 605.3 427.4 606.4 421.9 606.4z"/>
    <path class="df3" d="M600.8 590.4l-27.4-63h-27.3v7.8h2.4c2.6 0 4.8 0.2 6.3 0.5 1.2 0.2 2 0.7 2.6 1.4 0.6 0.8 1.1 2 1.3 3.6 0.3 1.9 0.4 4.6 0.4 8v44.5c0 3.3-0.1 5.9-0.4 7.9 -0.2 1.7-0.7 2.9-1.3 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h36.1v-7.8h-2.4c-2.7 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.5-1.4 -0.7-0.8-1.1-2.1-1.4-3.8 -0.3-2-0.5-4.6-0.5-7.8v-37l26.3 60.6h5l24.4-56.5v32.8c0 3.2-0.1 5.9-0.4 7.9 -0.2 1.7-0.7 2.9-1.3 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h41.8v-7.8h-2.4c-2.6 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.6-1.5 -0.7-0.8-1.1-2.1-1.3-3.7 -0.3-2-0.4-4.6-0.4-7.9v-44.5c0-3.3 0.1-6 0.4-8 0.2-1.6 0.6-2.8 1.3-3.5 0.6-0.7 1.5-1.2 2.7-1.4 1.6-0.3 3.7-0.5 6.3-0.5h2.4v-7.8h-25.5L600.8 590.4z"/>
    <path class="df3" d="M792.1 595.9c-0.4 1.7-0.9 3.2-1.3 4.4 -0.3 0.9-0.9 1.6-1.6 2.1 -0.8 0.6-2 1.1-3.5 1.4 -1.7 0.4-4.1 0.6-7.1 0.6h-11.8c-2.7 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.6-1.4 -0.6-0.8-1-2-1.3-3.6 -0.3-2-0.4-4.6-0.4-7.9v-17.5h4.4c3.3 0 6 0.1 8 0.4 1.6 0.2 2.8 0.6 3.5 1.3 0.7 0.6 1.2 1.5 1.4 2.6 0.3 1.5 0.5 3.7 0.5 6.4v2.4h7.8v-36.2h-7.8v2.4c0 2.6-0.2 4.8-0.5 6.3 -0.3 1.2-0.7 2.1-1.4 2.7 -0.8 0.7-2 1.1-3.5 1.3 -1.9 0.3-4.6 0.4-8 0.4h-4.4v-26.2H774c3 0 5.3 0.2 7 0.6 1.5 0.3 2.6 0.8 3.4 1.4 0.8 0.6 1.3 1.3 1.7 2.2 0.5 1.1 1 2.6 1.4 4.3l1.1 4.6h7.4l-1.6-23h-67.1v7.8h2.4c2.6 0 4.8 0.2 6.3 0.5 1.2 0.2 2 0.7 2.6 1.4 0.6 0.8 1.1 2 1.3 3.6 0.3 1.9 0.4 4.6 0.4 8v44.5c0 3.3-0.1 5.9-0.4 7.9 -0.2 1.7-0.7 2.9-1.3 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h71.8l1.4-23.1h-7.4L792.1 595.9z"/>
    <path class="df3" d="M934.4 577.2c-2.4-4.1-6.5-7.6-12.1-10.5l-13-6.7c-2.9-1.5-5.5-2.9-7.5-4.2 -1.9-1.2-3.5-2.4-4.7-3.5 -1.1-1.1-1.9-2.2-2.4-3.4 -0.5-1.1-0.7-2.3-0.7-3.7 0-2.8 1-5 3-6.9 2.1-1.9 4.6-2.8 7.8-2.8 3.6 0 6.5 0.6 8.6 1.9 2.2 1.3 4 2.9 5.4 4.9 1.4 2.1 2.5 4.4 3.1 6.9 0.7 2.7 1.1 5.4 1.4 8l0.2 2.2h7.5v-35h-7.1l-0.5 1.8c-0.3 1.2-0.7 2.1-1.2 2.9 -0.2 0.3-0.4 0.6-0.7 0.8 -1.7-1.1-3.6-2.2-5.5-3 -2.8-1.2-6.4-1.9-10.8-1.9 -3.6 0-6.9 0.6-10 1.7 -3.1 1.2-5.8 2.8-8 4.9 -2.2 2.1-4 4.5-5.3 7.3 -1.3 2.8-1.9 5.8-1.9 9 0 2.8 0.5 5.6 1.4 8.1 0.9 2.6 2.1 4.9 3.7 7 1.5 2.1 3.3 3.9 5.3 5.6 2 1.6 4 3 6.2 4.1l13.1 6.7c4.5 2.3 8.2 4.8 10.9 7.3 2.4 2.2 3.5 4.5 3.5 7 0 1.8-0.4 3.5-1.1 5 -0.7 1.5-1.7 2.8-3 4 -1.3 1.2-2.9 2.1-4.6 2.7 -1.8 0.6-3.8 1-6 1 -4.5 0-8.3-0.8-11.1-2.3 -2.9-1.5-5.1-3.3-6.6-5.4 -1.6-2.1-2.7-4.4-3.5-6.6 -0.8-2.3-1.2-4.2-1.3-5.6 -0.1-1.4-0.2-2.2-0.2-2.3v-2.4h-7.8v35h7.1l0.4-1.9c0.3-1.2 0.7-2.2 1.2-3 0.3-0.5 0.7-0.9 1-1.3 0.6 0.2 1.2 0.5 1.9 0.9 1.4 0.7 3.1 1.4 4.9 2.2 1.9 0.8 3.9 1.5 6.2 2.1 2.3 0.6 4.9 1 7.7 1 3.8 0 7.5-0.7 10.9-1.9 3.4-1.3 6.5-3.1 9.1-5.5 2.6-2.3 4.7-5.2 6.3-8.5 1.6-3.3 2.4-7.1 2.4-11.2C938 585.4 936.8 581.2 934.4 577.2z"/>
</svg>`

const LightFlat = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1137.9 616.8">
<style type="text/css">

.lf0{fill:none;}
.lf1{fill:#962A1F;}
.lf2{fill:#FFFFFF;}

</style>
  <path class="lf0" d="M346.2 205.6c-1 3.3-1.9 5.8-2.5 8.3 -3.6 14.1-6.4 28.4-8.4 42.9 -0.1 0.5-0.1 1.1-0.2 1.6H378c-3.7-9.6-8.2-19-13.7-28.1C359.2 221.8 353.7 213.6 346.2 205.6z"/>
  <path class="lf0" d="M283.2 164.7c2.7-4.7 2.5-5.1-3.1-6.4 -7.3-1.6-14.6-3.3-22.1-4.3 -27-3.7-53.7-2.7-79.6 5.7 -41.3 13.4-70.8 40.7-90.6 78.1 -3.6 6.8-6.7 13.6-9.5 20.6h172.3c1-7 2.2-14 3.9-20.9C260.6 211.9 269.9 187.6 283.2 164.7z"/>
  <path class="lf0" d="M1011.8 189.4c-8.5-7.3-17.5-13.8-27.4-19.2 -23.6-12.9-49.2-18-78.4-17.9 -5.9-0.6-14.1 0.4-22.3 1.2 -7.6 0.8-15.2 2.5-22.7 4 -3 0.6-4.6 1.3-2.4 5.2 8.1 14.3 15.3 28.9 20.7 44.4 5.8 16.8 10.2 33.9 13 51.3h167.1C1049.3 232.1 1034.3 208.6 1011.8 189.4z"/>
  <path class="lf0" d="M769.7 166c3-2 3.8-3.6 2-7.1 -15.7-29.9-35.4-56.5-62.4-77.6 -14.1-11-29-20.8-45.6-27.9 -29.7-12.7-61-17.3-94.8-16.8 -14.8-0.8-30.9 1.2-46.9 4.1 -39.7 7.2-73.3 25.9-102.3 52.8 -20.5 19-36.2 41.4-48.4 66.2 -3.1 6.3-3.2 6.3 2.7 10.2 8.2 5.5 15.9 11.6 23.1 18.2 21.2 19.4 37.8 42.1 50.4 67.8 0.4 0.8 0.8 1.6 1.1 2.4h240.9C707.4 220.5 734.1 189.5 769.7 166z"/>
  <path class="lf0" d="M781 201.6c-2.9 3-5.5 5.4-7.6 8 -12 15-21.2 31.3-28 48.8h48.2c-1.2-9.9-3.2-19.7-5.2-29.5C786.5 219.9 784.5 211 781 201.6z"/>
    <path class="lf1" d="M87.8 237.8c19.9-37.4 49.4-64.6 90.6-78.1 25.8-8.4 52.6-9.4 79.6-5.7 7.5 1 14.8 2.7 22.1 4.3 5.6 1.2 5.8 1.7 3.1 6.4 -13.3 22.9-22.7 47.2-28.7 72.8 -1.6 6.9-2.9 13.9-3.9 20.9h84.4c0.1-0.5 0.1-1.1 0.2-1.6 2-14.5 4.8-28.7 8.4-42.9 0.6-2.5 1.5-5 2.5-8.3 7.5 8 13 16.2 18.1 24.7 5.4 9.1 9.9 18.5 13.7 28.1h70.5c-0.4-0.8-0.8-1.6-1.1-2.4 -12.6-25.7-29.2-48.3-50.4-67.8 -7.2-6.6-14.9-12.7-23.1-18.2 -5.9-3.9-5.8-3.9-2.7-10.2 12.2-24.8 27.9-47.3 48.4-66.2 29-26.9 62.6-45.6 102.3-52.8 16-2.9 32.2-4.9 46.9-4.1 33.8-0.5 65.1 4.2 94.8 16.8 16.6 7.1 31.5 16.9 45.6 27.9 27 21.1 46.8 47.7 62.4 77.6 1.8 3.5 1.1 5-2 7.1 -35.6 23.5-62.3 54.4-80.4 92.4h56.1c6.8-17.5 16-33.8 28-48.8 2.1-2.7 4.7-5 7.6-8 3.5 9.4 5.5 18.4 7.3 27.3 2 9.8 4.1 19.6 5.2 29.5h98.7c-2.7-17.4-7.2-34.5-13-51.3 -5.3-15.5-12.6-30.1-20.7-44.4 -2.2-3.9-0.5-4.6 2.4-5.2 7.5-1.5 15.1-3.2 22.7-4 8.2-0.9 16.4-1.9 22.3-1.2 29.2-0.1 54.8 5 78.4 17.9 9.8 5.4 18.9 11.9 27.4 19.2 22.4 19.2 37.5 42.8 47.6 69h63.4c-3.5-7.4-7.4-14.6-11.7-21.6 -14.8-24.1-33.4-44.9-56.1-62 -17.1-12.9-35.8-23.3-55.8-31.5 -25.3-10.4-51.7-16.2-79-17.4 -11.5-0.5-23.1-0.5-34.8 0.6 -13.7 1.3-27.3 3.1-40.5 6.7 -3 0.8-5.1 0.4-6.9-2.3 -14-19.5-30.5-36.6-48.7-52.4 -18.6-16.1-39.1-29.5-60.9-40.9 -27.7-14.4-56.9-25-87.7-31.2 -17.6-3.6-35.3-6-53.3-6.2 -14.8-0.2-29.6-0.6-44.4 0.7 -12.2 1.1-24.4 2.4-36.3 4.7 -24.9 4.8-49 12.4-72.3 22.6 -35 15.3-66.4 35.8-94.1 61.8 -13.4 12.6-25.6 26.5-36.2 41.6 -1.8 2.5-3.5 3.4-6.7 2.5 -15.8-4.6-32.1-7.2-48.4-8 -12.9-0.7-25.8-0.5-38.8 0.5 -22.2 1.8-43.7 6.3-64.5 14.1 -43.9 16.5-80.6 42.4-108.2 80 -8.9 12.1-16.5 24.7-22.8 37.8h63.3C81.1 251.4 84.2 244.6 87.8 237.8z"/>
    <path class="lf2" d="M2.6 288.7c-0.2 0.6-0.4 1.2-0.6 1.8h0.6V288.7z"/>
    <path class="lf2" d="M165.6 417.1c0 7.7-1.4 14.6-4.3 20.8 -2.8 6.2-6.7 11.5-11.5 16 -4.8 4.5-10.5 7.9-16.9 10.4 -6.4 2.5-13.2 3.7-20.4 3.7 -9.6 0-18.1-1.7-25.4-5.2 -7.3-3.5-12.7-6.3-16.2-8.5 -1.2 1.2-2.6 3-4.1 5.4 -1.5 2.4-2.5 5.1-3 8.4h-6.7v-63.9h7.4c1.5 15.8 6.8 28 16 36.6 9.2 8.5 20.8 12.8 34.9 12.8 7.9 0 14.7-2.2 20.2-6.5 5.6-4.3 8.4-10.5 8.4-18.4 0-6.4-2.5-12.3-7.6-17.6 -5.1-5.3-12.7-10.5-22.8-15.4l-25.2-13c-8.9-4.7-16.2-10.8-21.9-18.4 -5.7-7.5-8.5-16.5-8.5-26.9 0-5.9 1.2-11.4 3.5-16.5 2.4-5.1 5.6-9.5 9.7-13.4 4.1-3.8 9-6.9 14.7-9.1 5.7-2.2 11.9-3.3 18.6-3.3 7.7 0 14.4 1.2 20.2 3.5 5.8 2.4 10.7 4.9 14.7 7.6 1.2-1.2 2.4-2.7 3.5-4.5 1.1-1.7 1.9-4.1 2.4-7.1h7.1v62.7h-7.4c-0.5-5.7-1.5-11.4-3.2-17.1 -1.6-5.7-4-10.9-7.1-15.6 -3.1-4.7-7-8.5-11.7-11.5 -4.7-3-10.4-4.5-17.1-4.5 -7.2 0-13.4 1.9-18.8 5.6 -5.3 3.7-8 9-8 16 0 6.4 2.5 11.9 7.6 16.3 5.1 4.5 13.3 9.7 24.7 15.6l24.9 13c10.6 5.4 18.2 12 22.6 19.7C163.3 402.5 165.6 409.9 165.6 417.1z"/>
    <path class="lf2" d="M340.8 346.1c-3.2 0.7-5.6 2.2-7.2 4.5 -1.6 2.2-2.7 5.3-3.3 9.3 -0.6 4-0.9 9.3-0.9 16v36.4c0 8.9-1.4 16.7-4.1 23.4 -2.7 6.7-6.5 12.3-11.3 16.7 -4.8 4.5-10.5 7.9-17.1 10.2 -6.6 2.3-13.7 3.5-21.3 3.5 -7.7 0-14.9-1.2-21.7-3.5 -6.8-2.4-12.8-5.8-18-10.2 -5.2-4.5-9.3-10-12.3-16.7 -3-6.7-4.5-14.5-4.5-23.4v-36.4c0-6.7-0.3-12-0.9-16 -0.6-4-1.7-7.1-3.3-9.3 -1.6-2.2-4-3.7-7.2-4.5 -3.2-0.7-7.4-1.1-12.6-1.1V338H268v7.1c-5.2 0-9.3 0.4-12.4 1.1 -3.1 0.7-5.5 2.2-7.2 4.5 -1.7 2.2-2.9 5.3-3.5 9.3 -0.6 4-0.9 9.3-0.9 16v38.2c0 12.4 3.3 21.5 9.8 27.5 6.6 5.9 15 8.9 25.4 8.9 10.4 0 19-3 25.8-8.9 6.8-5.9 10.2-15.1 10.2-27.5v-38.2c0-6.7-0.3-12-0.7-16 -0.5-4-1.6-7.1-3.3-9.3 -1.7-2.2-4.2-3.7-7.2-4.5 -3.1-0.7-7.2-1.1-12.4-1.1V338h62v7.1C348.2 345 344 345.4 340.8 346.1z"/>
    <path class="lf2" d="M520.5 346.1c-3.2 0.7-5.6 2.2-7.2 4.5 -1.6 2.2-2.7 5.3-3.3 9.3 -0.6 4-0.9 9.3-0.9 16v90.2h-7.8L414.7 374v49.4c0 6.7 0.3 12.1 0.9 16.1 0.6 4.1 1.7 7.2 3.3 9.5 1.6 2.2 4 3.7 7.2 4.5 3.2 0.7 7.4 1.1 12.6 1.1v7.1h-59.8v-7.1c5.2 0 9.4-0.4 12.6-1.1 3.2-0.7 5.6-2.2 7.2-4.5 1.6-2.2 2.7-5.4 3.3-9.5 0.6-4.1 0.9-9.5 0.9-16.1v-47.5c0-6.7-0.3-12-0.9-16 -0.6-4-1.7-7.1-3.3-9.3 -1.6-2.2-4-3.7-7.2-4.5 -3.2-0.7-7.4-1.1-12.6-1.1V338h36.8l81.7 86.5v-48.6c0-6.7-0.3-12-0.9-16 -0.6-4-1.7-7.1-3.3-9.3 -1.6-2.2-4-3.7-7.2-4.5 -3.2-0.7-7.4-1.1-12.6-1.1V338h59.8v7.1C527.9 345 523.7 345.4 520.5 346.1z"/>
    <path class="lf2" d="M562.5 454.6c5.2 0 9.4-0.4 12.6-1.1 3.2-0.7 5.6-2.2 7.2-4.5 1.6-2.2 2.7-5.4 3.3-9.5 0.6-4.1 0.9-9.5 0.9-16.1v-47.5c0-6.7-0.3-12-0.9-16 -0.6-4-1.7-7.1-3.3-9.3 -1.6-2.2-4-3.7-7.2-4.5 -3.2-0.7-7.4-1.1-12.6-1.1V338h66.5c13.4 0 24.8 1.6 34.3 4.8 9.5 3.2 17.4 7.6 23.6 13.2 6.2 5.6 10.8 12.1 13.7 19.7 3 7.6 4.5 15.5 4.5 23.9 0 8.4-1.5 16.4-4.5 23.9 -3 7.6-7.6 14.1-13.7 19.7 -6.2 5.6-14 10-23.6 13.4 -9.5 3.3-21 5-34.3 5h-66.5V454.6zM675.7 399.2c0-5.4-0.6-11.1-1.7-17.1 -1.1-5.9-3.3-11.3-6.7-16.2 -3.3-4.8-8-8.8-13.9-12.1 -5.9-3.2-13.7-4.8-23.4-4.8h-17.8v80.6c0 8.4 0.9 13.9 2.6 16.5 1.7 2.6 5.9 3.9 12.6 3.9h2.6c9.7 0 17.4-1.6 23.4-4.8 5.9-3.2 10.6-7.3 13.9-12.3 3.3-4.9 5.6-10.4 6.7-16.3C675.2 410.8 675.7 404.9 675.7 399.2z"/>
    <path class="lf2" d="M891.1 399.6c0 9.2-2 17.8-5.9 26 -4 8.2-9.3 15.3-16 21.3 -6.7 6.1-14.5 10.8-23.6 14.3 -9 3.5-18.6 5.2-28.8 5.2 -10.2 0-19.7-1.7-28.8-5.2 -9-3.5-16.9-8.2-23.6-14.3 -6.7-6.1-12-13.2-16-21.3 -4-8.2-5.9-16.8-5.9-26 0-9.2 2-17.8 5.9-25.8 4-8 9.3-15.1 16-21.2 6.7-6.1 14.5-10.8 23.6-14.3 9-3.5 18.6-5.2 28.8-5.2 10.1 0 19.7 1.7 28.8 5.2 9 3.5 16.9 8.2 23.6 14.3 6.7 6.1 12 13.1 16 21.2C889.1 381.9 891.1 390.5 891.1 399.6zM862.8 399.6c0-5.9-0.8-12.2-2.4-18.7 -1.6-6.6-4.2-12.5-7.8-17.8 -3.6-5.3-8.3-9.7-14.1-13.2 -5.8-3.5-13.1-5.2-21.7-5.2 -8.4 0-15.6 1.7-21.5 5.2 -5.9 3.5-10.7 7.9-14.3 13.2 -3.6 5.3-6.2 11.3-7.8 17.8 -1.6 6.6-2.4 12.8-2.4 18.7 0 6.2 0.8 12.5 2.4 18.9 1.6 6.4 4.2 12.3 7.8 17.6 3.6 5.3 8.4 9.7 14.3 13.2 5.9 3.5 13.1 5.2 21.5 5.2 8.7 0 15.9-1.7 21.7-5.2 5.8-3.5 10.5-7.9 14.1-13.2 3.6-5.3 6.2-11.2 7.8-17.6C862 412.1 862.8 405.8 862.8 399.6z"/>
    <path class="lf2" d="M999.5 466.4c-9.6 0-18.8-1.7-27.5-5.2 -8.7-3.5-16.2-8.2-22.5-14.3 -6.3-6.1-11.3-13.2-15-21.3 -3.7-8.2-5.6-16.8-5.6-26 0-9.2 1.9-17.8 5.6-25.8 3.7-8 8.7-15.1 15-21.2 6.3-6.1 13.8-10.8 22.5-14.3 8.7-3.5 17.8-5.2 27.5-5.2 7.2 0 14.4 1.6 21.7 4.8 7.3 3.2 13.3 7.4 18 12.6 1-1.5 1.9-3.4 2.6-5.8 0.7-2.4 1.5-4.6 2.2-6.9h7.1v50.9h-7.4c-3-13.9-8.2-24.7-15.8-32.5 -7.6-7.8-17-11.7-28.4-11.7 -8.4 0-15.3 1.7-20.8 5.2 -5.4 3.5-9.8 7.9-13 13.4 -3.2 5.4-5.5 11.4-6.7 17.8 -1.2 6.4-1.9 12.6-1.9 18.6 0 5.9 0.6 12.1 1.9 18.6 1.2 6.4 3.5 12.4 6.7 17.8 3.2 5.4 7.5 9.9 13 13.4 5.4 3.5 12.4 5.2 20.8 5.2 9.4 0 17.5-1.8 24.3-5.4 6.8-3.6 10.2-9.7 10.2-18.4 0-6.4-1.7-11-5-13.6 -3.3-2.6-9.5-3.9-18.4-3.9v-7.1h70.2v7.1c-5.2 0-9.4 0.4-12.6 1.1 -3.2 0.7-5.6 2.2-7.2 4.5 -1.6 2.2-2.7 5.3-3.3 9.3 -0.6 4-0.9 9.3-0.9 16v11.1c-4 0-8.4 0.6-13.4 1.7 -5 1.1-10 2.4-15.2 3.9 -5.2 1.5-10.3 2.8-15.2 3.9C1007.9 465.9 1003.4 466.4 999.5 466.4z"/>
  <path class="lf2" d="M1134.3 262.3H3.7c-2 0-3.7-1.6-3.7-3.7s1.6-3.7 3.7-3.7h1130.6c2 0 3.7 1.6 3.7 3.7S1136.3 262.3 1134.3 262.3z"/>
    <path class="lf2" d="M255.7 535.2h2.4c2.6 0 4.8 0.2 6.3 0.5 1.2 0.2 2 0.7 2.6 1.4 0.6 0.8 1.1 2 1.3 3.6 0.3 1.9 0.5 4.6 0.5 7.9v14.8h-41.5v-14.8c0-3.3 0.1-6 0.4-8 0.2-1.6 0.6-2.8 1.3-3.6 0.6-0.7 1.4-1.1 2.6-1.4 1.6-0.3 3.7-0.5 6.3-0.5h2.4v-7.8h-41.8v7.8h2.4c2.6 0 4.8 0.2 6.3 0.5 1.2 0.2 2 0.7 2.6 1.4 0.6 0.8 1.1 2 1.3 3.6 0.3 1.9 0.4 4.6 0.4 8v44.5c0 3.3-0.1 5.9-0.4 7.9 -0.2 1.7-0.7 2.9-1.3 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h41.8v-7.8h-2.4c-2.7 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.5-1.4 -0.6-0.8-1.1-2.1-1.3-3.8 -0.3-2-0.4-4.6-0.4-7.9v-19.7h41.5v19.7c0 3.2-0.2 5.9-0.5 7.8 -0.3 1.7-0.7 3-1.4 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h42.1v-7.8h-2.4c-2.7 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.6-1.5 -0.7-0.8-1.1-2.1-1.3-3.7 -0.3-2-0.4-4.6-0.4-7.9v-44.5c0-3.3 0.1-6 0.4-8 0.2-1.6 0.6-2.8 1.3-3.5 0.6-0.7 1.5-1.2 2.7-1.4 1.6-0.3 3.7-0.5 6.3-0.5h2.4v-7.8h-42.1V535.2z"/>
    <path class="lf2" d="M455.8 538.5c-4.3-4.1-9.4-7.4-15.2-9.8 -5.8-2.4-12.1-3.6-18.7-3.6 -6.6 0-12.9 1.2-18.7 3.6 -5.8 2.4-11 5.7-15.3 9.8 -4.3 4.1-7.8 9-10.3 14.6 -2.6 5.6-3.8 11.6-3.8 17.8 0 6.3 1.3 12.3 3.8 17.8 2.5 5.6 6 10.5 10.3 14.6 4.3 4.1 9.4 7.4 15.3 9.8 5.8 2.4 12.1 3.6 18.7 3.6 6.6 0 12.9-1.2 18.7-3.6 5.8-2.4 10.9-5.7 15.2-9.8 4.3-4.1 7.8-9 10.3-14.6 2.6-5.6 3.8-11.6 3.8-17.8 0-6.2-1.3-12.3-3.8-17.8C463.6 547.5 460.1 542.6 455.8 538.5zM421.9 606.4c-5.5 0-10.2-1.1-13.9-3.2 -3.8-2.2-7-5-9.3-8.3 -2.4-3.4-4.2-7.2-5.3-11.5 -1.1-4.3-1.7-8.5-1.7-12.4 0-4.1 0.6-8.3 1.7-12.5 1.1-4.2 2.9-8 5.3-11.5 2.4-3.4 5.5-6.2 9.3-8.4 3.8-2.1 8.4-3.2 13.9-3.2 5.5 0 10.1 1.1 13.8 3.2 3.8 2.2 6.9 5 9.3 8.4 2.4 3.4 4.2 7.3 5.3 11.5 1.1 4.3 1.7 8.5 1.7 12.5 0 4-0.6 8.2-1.7 12.4 -1.1 4.2-2.9 8.1-5.3 11.5 -2.4 3.3-5.5 6.2-9.3 8.3C432.1 605.3 427.4 606.4 421.9 606.4z"/>
    <path class="lf2" d="M600.8 590.4l-27.4-63h-27.3v7.8h2.4c2.6 0 4.8 0.2 6.3 0.5 1.2 0.2 2 0.7 2.6 1.4 0.6 0.8 1.1 2 1.3 3.6 0.3 1.9 0.4 4.6 0.4 8v44.5c0 3.3-0.1 5.9-0.4 7.9 -0.2 1.7-0.7 2.9-1.3 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h36.1v-7.8h-2.4c-2.7 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.5-1.4 -0.7-0.8-1.1-2.1-1.4-3.8 -0.3-2-0.5-4.6-0.5-7.8v-37l26.3 60.6h5l24.4-56.5v32.8c0 3.2-0.1 5.9-0.4 7.9 -0.2 1.7-0.7 2.9-1.3 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h41.8v-7.8h-2.4c-2.6 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.6-1.5 -0.7-0.8-1.1-2.1-1.3-3.7 -0.3-2-0.4-4.6-0.4-7.9v-44.5c0-3.3 0.1-6 0.4-8 0.2-1.6 0.6-2.8 1.3-3.5 0.6-0.7 1.5-1.2 2.7-1.4 1.6-0.3 3.7-0.5 6.3-0.5h2.4v-7.8h-25.5L600.8 590.4z"/>
    <path class="lf2" d="M792.1 595.9c-0.4 1.7-0.9 3.2-1.3 4.4 -0.3 0.9-0.9 1.6-1.6 2.1 -0.8 0.6-2 1.1-3.5 1.4 -1.7 0.4-4.1 0.6-7.1 0.6h-11.8c-2.7 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.6-1.4 -0.6-0.8-1-2-1.3-3.6 -0.3-2-0.4-4.6-0.4-7.9v-17.5h4.4c3.3 0 6 0.1 8 0.4 1.6 0.2 2.8 0.6 3.5 1.3 0.7 0.6 1.2 1.5 1.4 2.6 0.3 1.5 0.5 3.7 0.5 6.4v2.4h7.8v-36.2h-7.8v2.4c0 2.6-0.2 4.8-0.5 6.3 -0.3 1.2-0.7 2.1-1.4 2.7 -0.8 0.7-2 1.1-3.5 1.3 -1.9 0.3-4.6 0.4-8 0.4h-4.4v-26.2H774c3 0 5.3 0.2 7 0.6 1.5 0.3 2.6 0.8 3.4 1.4 0.8 0.6 1.3 1.3 1.7 2.2 0.5 1.1 1 2.6 1.4 4.3l1.1 4.6h7.4l-1.6-23h-67.1v7.8h2.4c2.6 0 4.8 0.2 6.3 0.5 1.2 0.2 2 0.7 2.6 1.4 0.6 0.8 1.1 2 1.3 3.6 0.3 1.9 0.4 4.6 0.4 8v44.5c0 3.3-0.1 5.9-0.4 7.9 -0.2 1.7-0.7 2.9-1.3 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h71.8l1.4-23.1h-7.4L792.1 595.9z"/>
    <path class="lf2" d="M934.4 577.2c-2.4-4.1-6.5-7.6-12.1-10.5l-13-6.7c-2.9-1.5-5.5-2.9-7.5-4.2 -1.9-1.2-3.5-2.4-4.7-3.5 -1.1-1.1-1.9-2.2-2.4-3.4 -0.5-1.1-0.7-2.3-0.7-3.7 0-2.8 1-5 3-6.9 2.1-1.9 4.6-2.8 7.8-2.8 3.6 0 6.5 0.6 8.6 1.9 2.2 1.3 4 2.9 5.4 4.9 1.4 2.1 2.5 4.4 3.1 6.9 0.7 2.7 1.1 5.4 1.4 8l0.2 2.2h7.5v-35h-7.1l-0.5 1.8c-0.3 1.2-0.7 2.1-1.2 2.9 -0.2 0.3-0.4 0.6-0.7 0.8 -1.7-1.1-3.6-2.2-5.5-3 -2.8-1.2-6.4-1.9-10.8-1.9 -3.6 0-6.9 0.6-10 1.7 -3.1 1.2-5.8 2.8-8 4.9 -2.2 2.1-4 4.5-5.3 7.3 -1.3 2.8-1.9 5.8-1.9 9 0 2.8 0.5 5.6 1.4 8.1 0.9 2.6 2.1 4.9 3.7 7 1.5 2.1 3.3 3.9 5.3 5.6 2 1.6 4 3 6.2 4.1l13.1 6.7c4.5 2.3 8.2 4.8 10.9 7.3 2.4 2.2 3.5 4.5 3.5 7 0 1.8-0.4 3.5-1.1 5 -0.7 1.5-1.7 2.8-3 4 -1.3 1.2-2.9 2.1-4.6 2.7 -1.8 0.6-3.8 1-6 1 -4.5 0-8.3-0.8-11.1-2.3 -2.9-1.5-5.1-3.3-6.6-5.4 -1.6-2.1-2.7-4.4-3.5-6.6 -0.8-2.3-1.2-4.2-1.3-5.6 -0.1-1.4-0.2-2.2-0.2-2.3v-2.4h-7.8v35h7.1l0.4-1.9c0.3-1.2 0.7-2.2 1.2-3 0.3-0.5 0.7-0.9 1-1.3 0.6 0.2 1.2 0.5 1.9 0.9 1.4 0.7 3.1 1.4 4.9 2.2 1.9 0.8 3.9 1.5 6.2 2.1 2.3 0.6 4.9 1 7.7 1 3.8 0 7.5-0.7 10.9-1.9 3.4-1.3 6.5-3.1 9.1-5.5 2.6-2.3 4.7-5.2 6.3-8.5 1.6-3.3 2.4-7.1 2.4-11.2C938 585.4 936.8 581.2 934.4 577.2z"/>
</svg>`

const DarkTextured = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1137.9 616.8">
<style>
  .a{
    stop-color:#58595B;
  }
  .b{
    stop-color:#414042;
  }
</style>
  <style type="text/css">

	.dt0{fill:url(#SVGID_1_);}
	.dt1{fill:url(#SVGID_2_);}
	.dt2{fill:url(#SVGID_3_);}
	.dt3{fill:url(#SVGID_4_);}
	.dt4{fill:url(#SVGID_5_);}
	.dt5{fill:url(#SVGID_6_);}
	.dt6{fill:url(#SVGID_7_);}
	.dt7{fill:#58595B;}
	.dt8{fill:none;}
	.dt9{fill:url(#SVGID_8_);}

  </style>
      <linearGradient gradientUnits="userSpaceOnUse" x1="2.3" y1="288.8" x2="2.3" y2="290.4">
        <stop offset="9.183674e-02" stop-color="#005D81"/>
        <stop offset="0.8673" stop-color="#003D58"/>
      </linearGradient>
      <path class="dt0" d="M2.6 288.7c-0.2 0.6-0.4 1.2-0.6 1.8h0.6V288.7z"/>
      <linearGradient gradientUnits="userSpaceOnUse" x1="111.4" y1="276.9" x2="111.4" y2="464.5">
        <stop offset="9.183674e-02" class="a"/>
        <stop offset="0.8673" class="b"/>
      </linearGradient>
      <path class="dt1" d="M165.6 417.1c0 7.7-1.4 14.6-4.3 20.8 -2.8 6.2-6.7 11.5-11.5 16 -4.8 4.5-10.5 7.9-16.9 10.4 -6.4 2.5-13.2 3.7-20.4 3.7 -9.6 0-18.1-1.7-25.4-5.2 -7.3-3.5-12.7-6.3-16.2-8.5 -1.2 1.2-2.6 3-4.1 5.4 -1.5 2.4-2.5 5.1-3 8.4h-6.7v-63.9h7.4c1.5 15.8 6.8 28 16 36.6 9.2 8.5 20.8 12.8 34.9 12.8 7.9 0 14.7-2.2 20.2-6.5 5.6-4.3 8.4-10.5 8.4-18.4 0-6.4-2.5-12.3-7.6-17.6 -5.1-5.3-12.7-10.5-22.8-15.4l-25.2-13c-8.9-4.7-16.2-10.8-21.9-18.4 -5.7-7.5-8.5-16.5-8.5-26.9 0-5.9 1.2-11.4 3.5-16.5 2.4-5.1 5.6-9.5 9.7-13.4 4.1-3.8 9-6.9 14.7-9.1 5.7-2.2 11.9-3.3 18.6-3.3 7.7 0 14.4 1.2 20.2 3.5 5.8 2.4 10.7 4.9 14.7 7.6 1.2-1.2 2.4-2.7 3.5-4.5 1.1-1.7 1.9-4.1 2.4-7.1h7.1v62.7h-7.4c-0.5-5.7-1.5-11.4-3.2-17.1 -1.6-5.7-4-10.9-7.1-15.6 -3.1-4.7-7-8.5-11.7-11.5 -4.7-3-10.4-4.5-17.1-4.5 -7.2 0-13.4 1.9-18.8 5.6 -5.3 3.7-8 9-8 16 0 6.4 2.5 11.9 7.6 16.3 5.1 4.5 13.3 9.7 24.7 15.6l24.9 13c10.6 5.4 18.2 12 22.6 19.7C163.3 402.5 165.6 409.9 165.6 417.1z"/>
      <linearGradient gradientUnits="userSpaceOnUse" x1="274.2" y1="276.9" x2="274.2" y2="464.5">
        <stop offset="9.183674e-02" class="a"/>
        <stop offset="0.8673" class="b"/>
      </linearGradient>
      <path class="dt2" d="M340.8 346.1c-3.2 0.7-5.6 2.2-7.2 4.5 -1.6 2.2-2.7 5.3-3.3 9.3 -0.6 4-0.9 9.3-0.9 16v36.4c0 8.9-1.4 16.7-4.1 23.4 -2.7 6.7-6.5 12.3-11.3 16.7 -4.8 4.5-10.5 7.9-17.1 10.2 -6.6 2.3-13.7 3.5-21.3 3.5 -7.7 0-14.9-1.2-21.7-3.5 -6.8-2.4-12.8-5.8-18-10.2 -5.2-4.5-9.3-10-12.3-16.7 -3-6.7-4.5-14.5-4.5-23.4v-36.4c0-6.7-0.3-12-0.9-16 -0.6-4-1.7-7.1-3.3-9.3 -1.6-2.2-4-3.7-7.2-4.5 -3.2-0.7-7.4-1.1-12.6-1.1V338H268v7.1c-5.2 0-9.3 0.4-12.4 1.1 -3.1 0.7-5.5 2.2-7.2 4.5 -1.7 2.2-2.9 5.3-3.5 9.3 -0.6 4-0.9 9.3-0.9 16v38.2c0 12.4 3.3 21.5 9.8 27.5 6.6 5.9 15 8.9 25.4 8.9 10.4 0 19-3 25.8-8.9 6.8-5.9 10.2-15.1 10.2-27.5v-38.2c0-6.7-0.3-12-0.7-16 -0.5-4-1.6-7.1-3.3-9.3 -1.7-2.2-4.2-3.7-7.2-4.5 -3.1-0.7-7.2-1.1-12.4-1.1V338h62v7.1C348.2 345 344 345.4 340.8 346.1z"/>
      <linearGradient gradientUnits="userSpaceOnUse" x1="456.1" y1="276.9" x2="456.1" y2="464.5">
        <stop offset="9.183674e-02" class="a"/>
        <stop offset="0.8673" class="b"/>
      </linearGradient>
      <path class="dt3" d="M520.5 346.1c-3.2 0.7-5.6 2.2-7.2 4.5 -1.6 2.2-2.7 5.3-3.3 9.3 -0.6 4-0.9 9.3-0.9 16v90.2h-7.8L414.7 374v49.4c0 6.7 0.3 12.1 0.9 16.1 0.6 4.1 1.7 7.2 3.3 9.5 1.6 2.2 4 3.7 7.2 4.5 3.2 0.7 7.4 1.1 12.6 1.1v7.1h-59.8v-7.1c5.2 0 9.4-0.4 12.6-1.1 3.2-0.7 5.6-2.2 7.2-4.5 1.6-2.2 2.7-5.4 3.3-9.5 0.6-4.1 0.9-9.5 0.9-16.1v-47.5c0-6.7-0.3-12-0.9-16 -0.6-4-1.7-7.1-3.3-9.3 -1.6-2.2-4-3.7-7.2-4.5 -3.2-0.7-7.4-1.1-12.6-1.1V338h36.8l81.7 86.5v-48.6c0-6.7-0.3-12-0.9-16 -0.6-4-1.7-7.1-3.3-9.3 -1.6-2.2-4-3.7-7.2-4.5 -3.2-0.7-7.4-1.1-12.6-1.1V338h59.8v7.1C527.9 345 523.7 345.4 520.5 346.1z"/>
      <linearGradient gradientUnits="userSpaceOnUse" x1="633.8" y1="276.9" x2="633.8" y2="464.5">
        <stop offset="9.183674e-02" class="a"/>
        <stop offset="0.8673" class="b"/>
      </linearGradient>
      <path class="dt4" d="M562.5 454.6c5.2 0 9.4-0.4 12.6-1.1 3.2-0.7 5.6-2.2 7.2-4.5 1.6-2.2 2.7-5.4 3.3-9.5 0.6-4.1 0.9-9.5 0.9-16.1v-47.5c0-6.7-0.3-12-0.9-16 -0.6-4-1.7-7.1-3.3-9.3 -1.6-2.2-4-3.7-7.2-4.5 -3.2-0.7-7.4-1.1-12.6-1.1V338h66.5c13.4 0 24.8 1.6 34.3 4.8 9.5 3.2 17.4 7.6 23.6 13.2 6.2 5.6 10.8 12.1 13.7 19.7 3 7.6 4.5 15.5 4.5 23.9 0 8.4-1.5 16.4-4.5 23.9 -3 7.6-7.6 14.1-13.7 19.7 -6.2 5.6-14 10-23.6 13.4 -9.5 3.3-21 5-34.3 5h-66.5V454.6zM675.7 399.2c0-5.4-0.6-11.1-1.7-17.1 -1.1-5.9-3.3-11.3-6.7-16.2 -3.3-4.8-8-8.8-13.9-12.1 -5.9-3.2-13.7-4.8-23.4-4.8h-17.8v80.6c0 8.4 0.9 13.9 2.6 16.5 1.7 2.6 5.9 3.9 12.6 3.9h2.6c9.7 0 17.4-1.6 23.4-4.8 5.9-3.2 10.6-7.3 13.9-12.3 3.3-4.9 5.6-10.4 6.7-16.3C675.2 410.8 675.7 404.9 675.7 399.2z"/>
      <linearGradient gradientUnits="userSpaceOnUse" x1="816.8" y1="276.9" x2="816.8" y2="464.5">
        <stop offset="9.183674e-02" class="a"/>
        <stop offset="0.8673" class="b"/>
      </linearGradient>
      <path class="dt5" d="M891.1 399.6c0 9.2-2 17.8-5.9 26 -4 8.2-9.3 15.3-16 21.3 -6.7 6.1-14.5 10.8-23.6 14.3 -9 3.5-18.6 5.2-28.8 5.2 -10.2 0-19.7-1.7-28.8-5.2 -9-3.5-16.9-8.2-23.6-14.3 -6.7-6.1-12-13.2-16-21.3 -4-8.2-5.9-16.8-5.9-26 0-9.2 2-17.8 5.9-25.8 4-8 9.3-15.1 16-21.2 6.7-6.1 14.5-10.8 23.6-14.3 9-3.5 18.6-5.2 28.8-5.2 10.1 0 19.7 1.7 28.8 5.2 9 3.5 16.9 8.2 23.6 14.3 6.7 6.1 12 13.1 16 21.2C889.1 381.9 891.1 390.5 891.1 399.6zM862.8 399.6c0-5.9-0.8-12.2-2.4-18.7 -1.6-6.6-4.2-12.5-7.8-17.8 -3.6-5.3-8.3-9.7-14.1-13.2 -5.8-3.5-13.1-5.2-21.7-5.2 -8.4 0-15.6 1.7-21.5 5.2 -5.9 3.5-10.7 7.9-14.3 13.2 -3.6 5.3-6.2 11.3-7.8 17.8 -1.6 6.6-2.4 12.8-2.4 18.7 0 6.2 0.8 12.5 2.4 18.9 1.6 6.4 4.2 12.3 7.8 17.6 3.6 5.3 8.4 9.7 14.3 13.2 5.9 3.5 13.1 5.2 21.5 5.2 8.7 0 15.9-1.7 21.7-5.2 5.8-3.5 10.5-7.9 14.1-13.2 3.6-5.3 6.2-11.2 7.8-17.6C862 412.1 862.8 405.8 862.8 399.6z"/>
      <linearGradient gradientUnits="userSpaceOnUse" x1="1004.9" y1="276.9" x2="1004.9" y2="464.5">
        <stop offset="9.183674e-02" class="a"/>
        <stop offset="0.8673" class="b"/>
      </linearGradient>
      <path class="dt6" d="M999.5 466.4c-9.6 0-18.8-1.7-27.5-5.2 -8.7-3.5-16.2-8.2-22.5-14.3 -6.3-6.1-11.3-13.2-15-21.3 -3.7-8.2-5.6-16.8-5.6-26 0-9.2 1.9-17.8 5.6-25.8 3.7-8 8.7-15.1 15-21.2 6.3-6.1 13.8-10.8 22.5-14.3 8.7-3.5 17.8-5.2 27.5-5.2 7.2 0 14.4 1.6 21.7 4.8 7.3 3.2 13.3 7.4 18 12.6 1-1.5 1.9-3.4 2.6-5.8 0.7-2.4 1.5-4.6 2.2-6.9h7.1v50.9h-7.4c-3-13.9-8.2-24.7-15.8-32.5 -7.6-7.8-17-11.7-28.4-11.7 -8.4 0-15.3 1.7-20.8 5.2 -5.4 3.5-9.8 7.9-13 13.4 -3.2 5.4-5.5 11.4-6.7 17.8 -1.2 6.4-1.9 12.6-1.9 18.6 0 5.9 0.6 12.1 1.9 18.6 1.2 6.4 3.5 12.4 6.7 17.8 3.2 5.4 7.5 9.9 13 13.4 5.4 3.5 12.4 5.2 20.8 5.2 9.4 0 17.5-1.8 24.3-5.4 6.8-3.6 10.2-9.7 10.2-18.4 0-6.4-1.7-11-5-13.6 -3.3-2.6-9.5-3.9-18.4-3.9v-7.1h70.2v7.1c-5.2 0-9.4 0.4-12.6 1.1 -3.2 0.7-5.6 2.2-7.2 4.5 -1.6 2.2-2.7 5.3-3.3 9.3 -0.6 4-0.9 9.3-0.9 16v11.1c-4 0-8.4 0.6-13.4 1.7 -5 1.1-10 2.4-15.2 3.9 -5.2 1.5-10.3 2.8-15.2 3.9C1007.9 465.9 1003.4 466.4 999.5 466.4z"/>
    <path class="dt7" d="M1134.3 262.3H3.7c-2 0-3.7-1.6-3.7-3.7s1.6-3.7 3.7-3.7h1130.6c2 0 3.7 1.6 3.7 3.7S1136.3 262.3 1134.3 262.3z"/>
      <path class="dt7" d="M255.7 535.2h2.4c2.6 0 4.8 0.2 6.3 0.5 1.2 0.2 2 0.7 2.6 1.4 0.6 0.8 1.1 2 1.3 3.6 0.3 1.9 0.5 4.6 0.5 7.9v14.8h-41.5v-14.8c0-3.3 0.1-6 0.4-8 0.2-1.6 0.6-2.8 1.3-3.6 0.6-0.7 1.4-1.1 2.6-1.4 1.6-0.3 3.7-0.5 6.3-0.5h2.4v-7.8h-41.8v7.8h2.4c2.6 0 4.8 0.2 6.3 0.5 1.2 0.2 2 0.7 2.6 1.4 0.6 0.8 1.1 2 1.3 3.6 0.3 1.9 0.4 4.6 0.4 8v44.5c0 3.3-0.1 5.9-0.4 7.9 -0.2 1.7-0.7 2.9-1.3 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h41.8v-7.8h-2.4c-2.7 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.5-1.4 -0.6-0.8-1.1-2.1-1.3-3.8 -0.3-2-0.4-4.6-0.4-7.9v-19.7h41.5v19.7c0 3.2-0.2 5.9-0.5 7.8 -0.3 1.7-0.7 3-1.4 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h42.1v-7.8h-2.4c-2.7 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.6-1.5 -0.7-0.8-1.1-2.1-1.3-3.7 -0.3-2-0.4-4.6-0.4-7.9v-44.5c0-3.3 0.1-6 0.4-8 0.2-1.6 0.6-2.8 1.3-3.5 0.6-0.7 1.5-1.2 2.7-1.4 1.6-0.3 3.7-0.5 6.3-0.5h2.4v-7.8h-42.1V535.2z"/>
      <path class="dt7" d="M455.8 538.5c-4.3-4.1-9.4-7.4-15.2-9.8 -5.8-2.4-12.1-3.6-18.7-3.6 -6.6 0-12.9 1.2-18.7 3.6 -5.8 2.4-11 5.7-15.3 9.8 -4.3 4.1-7.8 9-10.3 14.6 -2.6 5.6-3.8 11.6-3.8 17.8 0 6.3 1.3 12.3 3.8 17.8 2.5 5.6 6 10.5 10.3 14.6 4.3 4.1 9.4 7.4 15.3 9.8 5.8 2.4 12.1 3.6 18.7 3.6 6.6 0 12.9-1.2 18.7-3.6 5.8-2.4 10.9-5.7 15.2-9.8 4.3-4.1 7.8-9 10.3-14.6 2.6-5.6 3.8-11.6 3.8-17.8 0-6.2-1.3-12.3-3.8-17.8C463.6 547.5 460.1 542.6 455.8 538.5zM421.9 606.4c-5.5 0-10.2-1.1-13.9-3.2 -3.8-2.2-7-5-9.3-8.3 -2.4-3.4-4.2-7.2-5.3-11.5 -1.1-4.3-1.7-8.5-1.7-12.4 0-4.1 0.6-8.3 1.7-12.5 1.1-4.2 2.9-8 5.3-11.5 2.4-3.4 5.5-6.2 9.3-8.4 3.8-2.1 8.4-3.2 13.9-3.2 5.5 0 10.1 1.1 13.8 3.2 3.8 2.2 6.9 5 9.3 8.4 2.4 3.4 4.2 7.3 5.3 11.5 1.1 4.3 1.7 8.5 1.7 12.5 0 4-0.6 8.2-1.7 12.4 -1.1 4.2-2.9 8.1-5.3 11.5 -2.4 3.3-5.5 6.2-9.3 8.3C432.1 605.3 427.4 606.4 421.9 606.4z"/>
      <path class="dt7" d="M600.8 590.4l-27.4-63h-27.3v7.8h2.4c2.6 0 4.8 0.2 6.3 0.5 1.2 0.2 2 0.7 2.6 1.4 0.6 0.8 1.1 2 1.3 3.6 0.3 1.9 0.4 4.6 0.4 8v44.5c0 3.3-0.1 5.9-0.4 7.9 -0.2 1.7-0.7 2.9-1.3 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h36.1v-7.8h-2.4c-2.7 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.5-1.4 -0.7-0.8-1.1-2.1-1.4-3.8 -0.3-2-0.5-4.6-0.5-7.8v-37l26.3 60.6h5l24.4-56.5v32.8c0 3.2-0.1 5.9-0.4 7.9 -0.2 1.7-0.7 2.9-1.3 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h41.8v-7.8h-2.4c-2.6 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.6-1.5 -0.7-0.8-1.1-2.1-1.3-3.7 -0.3-2-0.4-4.6-0.4-7.9v-44.5c0-3.3 0.1-6 0.4-8 0.2-1.6 0.6-2.8 1.3-3.5 0.6-0.7 1.5-1.2 2.7-1.4 1.6-0.3 3.7-0.5 6.3-0.5h2.4v-7.8h-25.5L600.8 590.4z"/>
      <path class="dt7" d="M792.1 595.9c-0.4 1.7-0.9 3.2-1.3 4.4 -0.3 0.9-0.9 1.6-1.6 2.1 -0.8 0.6-2 1.1-3.5 1.4 -1.7 0.4-4.1 0.6-7.1 0.6h-11.8c-2.7 0-4.8-0.2-6.3-0.5 -1.2-0.2-2-0.7-2.6-1.4 -0.6-0.8-1-2-1.3-3.6 -0.3-2-0.4-4.6-0.4-7.9v-17.5h4.4c3.3 0 6 0.1 8 0.4 1.6 0.2 2.8 0.6 3.5 1.3 0.7 0.6 1.2 1.5 1.4 2.6 0.3 1.5 0.5 3.7 0.5 6.4v2.4h7.8v-36.2h-7.8v2.4c0 2.6-0.2 4.8-0.5 6.3 -0.3 1.2-0.7 2.1-1.4 2.7 -0.8 0.7-2 1.1-3.5 1.3 -1.9 0.3-4.6 0.4-8 0.4h-4.4v-26.2H774c3 0 5.3 0.2 7 0.6 1.5 0.3 2.6 0.8 3.4 1.4 0.8 0.6 1.3 1.3 1.7 2.2 0.5 1.1 1 2.6 1.4 4.3l1.1 4.6h7.4l-1.6-23h-67.1v7.8h2.4c2.6 0 4.8 0.2 6.3 0.5 1.2 0.2 2 0.7 2.6 1.4 0.6 0.8 1.1 2 1.3 3.6 0.3 1.9 0.4 4.6 0.4 8v44.5c0 3.3-0.1 5.9-0.4 7.9 -0.2 1.7-0.7 2.9-1.3 3.8 -0.6 0.7-1.4 1.2-2.5 1.4 -1.6 0.3-3.7 0.5-6.3 0.5h-2.4v7.8h71.8l1.4-23.1h-7.4L792.1 595.9z"/>
      <path class="dt7" d="M934.4 577.2c-2.4-4.1-6.5-7.6-12.1-10.5l-13-6.7c-2.9-1.5-5.5-2.9-7.5-4.2 -1.9-1.2-3.5-2.4-4.7-3.5 -1.1-1.1-1.9-2.2-2.4-3.4 -0.5-1.1-0.7-2.3-0.7-3.7 0-2.8 1-5 3-6.9 2.1-1.9 4.6-2.8 7.8-2.8 3.6 0 6.5 0.6 8.6 1.9 2.2 1.3 4 2.9 5.4 4.9 1.4 2.1 2.5 4.4 3.1 6.9 0.7 2.7 1.1 5.4 1.4 8l0.2 2.2h7.5v-35h-7.1l-0.5 1.8c-0.3 1.2-0.7 2.1-1.2 2.9 -0.2 0.3-0.4 0.6-0.7 0.8 -1.7-1.1-3.6-2.2-5.5-3 -2.8-1.2-6.4-1.9-10.8-1.9 -3.6 0-6.9 0.6-10 1.7 -3.1 1.2-5.8 2.8-8 4.9 -2.2 2.1-4 4.5-5.3 7.3 -1.3 2.8-1.9 5.8-1.9 9 0 2.8 0.5 5.6 1.4 8.1 0.9 2.6 2.1 4.9 3.7 7 1.5 2.1 3.3 3.9 5.3 5.6 2 1.6 4 3 6.2 4.1l13.1 6.7c4.5 2.3 8.2 4.8 10.9 7.3 2.4 2.2 3.5 4.5 3.5 7 0 1.8-0.4 3.5-1.1 5 -0.7 1.5-1.7 2.8-3 4 -1.3 1.2-2.9 2.1-4.6 2.7 -1.8 0.6-3.8 1-6 1 -4.5 0-8.3-0.8-11.1-2.3 -2.9-1.5-5.1-3.3-6.6-5.4 -1.6-2.1-2.7-4.4-3.5-6.6 -0.8-2.3-1.2-4.2-1.3-5.6 -0.1-1.4-0.2-2.2-0.2-2.3v-2.4h-7.8v35h7.1l0.4-1.9c0.3-1.2 0.7-2.2 1.2-3 0.3-0.5 0.7-0.9 1-1.3 0.6 0.2 1.2 0.5 1.9 0.9 1.4 0.7 3.1 1.4 4.9 2.2 1.9 0.8 3.9 1.5 6.2 2.1 2.3 0.6 4.9 1 7.7 1 3.8 0 7.5-0.7 10.9-1.9 3.4-1.3 6.5-3.1 9.1-5.5 2.6-2.3 4.7-5.2 6.3-8.5 1.6-3.3 2.4-7.1 2.4-11.2C938 585.4 936.8 581.2 934.4 577.2z"/>
    <path class="dt8" d="M346.2 205.6c-1 3.3-1.9 5.8-2.5 8.3 -3.6 14.1-6.4 28.4-8.4 42.9 -0.1 0.5-0.1 1.1-0.2 1.6H378c-3.7-9.6-8.2-19-13.7-28.1C359.2 221.8 353.7 213.6 346.2 205.6z"/>
    <path class="dt8" d="M283.2 164.7c2.7-4.7 2.5-5.1-3.1-6.4 -7.3-1.6-14.6-3.3-22.1-4.3 -27-3.7-53.7-2.7-79.6 5.7 -41.3 13.4-70.8 40.7-90.6 78.1 -3.6 6.8-6.7 13.6-9.5 20.6h172.3c1-7 2.2-14 3.9-20.9C260.6 211.9 269.9 187.6 283.2 164.7z"/>
    <path class="dt8" d="M1011.8 189.4c-8.5-7.3-17.5-13.8-27.4-19.2 -23.6-12.9-49.2-18-78.4-17.9 -5.9-0.6-14.1 0.4-22.3 1.2 -7.6 0.8-15.2 2.5-22.7 4 -3 0.6-4.6 1.3-2.4 5.2 8.1 14.3 15.3 28.9 20.7 44.4 5.8 16.8 10.2 33.9 13 51.3h167.1C1049.3 232.1 1034.3 208.6 1011.8 189.4z"/>
    <path class="dt8" d="M769.7 166c3-2 3.8-3.6 2-7.1 -15.7-29.9-35.4-56.5-62.4-77.6 -14.1-11-29-20.8-45.6-27.9 -29.7-12.7-61-17.3-94.8-16.8 -14.8-0.8-30.9 1.2-46.9 4.1 -39.7 7.2-73.3 25.9-102.3 52.8 -20.5 19-36.2 41.4-48.4 66.2 -3.1 6.3-3.2 6.3 2.7 10.2 8.2 5.5 15.9 11.6 23.1 18.2 21.2 19.4 37.8 42.1 50.4 67.8 0.4 0.8 0.8 1.6 1.1 2.4h240.9C707.4 220.5 734.1 189.5 769.7 166z"/>
    <path class="dt8" d="M781 201.6c-2.9 3-5.5 5.4-7.6 8 -12 15-21.2 31.3-28 48.8h48.2c-1.2-9.9-3.2-19.7-5.2-29.5C786.5 219.9 784.5 211 781 201.6z"/>
      <linearGradient gradientUnits="userSpaceOnUse" x1="569" x2="569" y2="258.4">
        <stop offset="9.183674e-02" stop-color="#BB3627"/>
        <stop offset="1" stop-color="#7B1A16"/>
      </linearGradient>
      <path class="dt9" d="M87.8 237.8c19.9-37.4 49.4-64.6 90.6-78.1 25.8-8.4 52.6-9.4 79.6-5.7 7.5 1 14.8 2.7 22.1 4.3 5.6 1.2 5.8 1.7 3.1 6.4 -13.3 22.9-22.7 47.2-28.7 72.8 -1.6 6.9-2.9 13.9-3.9 20.9h84.4c0.1-0.5 0.1-1.1 0.2-1.6 2-14.5 4.8-28.7 8.4-42.9 0.6-2.5 1.5-5 2.5-8.3 7.5 8 13 16.2 18.1 24.7 5.4 9.1 9.9 18.5 13.7 28.1h70.5c-0.4-0.8-0.8-1.6-1.1-2.4 -12.6-25.7-29.2-48.3-50.4-67.8 -7.2-6.6-14.9-12.7-23.1-18.2 -5.9-3.9-5.8-3.9-2.7-10.2 12.2-24.8 27.9-47.3 48.4-66.2 29-26.9 62.6-45.6 102.3-52.8 16-2.9 32.2-4.9 46.9-4.1 33.8-0.5 65.1 4.2 94.8 16.8 16.6 7.1 31.5 16.9 45.6 27.9 27 21.1 46.8 47.7 62.4 77.6 1.8 3.5 1.1 5-2 7.1 -35.6 23.5-62.3 54.4-80.4 92.4h56.1c6.8-17.5 16-33.8 28-48.8 2.1-2.7 4.7-5 7.6-8 3.5 9.4 5.5 18.4 7.3 27.3 2 9.8 4.1 19.6 5.2 29.5h98.7c-2.7-17.4-7.2-34.5-13-51.3 -5.3-15.5-12.6-30.1-20.7-44.4 -2.2-3.9-0.5-4.6 2.4-5.2 7.5-1.5 15.1-3.2 22.7-4 8.2-0.9 16.4-1.9 22.3-1.2 29.2-0.1 54.8 5 78.4 17.9 9.8 5.4 18.9 11.9 27.4 19.2 22.4 19.2 37.5 42.8 47.6 69h63.4c-3.5-7.4-7.4-14.6-11.7-21.6 -14.8-24.1-33.4-44.9-56.1-62 -17.1-12.9-35.8-23.3-55.8-31.5 -25.3-10.4-51.7-16.2-79-17.4 -11.5-0.5-23.1-0.5-34.8 0.6 -13.7 1.3-27.3 3.1-40.5 6.7 -3 0.8-5.1 0.4-6.9-2.3 -14-19.5-30.5-36.6-48.7-52.4 -18.6-16.1-39.1-29.5-60.9-40.9 -27.7-14.4-56.9-25-87.7-31.2 -17.6-3.6-35.3-6-53.3-6.2 -14.8-0.2-29.6-0.6-44.4 0.7 -12.2 1.1-24.4 2.4-36.3 4.7 -24.9 4.8-49 12.4-72.3 22.6 -35 15.3-66.4 35.8-94.1 61.8 -13.4 12.6-25.6 26.5-36.2 41.6 -1.8 2.5-3.5 3.4-6.7 2.5 -15.8-4.6-32.1-7.2-48.4-8 -12.9-0.7-25.8-0.5-38.8 0.5 -22.2 1.8-43.7 6.3-64.5 14.1 -43.9 16.5-80.6 42.4-108.2 80 -8.9 12.1-16.5 24.7-22.8 37.8h63.3C81.1 251.4 84.2 244.6 87.8 237.8z"/>
</svg>`

const LightTextured = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1137.9 616.8">
<style>
  .lt-a{
    stop-color:#EC2227;
  }
  .lt-b{
    stop-color:#E6E7E8;
  }
  .lt-c{
    stop-color:#FFF;
  }
  .lt-d{
    stop-color:#DEDFE0;
  }
  .lt-e{
    stop-color:#A8A3A1;
  }
</style>
  <style type="text/css">

  .lt0{fill:url(#SVGID_1_);stroke:url(#SVGID_2_);stroke-width:2.9412;stroke-miterlimit:10;}
  .lt1{fill:#FFFFFF;}
  .lt2{fill:url(#SVGID_3_);stroke:url(#SVGID_4_);stroke-width:1.4706;stroke-miterlimit:10;}
  .lt3{fill:url(#SVGID_5_);stroke:url(#SVGID_6_);stroke-width:1.4706;stroke-miterlimit:10;}
  .lt4{fill:url(#SVGID_7_);stroke:url(#SVGID_8_);stroke-width:1.4706;stroke-miterlimit:10;}
  .lt5{fill:url(#SVGID_9_);stroke:url(#SVGID_10_);stroke-width:1.4706;stroke-miterlimit:10;}
  .lt6{fill:url(#SVGID_11_);stroke:url(#SVGID_12_);stroke-width:1.4706;stroke-miterlimit:10;}
  .lt7{fill:url(#SVGID_13_);stroke:url(#SVGID_14_);stroke-width:1.4706;stroke-miterlimit:10;}
  .lt8{fill:url(#SVGID_15_);stroke:url(#SVGID_16_);stroke-width:1.4706;stroke-miterlimit:10;}
  .lt9{fill:url(#SVGID_17_);stroke:url(#SVGID_18_);stroke-width:1.4706;stroke-miterlimit:10;}
  .lt10{fill:url(#SVGID_19_);stroke:url(#SVGID_20_);stroke-width:1.4706;stroke-miterlimit:10;}
  .lt11{fill:url(#SVGID_21_);stroke:url(#SVGID_22_);stroke-width:1.4706;stroke-miterlimit:10;}
  .lt12{fill:url(#SVGID_23_);stroke:url(#SVGID_24_);stroke-width:1.4706;stroke-miterlimit:10;}
  .lt13{fill:url(#SVGID_25_);stroke:url(#SVGID_26_);stroke-width:1.4706;stroke-miterlimit:10;}

  </style>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4492.2" y1="-3327.5" x2="4492.2" y2="-2917.1" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-a"/>
          <stop offset="0.3161" class="lt-a"/>
          <stop offset="0.8638" stop-color="#A61F23"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4492.2" y1="-2915.6" x2="4492.2" y2="-3329" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" stop-color="#C42126"/>
          <stop offset="0.2765" class="lt-a"/>
          <stop offset="0.8638" stop-color="#B11F24"/>
        </linearGradient>
        <path class="lt0" d="M124.5 411.9c8.7-21 19.6-40.8 32.7-59.4 21.3-30.3 49-55.2 83-74.6 34-19.4 75.4-29.2 124-29.2 31.3 0 59.2 4.5 84.4 12.5 -7.1 12.6-13.8 25.4-19.9 38.8 -16.4 36-27.8 73.3-34.2 111.9h134c3.3-23.2 7.9-46.7 14.2-70.4 0.6-2.3 1.5-4.4 2.1-6.7 4.8 5.7 9.6 11.5 13.9 17.6 13.1 18.6 24 38.5 32.7 59.4H703c-19.2-38.9-44.2-73.1-75-102.7 -14.9-14.3-31.2-27-48.3-38.6 10.8-23.4 23.5-45.7 38.4-66.9 29.2-41.6 67.2-75.7 113.9-102.3 46.7-26.6 103.4-40 170.1-40 66.7 0 123.2 13.4 169.3 40 46.2 26.7 83.9 60.8 113.1 102.3 14 19.9 26.1 40.9 36.4 62.7 -19.6 12.6-37.6 26.8-54.3 42.7 -30.8 29.5-55.8 63.7-75 102.7h88.7c8.7-21 19.6-40.8 32.7-59.4 6-8.6 12.7-7.8 19.8-15.5 1.3 4.5 2.9 0 4.2 4.6 6.3 23.7 10.9 47.1 14.2 70.4h156.9c-6.5-38.6-17.8-75.9-34.3-111.9 -6.4-14-13.4-27.5-20.9-40.6 23.7-6.8 49.7-10.7 78.6-10.7 48.6 0 89.8 9.7 123.4 29.2 33.7 19.5 61.2 44.3 82.5 74.6 13.1 18.6 24 38.5 32.7 59.4h100.1c-19.2-38.9-44.2-73.1-75-102.7 -33.7-32.2-73.2-57.6-118.4-76.3 -45.3-18.7-93.7-28-145.3-28 -36.2 0-70.8 4.9-104 14 -18.7-26.8-39.9-51.6-63.7-74.3 -46.1-44.1-100.3-79-162.3-104.7C1039.2 14.3 972.8 1.5 901.9 1.5c-70.8 0-137.5 12.8-200 38.5 -62.6 25.6-117 60.5-163.1 104.7 -24.2 23.1-45.6 48.5-64.6 75.8 -34.9-10.3-71.5-15.5-109.9-15.5 -51.6 0-100.2 9.3-145.9 28 -45.6 18.7-85.3 44.1-119 76.3C68.6 338.8 43.6 373 24.4 411.9H124.5z"/>
        <path class="lt1" d="M3.9 458.4c-0.3 0.9-0.7 1.9-0.9 2.8h0.9V458.4z"/>
        <linearGradient gradientUnits="userSpaceOnUse" x1="3769.9" y1="-2860.8" x2="3769.9" y2="-2586.6" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-b"/>
          <stop offset="0.3466" class="lt-c"/>
          <stop offset="1" class="lt-d"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" x1="3682.5" y1="-2723.7" x2="3857.4" y2="-2723.7" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-e"/>
          <stop offset="0.6534" class="lt-c"/>
          <stop offset="1" class="lt-e"/>
        </linearGradient>
        <path class="lt2" d="M261.6 662.3c0 12.1-2.3 23-6.7 32.8 -4.5 9.8-10.5 18.1-18.1 25.2 -7.6 7-16.5 12.5-26.6 16.4 -10.2 3.9-20.9 5.9-32.2 5.9 -15.2 0-28.6-2.7-40.1-8.2 -11.5-5.5-20-9.9-25.4-13.5 -2 2-4.1 4.8-6.4 8.5 -2.3 3.7-3.9 8.1-4.7 13.2h-13V644h11.9c2.4 25.4 10.9 45 25.6 58.7 14.7 13.7 33.4 20.6 56 20.6 12.7 0 23.5-3.5 32.5-10.4 8.9-6.9 13.4-16.8 13.4-29.5 0-10.3-4.1-19.8-12.2-28.3 -8.2-8.5-20.4-16.8-36.7-24.7l-40.5-20.9c-14.3-7.5-26-17.4-35.2-29.5 -9.1-12.1-13.7-26.5-13.7-43.2 0-9.5 1.9-18.4 5.7-26.5 3.8-8.1 8.9-15.3 15.5-21.4 6.6-6.2 14.4-11 23.5-14.6 9.1-3.6 19.1-5.4 29.8-5.4 12.3 0 23.1 1.9 32.5 5.7 9.3 3.8 17.2 7.8 23.5 12.2 2-2 3.9-4.4 5.7-7.2 1.8-2.8 3.1-6.6 3.9-11.3H243v98.4h-14.1c-0.8-9-2.4-17.9-5-26.9 -2.5-9-6.2-17.2-11.1-24.6 -4.9-7.4-11-13.5-18.4-18.1 -7.4-4.7-16.4-7-26.9-7 -11.3 0-21.2 2.9-29.5 8.8 -8.4 5.9-12.6 14.2-12.6 25.2 0 10.1 4 18.7 12 25.7 8 7 21 15.2 38.9 24.6l39.2 20.5c16.8 8.6 28.7 18.9 35.7 31 7 12.1 10.5 23.8 10.5 35.1"/>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4027.8" y1="-2790.5" x2="4027.8" y2="-2589.2" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-b"/>
          <stop offset="0.3466" class="lt-c"/>
          <stop offset="1" class="lt-d"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" x1="3900.5" y1="-2689.8" x2="4155.1" y2="-2689.8" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-e"/>
          <stop offset="0.6534" class="lt-c"/>
          <stop offset="1" class="lt-e"/>
        </linearGradient>
        <path class="lt3" d="M535.3 550.4c-5.1 1.2-8.9 3.5-11.4 7 -2.5 3.5-4.3 8.4-5.3 14.6 -1 6.2-1.5 14.6-1.5 25.2V654c0 14.2-2.2 26.6-6.5 37.3 -4.3 10.6-10.4 19.5-18.1 26.6 -7.7 7.1-16.8 12.5-27.2 16.3 -10.5 3.7-21.8 5.6-34 5.6 -12.2 0-23.8-1.9-34.6-5.6 -10.9-3.7-20.4-9.2-28.7-16.3 -8.3-7.1-14.8-16-19.5-26.6 -4.7-10.6-7.1-23.1-7.1-37.3v-55.8c0-10.5-0.5-18.9-1.4-25.2 -0.9-6.2-2.5-11.1-4.9-14.6 -2.4-3.5-5.9-5.8-10.6-7 -4.7-1.2-10.8-1.8-18.4-1.8v-11.1h112.5v11.1c-7.6 0-13.6 0.6-18.1 1.8 -4.5 1.2-8 3.5-10.5 7 -2.5 3.5-4.2 8.4-5.1 14.6 -0.9 6.2-1.3 14.6-1.3 25.2v59.2c0 19.5 5.2 34 15.5 43.4 10.3 9.4 23.7 14.1 40.1 14.1 16.4 0 30-4.7 40.7-14.1 10.7-9.4 16.1-23.8 16.1-43.4v-61.7c0-9.9-0.4-17.8-1.1-23.6 -0.7-5.8-2.4-10.4-4.9-13.7 -2.6-3.3-6.1-5.5-10.7-6.6 -4.6-1.1-10.7-1.7-18.4-1.7v-11.6h98.4v10.1c-8.2 0-14.8 0.6-19.9 1.8"/>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4316.1" y1="-2790.5" x2="4316.1" y2="-2586.6" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-b"/>
          <stop offset="0.3466" class="lt-c"/>
          <stop offset="1" class="lt-d"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4195.8" y1="-2688.5" x2="4436.4" y2="-2688.5" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-e"/>
          <stop offset="0.6534" class="lt-c"/>
          <stop offset="1" class="lt-e"/>
        </linearGradient>
        <path class="lt4" d="M823.6 550.4c-5.1 1.2-8.9 3.5-11.4 7 -2.5 3.5-4.3 8.4-5.3 14.6 -1 6.2-1.5 14.6-1.5 25.2v145.3h-21.6l-126-145.2v77.9c0 10.5 0.4 19 1.4 25.5 0.9 6.4 2.5 11.4 4.9 14.9 2.3 3.5 5.9 5.9 10.5 7 4.7 1.2 10.8 1.8 18.4 1.8v11.1h-91.4v-11.2c7.6 0 13.7-0.6 18.4-1.8 4.7-1.2 8.2-3.5 10.6-7.1 2.3-3.5 4-8.6 4.9-15.1 0.9-6.5 1.4-15.1 1.4-25.7v-75.7c0-10.6-0.5-19.1-1.4-25.4 -0.9-6.3-2.5-11.2-4.9-14.8 -2.4-3.5-5.9-5.9-10.6-7.1 -4.7-1.2-10.8-1.8-18.4-1.8v-11.2h63.8l119 136.3v-76.6c0-10.5-0.5-18.9-1.4-25.2 -0.9-6.2-2.5-11.1-4.9-14.6 -2.4-3.5-5.9-5.8-10.6-7 -4.7-1.2-10.8-1.8-18.4-1.8v-11.1h91.4v10.1c-8.2 0-14.8 0.6-19.9 1.8"/>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4588.7" y1="-2790.5" x2="4588.7" y2="-2593.6" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-b"/>
          <stop offset="0.3466" class="lt-c"/>
          <stop offset="1" class="lt-d"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4484.1" y1="-2692.1" x2="4693.3" y2="-2692.1" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-e"/>
          <stop offset="0.6534" class="lt-c"/>
          <stop offset="1" class="lt-e"/>
        </linearGradient>
        <path class="lt5" d="M889.8 724.1c7.6 0 13.7-0.6 18.4-1.8 4.7-1.2 8.2-3.5 10.5-7.1 2.3-3.6 4-8.6 4.9-15.1s1.4-15.1 1.4-25.7v-75.7c0-10.6-0.5-19.1-1.4-25.4 -0.9-6.3-2.5-11.2-4.9-14.8 -2.3-3.5-5.9-5.9-10.5-7.1 -4.7-1.2-10.8-1.8-18.4-1.8v-11.2h96.8c19.5 0 36.2 2.6 50 7.7 13.9 5.1 25.3 12.1 34.4 21 9 8.9 15.7 19.3 20 31.3 4.3 12 6.5 24.7 6.5 38.1 0 13.4-2.2 26.1-6.5 38.1 -4.3 12-11 22.5-20 31.3 -9 8.9-20.5 16-34.4 21.3 -13.9 5.3-30.6 8-50 8h-96.8V724.1zM1068.9 632.8c0-8.7-0.9-17.8-2.7-27.3 -1.8-9.5-5.4-18.1-10.7-25.9 -5.4-7.7-12.8-14.2-22.3-19.3 -9.5-5.1-22-7.7-37.5-7.7h-28.5v129c0 13.5 1.4 22.3 4.2 26.5 2.8 4.2 9.5 6.2 20.2 6.2h4.2c15.5 0 27.9-2.6 37.5-7.7 9.5-5.1 16.9-11.7 22.3-19.6 5.4-7.9 8.9-16.7 10.7-26.2C1068 651.3 1068.9 642 1068.9 632.8"/>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4882.7" y1="-2799.1" x2="4882.7" y2="-2589.1" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-b"/>
          <stop offset="0.3466" class="lt-c"/>
          <stop offset="1" class="lt-d"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4765" y1="-2694.1" x2="5000.4" y2="-2694.1" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-e"/>
          <stop offset="0.6534" class="lt-c"/>
          <stop offset="1" class="lt-e"/>
        </linearGradient>
        <path class="lt6" d="M1404.7 634.6c0 14.4-3.1 28.1-9.4 41 -6.2 12.9-14.6 24.1-25.2 33.6 -10.5 9.6-22.9 17.1-37.1 22.5 -14.2 5.5-29.3 8.2-45.3 8.2 -16 0-31.1-2.7-45.3-8.2 -14.2-5.5-26.6-13-37.1-22.5 -10.5-9.5-18.9-20.8-25.2-33.6 -6.2-12.9-9.4-26.5-9.4-41 0-14.4 3.1-28 9.4-40.7 6.2-12.7 14.6-23.8 25.2-33.3 10.5-9.5 22.9-17.1 37.1-22.5 14.2-5.5 29.3-8.2 45.3-8.2 16 0 31.1 2.7 45.3 8.2 14.2 5.5 26.6 13 37.1 22.5 10.5 9.6 18.9 20.7 25.2 33.3C1401.6 606.6 1404.7 620.2 1404.7 634.6M1360.2 634.6c0-9.4-1.3-19.2-3.8-29.5 -2.5-10.3-6.6-19.7-12.3-28.1 -5.7-8.4-13.1-15.3-22.2-20.8 -9.2-5.5-20.6-8.2-34.2-8.2 -13.3 0-24.6 2.7-33.9 8.2 -9.4 5.5-16.9 12.4-22.5 20.8 -5.7 8.4-9.7 17.8-12.3 28.1 -2.5 10.3-3.8 20.2-3.8 29.5 0 9.8 1.3 19.7 3.8 29.8 2.5 10.1 6.6 19.4 12.3 27.8 5.7 8.4 13.2 15.3 22.5 20.8 9.4 5.5 20.7 8.2 33.9 8.2 13.6 0 25-2.7 34.2-8.2 9.2-5.5 16.6-12.4 22.2-20.8 5.7-8.4 9.8-17.6 12.3-27.8C1359 654.3 1360.2 644.3 1360.2 634.6"/>
        <linearGradient gradientUnits="userSpaceOnUse" x1="5179.9" y1="-2798.1" x2="5179.9" y2="-2588.1" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-b"/>
          <stop offset="0.3466" class="lt-c"/>
          <stop offset="1" class="lt-d"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" x1="5058.6" y1="-2693.1" x2="5301.2" y2="-2693.1" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-e"/>
          <stop offset="0.6534" class="lt-c"/>
          <stop offset="1" class="lt-e"/>
        </linearGradient>
        <path class="lt7" d="M1575.5 740.9c-15.2 0-29.6-2.7-43.3-8.2 -13.7-5.5-25.4-13-35.4-22.5 -9.9-9.6-17.8-20.8-23.7-33.6 -5.9-12.9-8.8-26.5-8.8-40.9 0-14.4 2.9-28 8.8-40.7 5.8-12.7 13.7-23.8 23.7-33.3 9.9-9.5 21.7-17.1 35.4-22.5 13.6-5.5 28.1-8.2 43.3-8.2 11.3 0 22.7 2.5 34.2 7.6 11.5 5.1 21 11.7 28.4 19.9 1.6-2.3 2.9-5.4 4.1-9.1 1.2-3.7 2.3-7.3 3.5-10.8h10.5v77.3h-11.6c-4.6-22.3-12.9-39.8-24.7-52.4 -11.8-12.6-26.6-18.9-44.4-18.9 -13.2 0-24 2.8-32.5 8.4 -8.5 5.6-15.3 12.8-20.3 21.6 -5 8.8-8.5 18.3-10.5 28.7 -1.9 10.4-2.9 20.3-2.9 29.9 0 9.6 1 19.6 2.9 29.9 1.9 10.4 5.4 19.9 10.5 28.7 5 8.8 11.8 16 20.3 21.5 8.5 5.6 19.4 8.4 32.5 8.4 14.7 0 27.4-2.9 38-8.7 10.6-5.8 16-15.7 16-29.6 0-10.4-2.6-17.7-7.8-21.8 -5.2-4.2-14.8-6.3-28.7-6.3V644h112.5v11.1c-7.6 0-13.7 0.6-18.4 1.8 -4.7 1.2-8.2 3.5-10.5 7 -2.4 3.5-4 8.4-4.9 14.6 -0.9 6.2-1.4 14.6-1.4 25.2v18.7c-6.2 0-13.3 0.9-21.1 2.6 -7.8 1.8-15.8 3.8-24 6.1 -8.2 2.3-16.2 4.4-24 6.1 -7.8 1.8-14.8 2.6-21.1 2.6"/>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4492.2" y1="-2924.1" x2="4492.2" y2="-2910" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-b"/>
          <stop offset="0.3466" class="lt-c"/>
          <stop offset="1" stop-color="#DCDDDE"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4492.2" y1="-2924.8" x2="4492.2" y2="-2909.3" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-e"/>
          <stop offset="0.6534" class="lt-c"/>
          <stop offset="1" class="lt-e"/>
        </linearGradient>
        <path class="lt8" d="M1789.8 419H6.5c-3.2-0.7-5.8-3.3-5.8-6.5 0-3.2 2.6-5.8 5.8-7.6h1781.4c3.2 1.8 5.8 4.4 5.8 7.6 0 3.2-2.6 5.8-5.8 5.8"/>
        <linearGradient gradientUnits="userSpaceOnUse" x1="3985.6" y1="-2495.2" x2="3985.6" y2="-2354.6" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-b"/>
          <stop offset="0.3466" class="lt-c"/>
          <stop offset="1" class="lt-d"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" x1="3907.5" y1="-2424.9" x2="4063.7" y2="-2424.9" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-e"/>
          <stop offset="0.6534" class="lt-c"/>
          <stop offset="1" class="lt-e"/>
        </linearGradient>
        <path class="lt9" d="M404.7 847.9h4.8c4 0 7.3 0.2 9.7 0.7 1.8 0.4 3.1 1.1 3.9 2.1 1 1.2 1.7 3 2 5.4 0.5 2.9 0.7 6.9 0.7 11.8v22.1h-63.3v-22.1c0-5 0.2-9 0.6-11.9 0.3-2.4 1-4.2 1.9-5.4 0.8-1 2.1-1.7 3.9-2.1 2.4-0.5 5.6-0.7 9.7-0.7h-2v-14.1h-63.3v14.1h4c4.3 0 7.7 0.3 10.3 0.8 1.9 0.4 3.3 1.1 4.2 2.2 1 1.2 1.7 3.1 2.1 5.6 0.4 3 0.6 7.3 0.6 12.5v70c0 5.1-0.2 9.3-0.6 12.4 -0.4 2.6-1.1 4.6-2.1 5.9 -0.9 1.1-2.2 1.9-4.1 2.2 -2.5 0.5-6 0.8-10.3 0.8h-4v14.1h63.3v-14.1h2c-4 0-7.3-0.2-9.7-0.7 -1.8-0.4-3-1-3.9-2.1 -1-1.2-1.6-3.1-2-5.5 -0.4-2.9-0.6-6.9-0.6-11.7v-29.2h63.3v29.2c0 4.8-0.2 8.7-0.7 11.6 -0.4 2.5-1.1 4.4-2.1 5.6 -0.8 1.1-2.1 1.7-3.9 2.1 -2.4 0.5-5.6 0.7-9.7 0.7h-4.8v14.1H468v-14.1H464c-4.3 0-7.7-0.3-10.2-0.8 -1.9-0.4-3.3-1.1-4.2-2.3 -1.1-1.3-1.8-3.3-2.1-5.8 -0.4-3.1-0.6-7.3-0.6-12.4v-70c0-5.3 0.2-9.5 0.6-12.5 0.3-2.5 1-4.4 2.1-5.6 1-1.1 2.4-1.9 4.3-2.3 2.5-0.5 5.9-0.8 10.2-0.8h3.9v-14.1h-63.3V847.9z"/>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4260.4" y1="-2496.7" x2="4260.4" y2="-2352.3" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-b"/>
          <stop offset="0.3466" class="lt-c"/>
          <stop offset="1" class="lt-d"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4183.9" y1="-2424.5" x2="4336.9" y2="-2424.5" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-e"/>
          <stop offset="0.6534" class="lt-c"/>
          <stop offset="1" class="lt-e"/>
        </linearGradient>
        <path class="lt10" d="M718.9 853.5c-6.8-6.5-14.8-11.7-23.9-15.4 -9.1-3.8-19-5.7-29.4-5.7 -10.4 0-20.3 1.9-29.5 5.7 -9.2 3.8-17.3 9-24.1 15.4 -6.8 6.5-12.3 14.2-16.3 23 -4 8.8-6.1 18.3-6.1 28.1 0 9.9 2 19.3 6.1 28.1 4 8.7 9.5 16.5 16.3 23 6.8 6.5 14.9 11.7 24.1 15.4 9.2 3.8 19.1 5.7 29.5 5.7 10.4 0 20.3-1.9 29.4-5.7 9.1-3.8 17.2-9 23.9-15.4 6.8-6.5 12.3-14.2 16.3-23 4-8.8 6.1-18.3 6.1-28.1 0-9.8-2-19.3-6.1-28.1C731.1 867.7 725.7 859.9 718.9 853.5M665.5 960.4c-8.6 0-16-1.7-21.9-5.1 -6-3.4-11-7.9-14.7-13.1 -3.8-5.4-6.6-11.4-8.4-18 -1.8-6.8-2.7-13.4-2.7-19.6 0-6.4 0.9-13 2.7-19.7 1.7-6.6 4.6-12.6 8.4-18.1 3.7-5.3 8.7-9.8 14.7-13.2 5.9-3.4 13.3-5.1 21.9-5.1 8.6 0 15.9 1.7 21.8 5.1 6 3.4 10.9 7.9 14.6 13.2 3.8 5.4 6.6 11.5 8.4 18.1 1.8 6.7 2.7 13.3 2.7 19.7 0 6.2-0.9 12.8-2.7 19.6 -1.7 6.6-4.6 12.7-8.4 18 -3.8 5.3-8.7 9.7-14.6 13.1C681.5 958.7 674.2 960.4 665.5 960.4"/>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4541.1" y1="-2495.2" x2="4541.1" y2="-2354.6" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-b"/>
          <stop offset="0.3466" class="lt-c"/>
          <stop offset="1" class="lt-d"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4456" y1="-2424.9" x2="4626.2" y2="-2424.9" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-e"/>
          <stop offset="0.6534" class="lt-c"/>
          <stop offset="1" class="lt-e"/>
        </linearGradient>
        <path class="lt11" d="M947.3 935.2l-43.2-101.4h-42.4v14.1h4c4.3 0 7.7 0.3 10.3 0.8 1.9 0.4 3.3 1.1 4.2 2.2 1 1.2 1.7 3.1 2.1 5.6 0.4 3 0.6 7.3 0.6 12.5v70c0 5.1-0.2 9.3-0.6 12.4 -0.3 2.6-1.1 4.6-2.1 5.9 -0.9 1.1-2.2 1.9-4.1 2.2 -2.5 0.5-6 0.8-10.3 0.8h-4v14.1H918v-14.1h-3.8c0 0-7.5 0.2-10-0.3 -1.8-0.4-3.1-1.1-4-2.3 -1-1.3-1.8-3.3-2.2-6 -0.5-3.1-0.7-7.2-1.1-12.4v-58.2l41.9 93.2h7.9l34.6-86.7v51.7c3.9 5.1 3.7 9.3 3.2 12.4 -0.3 2.6-1 4.6-2 5.9 -0.9 1.1-2.2 1.9-4 2.2 -2.4 0.5-10 0.3-10 0.3h-1.3v14.1h63.3v-14.1h-3.9c-4.3 0-7.7-0.3-10.2-0.8 -1.9-0.4-3.3-1.1-4.2-2.3 -1.1-1.3-1.8-3.3-2.1-5.8 -0.4-3.1-0.6-7.3-0.6-12.4v-70c0-5.3 0.2-9.5 0.6-12.5 0.3-2.5 1-4.4 2.1-5.6 1-1.1 2.4-1.9 4.3-2.3 2.5-0.5 5.9-0.8 10.2-0.8h3.9v-14.1h-40.1L947.3 935.2z"/>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4800.7" y1="-2495.2" x2="4800.7" y2="-2354.6" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-b"/>
          <stop offset="0.3466" class="lt-c"/>
          <stop offset="1" class="lt-d"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4744.3" y1="-2424.9" x2="4857.2" y2="-2424.9" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-e"/>
          <stop offset="0.6534" class="lt-c"/>
          <stop offset="1" class="lt-e"/>
        </linearGradient>
        <path class="lt12" d="M1248.9 946.7c-0.7 2.8-1.4 5.2-2.2 7.1 -0.5 1.4-1.4 2.5-2.5 3.4 -1.3 1-3.2 1.7-5.6 2.3 -2.7 0.6-6.5 0.9-11.4 0.9h-18.4c-4.2 0-7.5-0.3-10-0.8 -1.9-0.4-3.2-1.1-4-2.2 -1-1.2-1.7-3.2-2-5.8 -0.4-3.1-0.6-7.4-0.6-12.5v-27.9h6.9c5.3 0 9.5 0.2 12.5 0.6 2.5 0.3 4.4 1 5.6 2.1 1.1 1 1.9 2.3 2.3 4.1 0.5 2.4 0.8 5.8 0.8 10.2v4.1h14.1V876h-14.1v0.1c0 4.2-0.2 7.5-0.7 10 -0.4 1.9-1.1 3.3-2.3 4.2 -1.2 1-3.1 1.7-5.6 2 -3.1 0.4-7.3 0.6-12.5-2.2h-7v-42.2h28.9c4.9 0 8.7 0.3 11.3 0.9 2.4 0.5 4.3 1.3 5.6 2.3 1.2 0.9 2.1 2.1 2.7 3.5 0.8 1.9 1.5 4.2 2.2 7l1.9 7.4h10.4l-2.4-35.2H1150v14.1h4c4.3 0 7.7 0.3 10.3 0.8 1.9 0.4 3.3 1.1 4.2 2.2 1 1.2 1.7 3.1 2.1 5.6 0.4 3 0.6 7.3 0.6 12.5v70c0 5.1-0.2 9.3-0.6 12.4 -0.3 2.6-1 4.6-2.1 5.9 -0.9 1.1-2.2 1.9-4.1 2.2 -2.5 0.5-6 0.8-10.3 0.8h-4v14.1h109.3l2.1-35.2h-10.7L1248.9 946.7z"/>
        <linearGradient gradientUnits="userSpaceOnUse" x1="5025.3" y1="-2495.2" x2="5025.3" y2="-2354.6" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-b"/>
          <stop offset="0.3466" class="lt-c"/>
          <stop offset="1" class="lt-d"/>
        </linearGradient>
        <linearGradient gradientUnits="userSpaceOnUse" x1="4976.3" y1="-2424.9" x2="5074.3" y2="-2424.9" gradientTransform="matrix(1 0 0 1 -3595 3329)">
          <stop offset="0" class="lt-e"/>
          <stop offset="0.6534" class="lt-c"/>
          <stop offset="1" class="lt-e"/>
        </linearGradient>
        <path class="lt13" d="M1473 918c-3.7-6.4-10.2-12-19.1-16.5l-20.5-10.6c-4.6-2.4-8.6-4.6-11.8-6.5 -3-1.8-5.5-3.7-7.4-5.6 -1.7-1.7-3-3.5-3.8-5.3 -0.8-1.7-1.2-3.7-1.2-5.9 0-4.4 1.5-7.9 4.7-10.9 3.2-3 7.3-4.5 12.4-4.5 5.6 0 10.2 1 13.6 2.9 3.5 2 6.4 4.6 8.5 7.8 2.2 3.3 3.9 6.9 4.8 10.9 1 4.3 1.8 8.5 2.2 12.6l0.3 3.5h10.7v-56.2h-11.2l-0.7 3c-0.5 1.9-1.1 3.4-2 4.6 -0.3 0.5-0.7 0.9-1 1.4 -2.8-1.8-5.7-3.5-8.8-4.8 -4.4-2-10.1-3-17.2-3 -5.6 0-11 0.9-15.8 2.8 -4.9 1.9-9.1 4.5-12.7 7.8 -3.5 3.3-6.4 7.3-8.4 11.7 -2 4.5-3.1 9.3-3.1 14.4 0 4.5 0.7 8.9 2.2 13.1 1.4 4.1 3.4 7.9 5.9 11.2 2.4 3.3 5.2 6.3 8.4 9 3.1 2.6 6.4 4.8 9.8 6.5l20.8 10.8c7.2 3.8 13 7.8 17.3 11.8 3.8 3.6 5.6 7.3 5.6 11.3 0 2.9-0.6 5.6-1.7 8 -1.2 2.5-2.7 4.6-4.8 6.4 -2.1 1.9-4.5 3.3-7.3 4.3 -2.8 1-6 1.5-9.4 1.5 -7.2 0-13.1-1.2-17.6-3.6 -4.6-2.4-8.1-5.3-10.5-8.6 -2.5-3.5-4.4-7-5.5-10.7 -1.2-3.7-1.9-6.8-2.1-9 -0.2-2.3-0.3-3.6-0.3-3.7v-11.7H1382v56.2h14.7l0.7-3c0.4-1.9 1.1-3.5 1.9-4.6 0.5-0.7 1.1-1.4 1.6-2 0.9 0.4 2 0.8 3 1.3 2.2 1.1 4.8 2.3 7.7 3.5 2.9 1.2 6.2 2.3 9.7 3.3 3.7 1 7.8 1.5 12.1 1.5 6 0 11.8-1 17.2-3.1 5.4-2 10.2-4.9 14.3-8.6 4.1-3.7 7.4-8.2 9.9-13.4 2.5-5.2 3.7-11.2 3.7-17.6 0-6.7-1.9-13.3-5.6-19.7"/>
</svg>`

export const Sundog = {
  DarkFlat,
  LightFlat,
  DarkTextured,
  LightTextured,
}

const BuilderTrend = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 510 100">
<style type="text/css">
	.btl-0{fill:#005B80;}
	.btl-1{fill:#00ADE7;}
</style>
<g>
	<path class="btl-0" d="M22.8,4.1c0,0.4-0.1,0.7-0.1,1.1c0,6,0,11.9,0,17.9c0,0.3,0,0.6,0,0.9c0,0.5,0.2,0.6,0.7,0.6
		c0.4,0,0.7,0,1.1,0c5.6,0,11.1,0,16.7,0c0.8,0,1.6,0,2.5,0c0.1,1.3-0.4,2.3-0.6,3.3c-1.1,4.6-2.3,9.3-3.4,13.9
		c-0.1,0.4-0.3,0.5-0.6,0.5c-1.2,0.2-2.5,0.4-3.7,0.8c-6.7,2.4-10.9,7-12.2,14c-1,5.4,0.4,10.3,3.9,14.6c1,1.2,2.1,2.2,3.4,3.1
		c0.8,0.6,0.9,0.7,0.6,1.6C29.6,81.5,28.3,86.8,27,92c-0.1,0.3-0.2,0.6-0.4,0.9c-1.5-0.6-2.8-1.3-4.2-2.1c-6.3-3.8-11.1-9-14.3-15.8
		c-1.5-3.2-2.4-6.5-2.9-9.9C5,63.4,5,61.7,5,60C5,42,5,24,5,6c0-0.6,0-1.1,0-1.7c5.5,0,10.9,0,16.4,0c0.3,0,0.6,0.1,0.8-0.3
		C22.4,4.1,22.6,4.1,22.8,4.1z"/>
	<path class="btl-0" d="M390.8,80.9c0.3-0.8,0.2-1.4,0.2-1.9c0-13.3,0-26.7,0-40c0-1.9-0.2-1.6,1.7-1.6c7.2,0,14.4,0,21.6,0
		c0.3,0,0.5,0,0.8,0c1,0,1.1,0.1,1.1,1c0,2.6,0,5.3,0,7.9c0,0.6-0.2,0.8-0.8,0.8c-1.7,0-3.5,0-5.2,0c-2.2,0-4.4,0-6.6,0
		c-1.1,0-1.1,0-1.1,1.1c0,1.7,0,3.5,0,5.2c0,0.9,0,1,1,1c3.5,0,6.9,0,10.4,0c1.6,0,1.4-0.2,1.4,1.4c0,2.5,0,5,0,7.5
		c0,0.9,0,0.9-0.9,0.9c-3.4,0-6.7,0-10.1,0c-0.4,0-0.7,0-1.1,0c-0.5,0-0.7,0.1-0.7,0.7c0,2,0,4,0,6c0,0.7,0.3,0.7,0.8,0.7
		c3.6,0,7.1,0,10.7,0c0.4,0,0.8,0,1.2,0c0.8,0,0.9,0.1,0.9,0.9c0,2.7,0,5.5,0,8.2c0,0.4-0.1,0.7-0.6,0.6c-0.4,0-0.7,0-1.1,0
		c-12,0-24.1,0-36.1,0c-1.2,0-1.8-0.4-2.4-1.3c-3-4.7-6-9.3-9-13.9c-0.3-0.4-0.6-0.8-0.9-1.4c0,5.1,0,10,0,14.8c0,1.7,0,1.7-1.7,1.7
		c-2.9,0-5.8,0-8.7,0c-1,0-1,0.1-1-1c0-2.4,0-4.8,0-7.2c0-11.4,0-22.8,0-34.2c0-1.8-0.2-1.6,1.5-1.6c5.2,0,10.4,0,15.6,0
		c2,0,4,0.2,6,0.8c4.8,1.5,7.7,4.7,8.7,9.5c0.6,3.1,0.5,6.1-0.7,9.1c-1.2,2.9-3.3,4.8-6.2,5.8c-0.7,0.2-1.3,0.5-2.1,0.8
		C381.9,69.3,386.3,75,390.8,80.9z M366,51.6c0,1.5,0,2.9,0,4.4c0,1,0.1,1,1.1,1c1.2,0.1,2.3-0.1,3.5-0.3c2.3-0.5,4.1-1.7,4.5-4.3
		c0.4-2.6-0.9-4.9-3.4-5.7c-1.5-0.5-3.1-0.7-4.6-0.6c-0.9,0.1-1,0.1-1,1C366,48.7,366,50.2,366,51.6z"/>
	<path class="btl-0" d="M451.7,64c0-0.8,0-1.3,0-1.8c0-7.7,0-15.4,0-23c0-0.4,0-0.7,0-1.1c0-0.5,0.1-0.7,0.7-0.7c3.3,0,6.6,0,9.9,0
		c0.8,0,0.9,0.1,0.9,0.9c0,0.3,0,0.6,0,0.9c0,13.5,0,27.1,0,40.6c0,1.7,0.3,1.5-1.5,1.5c-3,0-6,0-9,0c-0.7,0-1.1-0.2-1.5-0.8
		c-3.1-3.9-6.1-7.8-9.2-11.7c-3.2-4.1-6.4-8.2-9.7-12.3c-0.4-0.5-0.7-1.1-1.3-1.5c-0.2,0.5-0.1,1-0.1,1.5c0,7.6,0,15.3,0,22.9
		c0,0.3,0,0.5,0,0.8c0,1,0.2,1.1-1,1.1c-2.5,0-5.1,0-7.6,0c-0.7,0-1.4,0-2.1,0c-0.6,0-0.8-0.2-0.7-0.8c0-0.3,0-0.5,0-0.8
		c0-13.6,0-27.2,0-40.7c0-1.6,0-1.6,1.6-1.6c2.9,0,5.8,0,8.7,0c0.8,0,1.3,0.2,1.8,0.8c4.4,5.7,8.9,11.4,13.4,17.1
		C447,58.1,449.3,60.9,451.7,64z"/>
	<path class="btl-1" d="M58.6,60.1c0.2-4.1-1.2-7.6-3.6-10.8c-1.1-1.4-2.4-2.8-3.9-3.8c-0.6-0.4-0.8-0.8-0.5-1.6
		c1.3-4.7,2.4-9.5,3.6-14.2c0.1-0.5,0.1-1,0.4-1.5c0.4-0.8,0.4-1,1.3-0.6c2,1,4,2.1,5.8,3.4c1.7,1.3,3.4,2.6,4.8,4.2
		c3.9,4.2,6.9,8.9,8.5,14.4c0.8,2.5,1.3,5,1.4,7.6c0,1.1,0.3,2.3,0.2,3.5c-0.3,4.7-1.1,9.2-3,13.5c-1.1,2.5-2.4,4.7-4,6.9
		c-1.8,2.5-4,4.7-6.4,6.7c-1.9,1.6-4,3-6.3,4.1c-3.3,1.7-6.8,2.9-10.5,3.4c-2.2,0.3-4.4,0.6-6.6,0.6c-0.2,0-0.3,0-0.5,0
		c-0.5,0.1-1.1-0.1-1.4-0.3c-0.6-0.3,0-0.9,0.1-1.3c1.1-4.7,2.3-9.4,3.5-14.1c0.1-0.3,0.1-0.7,0.2-1c0.2-1,0.3-1.1,1.5-1.2
		c3-0.4,5.7-1.6,8.1-3.3c3.4-2.4,5.5-5.7,6.7-9.6C58.5,63.4,58.7,61.8,58.6,60.1z"/>
	<path class="btl-0" d="M219.1,59.5c0-6.8,0-13.5,0-20.3c0-0.4,0-0.7,0-1.1c0-0.5,0.3-0.7,0.8-0.7c0.4,0,0.7,0,1.1,0
		c4.8,0,9.7,0,14.5,0c2.7,0,5.3,0.4,7.9,1.4c4.8,1.9,8.6,5.1,11.3,9.5c1.5,2.5,2.6,5.3,2.9,8.2c0.5,4.3,0,8.6-2.1,12.5
		c-2.9,5.6-7.3,9.5-13.4,11.4c-1.6,0.5-3.2,0.8-4.9,0.8c-5.7,0-11.4,0-17.1,0c-1,0-1-0.1-1-1.1C219.1,73.3,219.1,66.4,219.1,59.5z
		 M230.6,59.3c0,1.1,0,2.2,0,3.4c0,2.7,0,5.4,0,8.1c0,0.6,0.2,0.8,0.8,0.8c1.7,0,3.4,0.1,5-0.3c4-0.9,6.9-3.1,8.4-7.1
		c0.9-2.4,1.1-4.8,0.6-7.3c-1-4.8-3.7-7.9-8.5-9.2c-1.7-0.4-3.5-0.6-5.3-0.5c-0.9,0-0.9,0-0.9,0.9C230.6,51.8,230.6,55.6,230.6,59.3
		z"/>
	<path class="btl-0" d="M476.3,81.3c-3,0-6,0-9,0c-0.8,0-0.8,0-0.9-0.8c0-0.3,0-0.6,0-0.9c0-13.4,0-26.8,0-40.3c0-0.1,0-0.2,0-0.3
		c0-1.6,0-1.6,1.6-1.6c5.1,0,10.2-0.1,15.3,0c5.7,0.1,10.6,2.2,14.8,6.1c3.2,3,5.3,6.6,6.3,10.8c1,4.4,0.8,8.7-0.9,12.9
		c-2.5,6.2-6.9,10.5-13.2,12.8c-1.8,0.7-3.8,1.1-5.8,1.2C481.8,81.3,479.1,81.3,476.3,81.3C476.3,81.3,476.3,81.3,476.3,81.3z
		 M478,59.3c0,0.4,0,0.8,0,1.2c0,3.4,0,6.8,0,10.2c0,0.5,0.1,0.8,0.7,0.8c1.7,0,3.5,0.1,5.2-0.3c3.9-0.9,6.7-3.1,8.2-6.8
		c1-2.4,1.2-5,0.7-7.6c-1-4.8-3.7-7.9-8.5-9.2c-1.5-0.4-3.1-0.6-4.7-0.5c-1.7,0-1.6-0.3-1.6,1.5C478,52.2,478,55.8,478,59.3z"/>
	<path class="btl-0" d="M124.1,58c1.2,0.3,2.3,0.6,3.4,1.2c3,1.6,4.6,4.1,5,7.5c0.2,1.8,0.1,3.6-0.3,5.4c-1,4-3.5,6.6-7.3,8.1
		c-2.1,0.8-4.2,1.2-6.4,1.2c-6.2,0-12.3,0-18.5,0c-0.9,0-0.9,0-0.9-0.9c0-6.4,0-12.8,0-19.2c0-7.3,0-14.6,0-22c0-0.3,0-0.6,0-0.9
		c0-0.9,0-0.9,1-0.9c5.5,0,10.9-0.1,16.4,0c2.6,0.1,5.2,0.4,7.4,1.8c2.4,1.5,3.9,3.6,4.4,6.4c0.4,2.1,0.4,4.1,0,6.2
		c-0.5,2.2-1.6,3.9-3.3,5.3c-0.2,0.2-0.5,0.3-0.7,0.6C124.1,57.8,124.1,57.8,124.1,58z M110.6,67.8c0,1.3,0,2.5,0,3.8
		c0,0.8,0,0.8,0.9,0.8c1.7,0.1,3.5,0,5.2-0.3c2.8-0.5,4.4-1.5,4.2-4.8c-0.1-1.5-0.9-2.6-2.2-3.2c-2.5-1.1-5.1-0.8-7.6-0.8
		c-0.4,0-0.4,0.4-0.4,0.7C110.6,65.2,110.6,66.5,110.6,67.8z M110.4,50.4c0,1.2,0,2.3,0,3.5c0,0.6,0.2,0.8,0.8,0.8
		c1.2,0,2.3,0,3.5-0.3c2-0.5,3.3-1.9,3.2-3.9c-0.1-2.3-0.8-3.2-3-4c-1.2-0.4-2.4-0.3-3.6-0.3c-0.6,0-0.8,0.3-0.8,0.8
		C110.4,48.2,110.4,49.3,110.4,50.4z"/>
	<path class="btl-0" d="M324.9,81.3c-2.8,0-5.2,0-7.7,0c-1.8,0-3.6-0.1-5.4,0c-1,0.1-1.5-0.3-2.1-1.2c-2.9-4.5-5.8-9-8.7-13.5
		c-0.3-0.5-0.6-1.2-1.3-1.6c0,0.8,0,1.4,0,2.1c0,4.4,0,8.7,0,13.1c0,1,0.1,1.1-0.9,1.1c-3.3,0-6.6,0-9.9,0c-0.2,0-0.4,0-0.6-0.1
		c-0.2-0.4-0.1-0.9-0.1-1.2c0-13.7,0-27.4,0-41c0-1.8-0.2-1.6,1.5-1.6c5.4,0,10.8,0,16.2,0c2.2,0,4.4,0.3,6.5,1.1
		c4.5,1.8,7,5.2,7.9,9.9c0.4,2.3,0.2,4.5-0.3,6.7c-0.9,3.6-3.3,6-6.6,7.4c-0.7,0.3-1.4,0.5-2.3,0.8
		C315.7,69.3,320.2,75.2,324.9,81.3z M302.2,46.4c-0.5,0-1,0-1.5,0c-0.8,0-0.9,0-0.9,0.8c0,2.4,0,4.9,0,7.3c0,0.7-0.3,1.7,0.2,2.2
		c0.5,0.5,1.5,0.1,2.2,0.2c1.3,0,2.5-0.1,3.7-0.6c2-0.9,2.8-2.5,2.8-4.6c0-2.2-0.9-3.7-2.8-4.6C304.7,46.5,303.5,46.4,302.2,46.4z"
		/>
	<path class="btl-0" d="M172.5,52.4c0,4.6,0.1,9.2,0,13.7c-0.1,3.3-1,6.5-2.7,9.3c-2,3.2-5,5.1-8.6,6.1c-3.4,0.9-6.8,1.1-10.3,0.9
		c-2.6-0.2-5.2-0.7-7.6-1.8c-4.4-2-7-5.4-8.1-10c-0.6-2.4-0.9-5-0.9-7.5c0-8.1,0-16.3,0-24.4c0-0.2,0-0.3,0-0.5c0-0.8,0.1-0.9,1-0.9
		c0.2,0,0.4,0,0.6,0c2.8,0,5.5,0,8.3,0c2.1,0,1.7,0.2,1.7,1.8c0,7.5,0,14.9,0,22.4c0,1.7,0,3.3,0.3,5c0.5,2.3,1.5,4.2,3.7,5.2
		c1.7,0.8,3.6,0.8,5.5,0.5c3.8-0.6,5.1-3.6,5.5-6.2c0.3-1.7,0.3-3.4,0.3-5.2c0-7.3,0-14.5,0-21.8c0-0.4,0-0.7,0-1.1
		c0-0.5,0.1-0.7,0.7-0.7c3.3,0,6.6,0,9.9,0c0.5,0,0.8,0.1,0.7,0.7c0,0.9,0,1.7,0,2.6C172.5,44.6,172.5,48.5,172.5,52.4
		C172.5,52.4,172.5,52.4,172.5,52.4z"/>
	<path class="btl-0" d="M284.8,71.6c0,3.3,0,6.4,0,9.6c-0.2,0.3-0.6,0.2-1,0.2c-7.6,0-15.2,0-22.8,0c-0.2,0-0.4,0-0.6,0
		c-0.4,0-0.7-0.1-0.6-0.6c0-0.3,0-0.6,0-0.9c0-13.6,0-27.2,0-40.8c0-1.9-0.2-1.6,1.6-1.6c7.3,0,14.6,0,21.9,0c0.3,0,0.6,0,0.9,0
		c0.6-0.1,0.7,0.2,0.7,0.7c0,1.8,0,3.7,0,5.5c0,1.1,0,2.2,0,3.3c-0.4,0.4-0.8,0.2-1.2,0.2c-3.8,0-7.6,0-11.3,0c-0.9,0-1,0-1,0.9
		c0,1.9,0,3.8,0,5.6c0,0.6,0.2,0.8,0.8,0.8c3.4,0,6.8,0,10.3,0c0.3,0,0.6,0,0.9,0c0.8,0,0.9,0,0.9,0.8c0,2.7,0,5.4,0,8.1
		c0,0.8,0,0.8-0.9,0.8c-3.4,0-6.8,0-10.3,0c-0.4,0-0.7,0-1.1,0c-0.4,0-0.6,0.1-0.6,0.6c0,1.2,0,2.3,0,3.5c0,0.8,0,1.6,0,2.4
		c0,0.8,0,0.8,0.9,0.8c3.9,0,7.9,0,11.8,0C284.2,71.6,284.4,71.6,284.8,71.6z"/>
	<path class="btl-0" d="M342.7,64.4c0,5.3,0,10.6,0,15.9c0,1,0.1,1.1-1.1,1.1c-3.1,0-6.2,0-9.3,0c-1.4,0-1.2,0-1.2-1.3
		c0-3.4,0-6.8,0-10.2c0-7,0-13.9,0-20.9c0-0.3,0-0.6,0-0.9c0-0.8,0-0.8-0.8-0.9c-2.4,0-4.9,0-7.3,0c-1.2,0-1.1-0.1-1.1-1.1
		c0-2.5,0-5,0-7.5c0-1.2,0-1.2,1.1-1.2c3.1,0,6.2,0,9.3,0c6,0,11.9,0,17.9,0c0.3,0,0.6,0,0.9,0c0.9,0,1,0.1,1,1c0,2.7,0,5.4,0,8.1
		c0,0.5-0.2,0.7-0.7,0.7c-1.8,0-3.6,0-5.4,0c-0.9,0-1.8,0-2.8,0c-0.4,0-0.7,0.1-0.6,0.6c0,0.4,0,0.7,0,1.1
		C342.7,54,342.7,59.2,342.7,64.4z"/>
	<path class="btl-0" d="M215.7,81.1c-0.5,0.3-0.9,0.2-1.3,0.2c-7.5,0-15,0-22.5,0c-0.3,0-0.6,0-0.9,0c-0.3,0-0.4-0.2-0.4-0.5
		c0-0.4,0-0.7,0-1.1c0-13.6,0-27.2,0-40.7c0-1.9-0.2-1.6,1.6-1.6c2.8,0,5.6,0,8.4,0c1.7,0,1.6,0.1,1.6,1.6c0,10.3,0,20.6,0,31
		c0,1.8-0.3,1.6,1.5,1.6c3.7,0,7.3,0,11,0c1,0,1.1,0,1.1,1C215.7,75.4,215.7,78.3,215.7,81.1z"/>
	<path class="btl-0" d="M175.9,81.1c-0.2-0.3-0.1-0.7-0.1-1.1c0-13.7,0-27.5,0-41.2c0-0.3,0-0.5,0-0.8c0-0.5,0.2-0.7,0.7-0.7
		c2.6,0,5.2,0,7.8,0c0.8,0,1.5,0,2.3,0c0.5,0,0.8,0.2,0.8,0.7c0,0.3,0,0.5,0,0.8c0,13.7,0,27.4,0,41c0,1.5,0.3,1.4-1.4,1.4
		c-3.2,0-6.3,0-9.5,0C176.4,81.3,176.1,81.4,175.9,81.1z"/>
</g>
</svg>`

const BuilderTrendIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 385 495">
<style type="text/css">
	.bti-0{fill:#FDFEFE;}
	.bti-1{fill:#00ABE5;}
</style>
<g>
	<path class="bti-0" d="M116.9,477.4c-17.9-7.8-33.8-17.6-48.2-29.8c-16.4-13.9-30-30.1-41.1-48.6c-10-16.7-17-34.5-21.7-53.4
		C2.9,333.3,1.4,320.7,1,308C0.8,301.6,1,295,1,288.6c0-94,0-187.9,0-281.9c0-5.9-0.3-5.1,5.1-5.1c27.5,0,55,0,82.5,0c7,0,7,0,7,7
		c0,32.2,0,64.3,0,96.5c0,0.8,0,1.7,0,2.5c0.1,2.8,0.1,2.8,2.7,3c1.2,0,2.3,0,3.5,0c31.3,0,62.6,0,94,0c4.1,0,8.2,0.3,12.7,0.9
		c-1.1,4.7-2.1,9-3.1,13.3c-6.3,25.3-12.6,50.7-18.9,76c-0.2,0.6-0.4,1.3-0.5,1.9c-0.3,2.1-1.4,3.1-3.6,3.3
		c-2.8,0.3-5.6,0.8-8.4,1.4c-27.2,5.5-48.1,20.2-63.2,43.4c-11.3,17.4-16,36.5-14.7,57c1.9,30,15.6,53.8,39.4,71.9
		c1.1,0.8,2.1,1.7,3.3,2.3c1.9,0.9,2,2.3,1.5,4.1c-5.3,21.1-10.5,42.2-15.8,63.3c-2,8.1-4.1,16.1-6.1,24.2
		C117.9,474.6,117.4,475.9,116.9,477.4z"/>
	<path class="bti-1" d="M193.6,493.3c-4.1,0-7.5,0-10.8,0c-1.5,0-2.9-0.4-4.4-0.7c-1.7-0.3-2.3-1-1.9-2.7c2.3-9.3,4.6-18.6,6.9-27.9
		c3.6-14.7,7.8-29.3,11-44.2c1.2-5.6,3-11.2,4.1-16.8c0.6-2.8,1.5-3.5,3.9-3.7c10.7-0.8,20.7-4.2,30.4-8.5
		c11.3-5,21.1-12.4,29.6-21.4c11.6-12.3,19.4-26.9,23.5-43.2c2.7-10.5,3.2-21.4,2.1-32.2c-1.4-13.2-5.1-25.9-11.9-37.5
		c-5.3-9-11.6-17-19.2-24.1c-3.3-3.1-6.9-5.8-10.6-8.3c-2.1-1.4-2.5-2.9-2-5c2.3-9.1,4.7-18.2,7-27.3c2.4-9.4,4.5-18.9,6.9-28.4
		c2.4-9.8,5-19.5,7.5-29.2c0.4-1.4,0.7-2.9,1.1-4.3c0.5-1.7,1.4-2,3-1.3c5.8,2.9,11.7,5.5,17.3,8.7c6.9,4,13.6,8.3,19.9,13.2
		c10.2,8,19.9,16.5,28.5,26.3c11.6,13.2,21.2,27.6,29,43.3c6.9,14,11.9,28.7,15.3,43.9c1.3,6.1,2.2,12.3,2.7,18.6
		c0.5,7.1,1.8,14.2,1.5,21.3c-0.3,6.9-1,13.9-1.5,20.8c-0.9,11-3.4,21.5-6.3,32c-2.2,8-5.2,15.7-8.4,23.3
		c-6.4,15.1-15,28.8-24.9,41.7c-8.4,10.8-18.1,20.3-28.5,29.1c-8.8,7.4-18.2,13.9-28.1,19.6c-12.7,7.2-26.1,12.8-40.2,16.9
		c-8.1,2.3-16.3,4.1-24.6,5.7c-2.7,0.5-5.6,0.8-8.4,1C206.4,492.7,199.6,494,193.6,493.3z"/>
</g>
</svg>`

export const Partners = {
  BuilderTrend,
  BuilderTrendIcon,
}
